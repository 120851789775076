import React from "react";
import { Typography, Button, Form, Input, message, Drawer } from "antd";
import { CREATE_NOTIFICATION } from "./requests";
import { useMutation } from "@apollo/client";
import { Container } from "../elements";

const { Title, Text } = Typography;

const CreateNotification = ({ showModal, setShowModal, refetch }) => {
  const [form] = Form.useForm();

  const onClose = () => {
    setShowModal(false);
  };

  const [createNotificationMutation] = useMutation(CREATE_NOTIFICATION);
  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          notification: {
            title: values.title,
            description: values.description,
          },
        },
      };
      await createNotificationMutation(payload);
      await refetch();
      message.success("Se creó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
      form.resetFields();
    }
  };

  return (
    <Container>
      <Drawer
        open={showModal}
        ShowModal
        onClose={onClose}
        title={<Title level={3}>Crear Notificación</Title>}
        width={"60%"}
      >
        <Form form={form} name="notification" onFinish={onFinish}>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Nombre de la Notificación <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Ingrese el nombre" />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Descripción <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Ingrese la descripción" />
            </Form.Item>
          </div>
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Crear Notificación
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </Container>
  );
};

export default CreateNotification;
