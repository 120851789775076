import { gql } from "@apollo/client";

const CREATE_BUNDLE = gql`
  mutation createBundle($bundle: BundleCreate!) {
    createBundle(bundle: $bundle) {
      title
    }
  }
`;

export { CREATE_BUNDLE };
