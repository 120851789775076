import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      success
      message
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;
const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user) {
      success
      message
      user {
        id
        firstName
        lastName
        email
        phone
        mobile
        img
      }
    }
  }
`;
export { CREATE_USER, UPDATE_USER };
