import { gql } from "@apollo/client";

const UPDATE_BANNER = gql`
  mutation updateBanner($id: ID!, $banner: BannerUpdateInput!) {
    updateBanner(id: $id, banner: $banner) {
      id
    }
  }
`;

export { UPDATE_BANNER };
