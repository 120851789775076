import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Typography,
  Button,
  Select,
  Input,
  Row,
  Col,
  Upload,
  message,
  Drawer,
  Spin,
} from "antd";
import { UploadOutlined, EditOutlined } from "@ant-design/icons";
import { getSubcategories, updateSubcategoryImage } from "./requests";
import useUpload from "hooks/use-upload";

const { Title, Text } = Typography;
const { Option } = Select;

const Subcategories = () => {
  const { upload } = useUpload();
  const [groupedSubcategories, setGroupedSubcategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchSubcategory, setSearchSubcategory] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(""); // Guarda la URL de la imagen

  useEffect(() => {
    fetchSubcategories();
  }, []);

  const fetchSubcategories = async () => {
    try {
      const response = await getSubcategories();
      const subcategoriesRes = response.data.subcategories;

      // Filtrar subcategorías sin `parentCategory`
      const validSubcategories = subcategoriesRes.filter(
        (sub) => sub.parentCategory
      );

      // Agrupar subcategorías por categoría padre
      const grouped = {};
      validSubcategories.forEach((sub) => {
        if (!grouped[sub.parentCategory]) {
          grouped[sub.parentCategory] = [];
        }
        grouped[sub.parentCategory].push(sub);
      });

      setGroupedSubcategories(grouped);
    } catch (error) {
      console.error("❌ Error fetching subcategories:", error);
      message.error("Error al obtener subcategorías.");
    }
  };

  const handleUpload = async ({ fileList }) => {
    if (!selectedSubcategory || !fileList[0]) return;

    setUploadLoading(true);
    try {
      const fileName = `subcategories/${
        selectedSubcategory._id
      }/${new Date().toISOString()}_${fileList[0]?.name}`;
      const uploadedImageUrl = await upload(
        fileList[0].originFileObj,
        fileName
      );

      setImageUrl(uploadedImageUrl); // Guarda la URL de la imagen subida
      message.success("Imagen subida con éxito");
    } catch (error) {
      console.error("❌ Error uploading image:", error);
      message.error("Error al subir imagen.");
    }
    setUploadLoading(false);
  };

  const handleSaveImage = async () => {
    if (!selectedSubcategory || !imageUrl) {
      message.error("Falta información para actualizar la imagen.");
      return;
    }

    try {
      await updateSubcategoryImage(selectedSubcategory._id, imageUrl);
      message.success("Imagen actualizada con éxito");
      fetchSubcategories();
      setDrawerVisible(false);
    } catch (error) {
      console.error("❌ Error updating image:", error);
      message.error("Error al actualizar imagen.");
    }
  };

  return (
    <Card>
      <Col span={24}>
        <Text style={{ fontSize: 20, fontWeight: "800" }}>Subcategorias</Text>
      </Col>
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Select
            placeholder="Filtrar por Categoría"
            style={{ width: "100%" }}
            onChange={setSelectedCategory}
            allowClear
          >
            {Object.keys(groupedSubcategories).map((category) => (
              <Option key={category} value={category}>
                {category}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Buscar Subcategoría"
            onChange={(e) => setSearchSubcategory(e.target.value)}
          />
        </Col>
      </Row>

      {Object.entries(groupedSubcategories)
        .filter(
          ([category]) => !selectedCategory || category === selectedCategory
        )
        .map(([category, subcategories]) => (
          <Card key={category} style={{ marginBottom: 20 }}>
            <Title level={5} style={{ fontWeight: "800" }}>
              {category}
            </Title>
            <Table
              dataSource={subcategories.filter((sub) =>
                sub.name.toLowerCase().includes(searchSubcategory.toLowerCase())
              )}
              rowKey="_id"
              columns={[
                {
                  title: "Imagen",
                  key: "image",
                  render: (row) => (
                    <div
                      style={{
                        width: 80,
                        height: 80,
                        borderRadius: 8,
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid #ddd",
                        background: "#f5f5f5",
                      }}
                    >
                      <img
                        src={row.image || "https://via.placeholder.com/80"}
                        alt="Subcategoría"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ),
                },
                {
                  title: "Nombre",
                  key: "name",
                  render: (row) => <Text>{row.name}</Text>,
                },
                {
                  title: "Acciones",
                  key: "actions",
                  render: (row) => (
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setSelectedSubcategory(row);
                        setImageUrl(row.image || "");
                        setDrawerVisible(true);
                      }}
                    >
                      Editar Imagen
                    </Button>
                  ),
                },
              ]}
              pagination={false}
            />
          </Card>
        ))}

      {/* Drawer para cambiar imagen */}
      <Drawer
        title="Actualizar Imagen"
        placement="right"
        width={400}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        {selectedSubcategory && (
          <>
            <Title level={4}>{selectedSubcategory.name}</Title>
            <Input
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              placeholder="URL de la imagen"
              style={{ marginBottom: 10 }}
            />
            <Row justify="end">
              <Upload
                beforeUpload={() => false}
                listType="picture"
                maxCount={1}
                accept="image/*"
                onChange={(info) => handleUpload(info)}
                showUploadList={false}
              >
                <Button icon={<UploadOutlined />}>Subir Imagen</Button>
                {uploadLoading ? <Spin style={{ marginLeft: 10 }} /> : null}
              </Upload>
            </Row>
          </>
        )}
        <br />
        <Button
          type="primary"
          style={{ marginTop: 10 }}
          onClick={handleSaveImage}
        >
          Guardar cambios
        </Button>
      </Drawer>
    </Card>
  );
};

export default Subcategories;
