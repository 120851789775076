import { currencies } from "utils/constants";

class Money {
  constructor(
    amount,
    exponent = 2,
    currency = currencies.mxn,
    shouldFormat = false
  ) {
    this.amount = shouldFormat
      ? Math.trunc(amount * Math.pow(10, exponent))
      : Math.trunc(amount);
    this.exponent = exponent;
    this.currency = currency;
  }

  static add(...args) {
    return args.reduce((sum, money) => {
      if (!money) return sum;
      if (money.amount) return sum + Number(money.amount);
      return sum;
    }, 0);
  }

  static asDecimal(money) {
    return money?.amount / Math.pow(10, money?.exponent);
  }

  asObject() {
    return {
      amount: this.amount,
      exponent: this.exponent,
      currency: this.currency,
    };
  }
}

class MoneyTransfer {
  constructor(
    amount,
    exponent = 2,
    currency = currencies.mxn,
    shouldFormat = false
  ) {
    this.amount = shouldFormat
      ? Math.trunc(amount * Math.pow(10, exponent))
      : Math.trunc(amount);
    this.exponent = exponent;
    this.currency = currency;
  }

  static add(...args) {
    return args.reduce((sum, money) => {
      if (!money) return sum;
      if (money.amount) return sum + Number(money.amount);
      return sum;
    }, 0);
  }

  static asDecimal(money) {
    return money.amount / Math.pow(10, money.exponent);
  }

  asObject() {
    return {
      amount: this.amount,
      exponent: this.exponent,
      currency: this.currency,
    };
  }
}
export { Money, MoneyTransfer };
