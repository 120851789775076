import styled from "styled-components";
import { Button } from "antd";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  padding: 30px;
  width: 100%;
`;

const ExcelButton = styled(Button)`
  width: 100%;
  background-color: #4caf50;
  color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px;
  width: 100%;
`;

export { ModalContainer, ExcelButton, LoadingContainer };
