import styled from "styled-components";

const TitleContainer = styled.div`
  display: block;
  padding: 0;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export { TitleContainer, DescriptionContainer };
