import React, { useState } from "react";
import {
  Drawer,
  Typography,
  Table,
  Empty,
  Input,
  Image,
  Row,
  Col,
  Card,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Container } from "../elements";
import { useQuery } from "@apollo/client";
import { GET_BANNER } from "./requests";
import { formatCurrency } from "utils/functions";

const { Title, Text } = Typography;

const DetailsBanner = ({ showModal, setShowModal, selectedBanner }) => {
  const [searchSku, setSearchSku] = useState("");

  const { data: bannerData } = useQuery(GET_BANNER, {
    variables: {
      id: selectedBanner,
    },
    skip: !showModal,
  });

  const collaboration = bannerData?.banner?.collaboration;
  const products = bannerData?.banner?.products;

  console.log("collab", collaboration);

  const onClose = () => {
    setShowModal(false);
  };

  const filteredProducts = products?.filter((product) =>
    product.sku.toLowerCase().includes(searchSku.toLowerCase())
  );

  const columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      render: (text, record) => (
        <img
          src={text}
          alt={record.title}
          style={{ maxWidth: "80px", maxHeight: "80px" }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return <Text>{formatCurrency(text)}</Text>;
      },
    },
  ];

  return (
    <Container>
      <Drawer
        visible={showModal}
        onClose={onClose}
        title={<Title level={3}>Detalles de Banner</Title>}
        width={"60%"}
      >
        {products?.length === 0 ? (
          <>
            <Title level={4}>{`Colaboracion con ${collaboration?.name}`}</Title>
            <Card
              style={{
                borderRadius: 40,
                borderColor: collaboration?.colors?.primary,
                boxShadow: "5px 5px 5px lightblue",
              }}
            >
              <Row justify="center" align="middle">
                <Col span={12}>
                  <div style={{ marginRight: "20px", textAlign: "center" }}>
                    <Image src={collaboration?.thumbnail} alt="Thumbnail" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Text>Imagen de Miniatura</Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div style={{ textAlign: "center" }}>
                    <Image src={collaboration?.img} alt="Fondo" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <Text>Imagen de Fondo</Text>
                  </div>
                </Col>
              </Row>
              <div style={{ marginTop: 20 }}>
                <Text style={{ fontSize: 16 }}>Descripción: </Text>
                <Tag color="blue">{collaboration?.description}</Tag>
              </div>
              <div style={{ marginTop: 20 }}>
                <Text style={{ fontSize: 16 }}>Spotify: </Text>
                <Tag color="blue">{collaboration?.spotifyUrl}</Tag>
              </div>
            </Card>
            <Card
              title="Productos de la Colaboración"
              style={{
                marginTop: 20,
                borderRadius: 40,
                borderColor: collaboration?.colors?.secondary,
                boxShadow: "5px 5px 5px lightblue",
              }}
            >
              <Table
                columns={columns}
                dataSource={collaboration?.products}
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </Card>
          </>
        ) : (
          <>
            <Title level={4}>
              Estos son los Productos relacionados con el Banner
            </Title>
            <Card
              style={{
                borderRadius: 40,
                borderColor: "#71B530",
                boxShadow: "5px 5px 5px lightblue",
              }}
            >
              <div style={{ marginTop: 20 }}>
                <Input
                  style={{ width: 220 }}
                  placeholder="Buscar por SKU"
                  value={searchSku}
                  onChange={(e) => setSearchSku(e.target.value)}
                  prefix={<SearchOutlined />}
                  allowClear
                />
              </div>
              {filteredProducts?.length === 0 ? (
                <Empty description="No se encontraron productos" />
              ) : (
                <Table
                  columns={columns}
                  dataSource={filteredProducts}
                  rowKey="id"
                  pagination={{ pageSize: 10 }}
                />
              )}
            </Card>
          </>
        )}
      </Drawer>
    </Container>
  );
};

export default DetailsBanner;
