/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  Tag,
  Tooltip,
  Button,
  Card,
  Dropdown,
  Menu,
  Image,
  Space,
} from "antd";
import {
  ClockCircleOutlined,
  AuditOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  DownOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  CarOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TableTitle from "./table-title";
import IndicatorComponent from "./components/indicator";
import {
  Container,
  IndicatorContainer,
  RowInfo,
  TextWrapper,
} from "./elements";
import Uber from "./components/imgs/uber.png";
import Estafeta from "./components/imgs/estafeta.png";
import DetailDrawer from "./components/details";
import { formatCurrency } from "utils/functions";
import { useQuery } from "@apollo/client";
import { GET_ORDERS, GET_ORDERS_METRICS, GET_CLIENTS } from "./requests";
import { orderStatus } from "utils/constants";
import TrackOrder from "./components/track-order";

const { Title, Text } = Typography;

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [referenceSearch, setReferenceSearch] = useState(null);
  const [clientFilter, setClientFilter] = useState(null);
  const [serviceTypeSearch, setServiceTypeSearch] = useState(null);
  const [statusSearch, setStatusSearch] = useState([
    "in_progress",
    "completed",
  ]);
  const [dateSearch, setDateSearch] = useState(false);
  const [trackingOrder, setTrackingOrder] = useState(null);
  const [params, setParams] = useState({ page: 1, pageSize: 10 });
  const [showTrackOrder, setShowTrackOrder] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(undefined);
  const [currentStatus, setCurrentStatus] = useState(null);

  const {
    data: orders,
    refetch,
    loading,
  } = useQuery(GET_ORDERS, {
    variables: {
      filters: {
        ...(referenceSearch && { name: referenceSearch }),
        ...(serviceTypeSearch && { serviceType: serviceTypeSearch }),
        ...(statusSearch?.length > 0 && { status: statusSearch }),
        ...(dateSearch && { ...dateSearch }),
        ...(clientFilter && { client: clientFilter }),
      },
      params,
    },
    errorPolicy: "all",
  });

  const { data: ordersMetrics, loading: loadingMetrics } = useQuery(
    GET_ORDERS_METRICS,
    {
      errorPolicy: "all",
    }
  );
  const { data: allClients } = useQuery(GET_CLIENTS);
  const clients = allClients?.clients?.results ?? [];

  console.log(orders);
  const filters = [
    {
      type: "search",
      span: 4,
      label: "Buscar referencia: ",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (e) => setReferenceSearch(e.target.value),
    },
    {
      label: "Fecha generada: ",
      span: 7,
      type: "date",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (value) => {
        if (value) {
          const [startDate, endDate] = value;
          setDateSearch({
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          });
        } else {
          setDateSearch(false);
        }
      },
    },
    {
      type: "select",
      span: 3,
      label: "Filtrar por estatus: ",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (value) => setStatusSearch(value),
      defaultValue: "completed",
      options: [
        { label: "Cualquiera", value: false },
        { label: "En Progreso", value: "in_progress" },
        { label: "Entregado", value: "completed" },
        { label: "Cancelado", value: "cancelled" },
      ],
    },
    {
      type: "select",
      span: 2,
      label: "Tipo de servicio: ",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (value) => setServiceTypeSearch(value),
      defaultValue: false,
      options: [
        { label: "Cualquiera", value: false },
        { label: "Express", value: "express" },
        { label: "Estandar", value: "standard" },
      ],
    },
  ];

  const handleOpen = (row) => {
    setOpen(!open);
    setCurrentOrder(row);
  };

  const getIcon = (status) => {
    switch (status) {
      case "created":
        return <ClockCircleOutlined />;
      case "in_progress":
        return <SyncOutlined spin />;
      case "cancelled":
        return <ExclamationCircleOutlined />;
      case "standBy":
        return <CloseCircleOutlined />;
      case "completed":
        return <CheckCircleOutlined />;
      default:
        return <InfoCircleOutlined />;
    }
  };
  const handlePageChange = (page, pageSize) => {
    setParams({
      pageSize: Number(pageSize),
      page: Number(page),
    });
  };

  const columns = [
    {
      align: "center",
      key: "type",
      width: "5%",
      render: (row) => (
        <img
          src={row.serviceType === "express" ? Uber : Estafeta}
          style={{ width: 40, height: 40, borderRadius: 50 }}
          alt="Service Type"
        />
      ),
    },
    {
      title: "Referencia",
      align: "center",
      key: "name",
      width: "12%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.name}</Title>
          <Text>{row.franchise?.name || "N/A"}</Text>
        </RowInfo>
      ),
    },
    {
      title: "Cliente",
      width: "10%",
      align: "left",
      key: "name",
      render: (row) => (
        <RowInfo>
          <Title level={5}>
            {row?.client?.user?.firstName || "--"}{" "}
            {row?.client?.user?.lastName || ""}
          </Title>
          <p>{row?.client?.user?.mobile || ""}</p>
        </RowInfo>
      ),
    },
    {
      title: "Creación",
      align: "center",
      key: "createdAt",
      width: "13%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{moment(row.createdAt).calendar()}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Entrega",
      align: "center",
      key: "eta",
      width: "13%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{moment(row.delivery?.eta).calendar()}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Total",
      align: "center",
      key: "name",
      width: "15%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{formatCurrency(row.payment?.total)}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Estatus",
      align: "center",
      key: "status",
      width: "10%",
      render: (row) => (
        <Tag
          icon={getIcon(row.status)}
          color={orderStatus[row?.status]?.color ?? "#FFDD00"}
        >
          {orderStatus[row?.status]?.name ?? row?.status}
        </Tag>
      ),
    },
    {
      title: "Rastreo",
      align: "center",
      width: "8%",
      dataIndex: "delivery",
      key: "trackingId",
      render: (row) => (
        <TextWrapper>
          <Tag color="success">{row?.trackingId || "N/A"}</Tag>
        </TextWrapper>
      ),
    },
    {
      title: "Productos",
      align: "center",
      width: "1%",
      dataIndex: "lineItems",
      key: "secret",
      render: (items) =>
        items && items.length > 0 ? (
          <Dropdown
            trigger={["click"]}
            overlay={
              <Menu mode="vertical">
                {items?.map((item) => (
                  <Menu.Item key={item?.product?.id}>
                    <Row>
                      <Image
                        src={item?.product?.img ?? ""}
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 15,
                        }}
                      />
                      <div style={{ marginLeft: 20, marginRight: 20 }}>
                        <Title level={5}>
                          {item?.product?.sku ?? "Sin SKU"}
                        </Title>
                        <Text>{item?.product?.title ?? "Sin título"}</Text>{" "}
                        <br />
                        <Text> Cantidad: {item?.quantity ?? 0}</Text>
                      </div>
                    </Row>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button>
              <Space>
                Productos
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        ) : (
          <Text>N/A</Text>
        ),
    },
    {
      align: "center",
      width: "17%",
      title: () => (
        <Text
          level={4}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Acciones
        </Text>
      ),
      key: "action",
      render: (row) => {
        const { id, delivery, status } = row;
        const trackingId = delivery?.trackingId;
        const isTrackingDisabled =
          trackingId === null ||
          trackingId === "" ||
          trackingId === "No se pudo generar ID.";

        return (
          <Row>
            <Col>
              <Tooltip placement="top" title="Información">
                <Button
                  shape="circle"
                  type="primary"
                  size="large"
                  icon={<InfoCircleOutlined style={{ color: "white" }} />}
                  style={{ margin: "auto", display: "block" }}
                  onClick={() => handleOpen(row)}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip placement="top" title="Rastrear">
                <Button
                  shape="circle"
                  type="secondary"
                  size="large"
                  icon={<CarOutlined style={{ color: "black" }} />}
                  style={{ margin: "auto", display: "block", marginLeft: 2 }}
                  disabled={isTrackingDisabled}
                  onClick={async () => {
                    setShowTrackOrder(true);
                    setTrackingOrder(id);
                    setCurrentStatus(status);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Container>
      <Row>
        <Col span={24}>
          <IndicatorContainer>
            <Row style={{ justifyContent: "space-between" }}>
              <IndicatorComponent
                title="Órdenes Activas"
                value={
                  loadingMetrics
                    ? ""
                    : ordersMetrics?.ordersMetrics.activeOrders
                }
                subValue=""
                subValueColor="green"
                icon={
                  <ClockCircleOutlined
                    style={{ fontSize: 20, color: "white" }}
                  />
                }
              />
              <IndicatorComponent
                title="Monto Ventas"
                width
                value={
                  loadingMetrics
                    ? ""
                    : formatCurrency(ordersMetrics?.ordersMetrics.salesAmount)
                }
                titleLevel={3}
                subValueColor="#8c8c8c"
                icon={<BulbOutlined style={{ fontSize: 20, color: "white" }} />}
              />
              <IndicatorComponent
                title="Acumulado Órdenes"
                value={
                  loadingMetrics
                    ? ""
                    : ordersMetrics?.ordersMetrics.accumulatedOrders
                }
                subValue=""
                subValueColor="green"
                icon={
                  <AuditOutlined style={{ fontSize: 20, color: "white" }} />
                }
              />
              <IndicatorComponent
                title="Acumulado Ventas"
                value={
                  loadingMetrics
                    ? ""
                    : formatCurrency(
                        ordersMetrics?.ordersMetrics.accumulatedSales
                      )
                }
                titleLevel={3}
                subValue=""
                subValueColor="green"
                icon={
                  <AuditOutlined style={{ fontSize: 20, color: "white" }} />
                }
              />
            </Row>
          </IndicatorContainer>
        </Col>
      </Row>
      <Card
        style={{
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Table
          style={{
            overflowX: "hidden",
            width: "100%",
          }}
          loading={loading}
          locale="es"
          title={() => (
            <TableTitle
              filters={filters}
              clients={clients}
              setClientFilter={setClientFilter}
            />
          )}
          dataSource={orders?.orders?.results ?? []}
          columns={columns}
          rowKey="id"
          pagination={{
            current: Number(params.page),
            pageSize: Number(params.pageSize),
            total: orders?.orders?.info?.count,
            showTotal: (total) => <Text disabled>{`${total} resultados`}</Text>,
            onChange: handlePageChange,
          }}
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      {currentOrder ? (
        <DetailDrawer
          open={open}
          setOpen={setOpen}
          currentOrder={currentOrder}
          setCurrentOrder={setCurrentOrder}
          refetch={refetch}
        />
      ) : null}
      <TrackOrder
        showModal={showTrackOrder}
        setShowModal={setShowTrackOrder}
        trackingOrder={trackingOrder}
        setTrackingOrder={setTrackingOrder}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        refetch={refetch}
      />
    </Container>
  );
};

export default Orders;
