import styled from "styled-components";
import { Card } from "antd";

const Container = styled.div`
  overflow: auto;
`;

const CollaborationCard = styled(Card)`
  width: 100%; // Ajusta automáticamente al tamaño de la columna
  max-width: 400px; // Límite máximo para mantener proporción
  height: auto; // Altura automática para adaptarse al contenido
  cursor: pointer;
  margin: 0; // Elimina márgenes redundantes
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden; // Oculta cualquier contenido que se desborde
  border-radius: 5px; // Bordes redondeados
`;
export { Container, CollaborationCard };
