import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI;

// Obtener todas las redes sociales
export const getSocialNetworks = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/social-networks`);
    return response.data; // Devuelve los datos recibidos
  } catch (error) {
    console.error("Error fetching social networks:", error);
    throw error; // Maneja el error en el componente
  }
};

// Crear una nueva red social
export const createSocialNetwork = async (socialNetworkData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/social-networks/create`,
      socialNetworkData
    );
    return response.data; // Devuelve los datos del nuevo registro
  } catch (error) {
    console.error("Error creating social network:", error);
    throw error; // Maneja el error en el componente
  }
};

// Actualizar una red social existente
export const updateSocialNetwork = async (id, socialNetworkData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/social-networks/update/${id}`,
      socialNetworkData
    );
    return response.data; // Devuelve los datos actualizados
  } catch (error) {
    console.error("Error updating social network:", error);
    throw error; // Maneja el error en el componente
  }
};

// Eliminar una red social
export const deleteSocialNetwork = async (id) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/social-networks/delete/${id}`
    );
    return response.data; // Devuelve la respuesta del servidor
  } catch (error) {
    console.error("Error deleting social network:", error);
    throw error; // Maneja el error en el componente
  }
};
