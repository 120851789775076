import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import MainLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import Authentication from "views/private/routes/authentication";
import Dash from "views/private/routes/crm/dash"; // 🔥 Rutas protegidas
import { useUser } from "providers/user";

const Private = ({ history }) => {
  const { user, isInitializing } = useUser();

  if (isInitializing) {
    return <div></div>;
  }

  if (!user) {
    return (
      <AuthLayout>
        <Authentication />
      </AuthLayout>
    );
  }

  // 📌 Definir la página de inicio según el `userType`
  const getRedirectPath = () => {
    switch (user.userType) {
      case "admin":
        return "/dashboard"; // 🔥 Admin ve TODO
      case "employee":
        return "/orders";
      case "marketing":
        return "/social-networks";
      default:
        return "/dashboard"; // Cliente NO debería ver nada
    }
  };

  if (history.location.pathname === "/") {
    return <Redirect to={getRedirectPath()} />;
  }

  return (
    <MainLayout>
      <Dash />
    </MainLayout>
  );
};

export default withRouter(Private);
