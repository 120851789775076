import csvFileCreator from "csv-file-creator";
import { geoJSONType } from "./constants";

const printPdf = (base64PDF) =>
  new Promise((resolve) => {
    let byteCharacters;
    if (base64PDF.includes("base64"))
      byteCharacters = atob(base64PDF.split(",")[1]);
    else byteCharacters = base64PDF;

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "application/pdf" });

    const urlBlob = URL.createObjectURL(blob);
    const iframe = document.getElementById("print");
    iframe.src = urlBlob;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
        resolve();
      }, 100);
    };
  });

const downloadLeadsCSV = () => {
  let template = [["Empresa", "Contacto", "Email", "Telefono", "URL"]];
  csvFileCreator("crm-leads-template.csv", template);
};

const downloadClientsCSV = () => {
  let template = [
    [
      "Nombre",
      "ApellidoPaterno",
      "ApellidoMaterno",
      "CodigoPostal",
      "NumeroExterior",
      "NumeroInterior",
      "Calle",
      "Colonia",
      "Ciudad",
      "Estado",
      "Pais",
      "Agente",
      "Empresa",
      "RFC",
      "RegimenFiscal",
      "Correo",
      "CodigoPostalFiscal",
      "NumeroExteriorFiscal",
      "NumeroInteriorFiscal",
      "CalleFiscal",
      "ColoniaFiscal",
      "CiudadFiscal",
      "EstadoFiscal",
      "PaisFiscal",
    ],
  ];
  csvFileCreator("clients-template.csv", template);
};

const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const downloadTemplateCSV = (columns = Array, fileName) => {
  const template = [columns];
  csvFileCreator(`${fileName}-template.csv`, template);
};

const formatCurrency = (money) => {
  if (!money) return;
  const { amount, exponent } = money;

  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "mxn",
  }).format(amount / Math.pow(10, exponent));
};

const formatAddress = async (location) => {
  const {
    address: [googleAddress],
  } = location;
  if (!googleAddress["address_components"]) throw new Error("Invalid input!");
  const address = {};
  // Mapping between Dropin's address format and Google's address format.
  const addressComponentsMap = {
    country: "country",
    administrative_area_level_1: "state",
    locality: "city",
    sublocality: "colony",
    neighborhood: "neighborhood",
    route: "street",
    postal_code: "postcode",
  };

  for (const component of googleAddress["address_components"]) {
    for (const type of component.types) {
      if (addressComponentsMap[type]) {
        address[addressComponentsMap[type]] = component["long_name"];
      }
    }
  }

  address.colony = address?.neighborhood ?? address.colony;
  delete address?.neighborhood;

  return {
    destination: {
      ...address,
      fullName: location.result,
      location: {
        type: geoJSONType.Point,
        coordinates: [location.lng, location.lat],
      },
    },
  };
};

function isEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!isEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
}

function removeTypename(obj) {
  if (!obj || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  }

  const newObj = { ...obj };
  delete newObj["__typename"];

  for (const key in newObj) {
    newObj[key] = removeTypename(newObj[key]);
  }

  return newObj;
}

export {
  printPdf,
  downloadLeadsCSV,
  downloadClientsCSV,
  removeAccents,
  downloadTemplateCSV,
  formatCurrency,
  formatAddress,
  isEqual,
  removeTypename,
};
