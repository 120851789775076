import React from "react";
import PropTypes from "prop-types";
import { Typography, Button, Row, Col, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TitleContainer } from "./elements";

const { Text, Title } = Typography;

const TableTitle = ({
  setShowModal,
  searchTitle,
  setSearchTitle,
  programType,
  setProgramType,
  rewardType,
  setRewardType,
}) => {
  return (
    <TitleContainer>
      <Row
        gutter={[16, 16]} // Espaciado entre columnas
        style={{
          width: "100%",
          alignItems: "center",
        }}
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>Descuentos</Text>
        </Col>

        {/* Filtros y Botón */}
        <Col xs={24}>
          <Row gutter={[16, 16]} align="middle">
            {/* Filtro por Nombre */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Filtrar por Nombre
              </Title>
              <Input
                placeholder="Buscar por Nombre"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>

            {/* Filtro por Tipo de Promoción */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Tipo de Promoción
              </Title>
              <Input
                placeholder="Buscar por Tipo de Promoción"
                value={programType}
                onChange={(e) => setProgramType(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>

            {/* Filtro por Tipo de Recompensa */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Tipo de Recompensa
              </Title>
              <Input
                placeholder="Buscar por Tipo de Recompensa"
                value={rewardType}
                onChange={(e) => setRewardType(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>

            {/* Botón Crear Descuento */}
            <Col xs={24} sm={24} md={8} lg={24} xl={6}>
              <Row justify="end">
                <Col>
                  <Button
                    size="large"
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      borderRadius: 8,
                    }}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Crear Descuento
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleContainer>
  );
};

TableTitle.defaultProps = {
  search: "",
};

TableTitle.propTypes = {
  search: PropTypes.string,
};

export default TableTitle;
