import { gql } from "@apollo/client";

const CREATE_NOTIFICATION = gql`
  mutation createNotification($notification: NotificationCreateInput!) {
    createNotification(notification: $notification) {
      title
    }
  }
`;

export { CREATE_NOTIFICATION };
