/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { GET_SYNC_OPERATIONS } from "./requests";
import { useQuery } from "@apollo/client";
import { Card, Table, Typography, Tag } from "antd";
import TableTitle from "./table-title";
import { Container } from "./elements";
import moment from "moment";
import { mapSyncOperationStatus } from "utils/constants";

const { Text, Title } = Typography;

const Simualation = () => {
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
  });
  const {
    data: syncOperations,
    refetch,
    loading,
  } = useQuery(GET_SYNC_OPERATIONS, {
    variables: {
      params,
    },
  });

  const columns = [
    {
      title: "Fecha",
      align: "center",
      key: "createdAt",
      dataIndex: "createdAt",
      width: "33%",
      render: (row) => {
        return <Title level={5}>{moment(row).format("LLL")}</Title>;
      },
    },
    {
      title: "Operacion",
      align: "left",
      key: "name",
      dataIndex: "name",
      width: "33%",
      render: (row) => {
        return <Text>{row}</Text>;
      },
    },
    {
      title: "Estado",
      width: "33%",
      align: "left",
      key: "status",
      dataIndex: "status",
      render: (row) => {
        const { name, color } = mapSyncOperationStatus[row];
        return <Tag color={color}>{name}</Tag>;
      },
    },
  ];

  return (
    <Container>
      <Card
        style={{
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Table
          style={{
            overflowX: "hidden", // Esto asegura que no desborde el contenedor
            width: "100%",
          }}
          loading={loading}
          locale="es"
          title={() => <TableTitle refetch={refetch} loading={loading} />}
          dataSource={syncOperations?.syncOperations?.results ?? []}
          columns={columns}
          rowKey="id"
          pagination={{
            current: Number(params.page),
            defaultCurrent: params.page,
            pageSize: Number(params.pageSize),
            defaultPageSize: params.pageSize,
            total: syncOperations?.syncOperations?.info?.count,
            showTotal: (total) => <Text disabled>{`${total} resultados`}</Text>,
            onChange: (page, pageSize) =>
              setParams({
                pageSize: Number(pageSize),
                page: Number(page),
              }),
          }}
          scroll={{ x: 800, y: 500 }} // Permite desplazamiento horizontal si el ancho excede 800px
        />
      </Card>
    </Container>
  );
};

export default Simualation;
