import React, { useState } from "react";
import {
  Drawer,
  Typography,
  Divider,
  Row,
  Col,
  Table,
  Button,
  Image,
  List,
  Tag,
  message,
  Tooltip,
  Select,
} from "antd";
import { EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import {
  orderStatus,
  mapPaymentMethod,
  mapPaymentStatus,
} from "utils/constants";
import TitleSubtitle from "utils/components/title-subtitle";
import { formatCurrency } from "utils/functions";

import { useMutation } from "@apollo/client";
import { POST_ORDER, UPDATE_ORDER } from "./requests";

const { Option } = Select;

const DetailDrawer = ({
  open,
  setOpen,
  currentOrder,
  setCurrentOrder,
  refetch,
}) => {
  const [postOrder] = useMutation(POST_ORDER);
  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [editing, setEditing] = useState(false);
  const [newStatus, setNewStatus] = useState(null);

  const onClose = () => {
    setOpen(false);
    setCurrentOrder(undefined);
    setEditing(false);
    setNewStatus(null);
  };

  const changeStatus = async () => {
    try {
      if (!currentOrder) return;
      const payload = {
        variables: {
          id: currentOrder?.id,
          order: {
            status: newStatus,
          },
        },
      };
      await updateOrder(payload);
      await refetch();
      message.success("Orden Actualizada!");
      onClose();
    } catch (err) {
      console.log(err);
      message.error("Error al actualizar");
    }
  };

  const handleManualOdooPost = async (orderId) => {
    if (!orderId) return;
    console.log("Generando en Odoo...");
    try {
      const response = await postOrder({
        variables: {
          order: orderId,
        },
      });
      if (response?.data?.manuallyPostOrderToOdoo?.id) {
        message.success("Orden generada en Odoo correctamente");
        await refetch();
      } else {
        message.error("Error al generar orden en Odoo");
      }
    } catch (err) {
      console.log(err);
      message.error("Error en la generación de Odoo");
    }
  };

  if (!currentOrder) {
    return null;
  }

  const productsColumns = [
    {
      title: "Imagen",
      dataIndex: "product",
      key: "image",
      render: (product) =>
        product?.img ? (
          <Image
            src={product.img}
            alt="product"
            width={100}
            style={{ height: "auto" }}
          />
        ) : (
          "Sin imagen"
        ),
    },
    {
      title: "Título",
      dataIndex: "product",
      key: "title",
      render: (product) => (
        <Typography.Text strong>{product?.title ?? "N/A"}</Typography.Text>
      ),
    },
    {
      title: "SKU",
      dataIndex: "product",
      key: "sku",
      render: (product) => (
        <Typography.Text>{product?.sku ?? "N/A"}</Typography.Text>
      ),
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity) => <Typography.Text>{quantity ?? 0}</Typography.Text>,
    },
  ];

  return (
    <Drawer
      title="Detalles de la orden"
      placement="right"
      width={"40%"}
      onClose={onClose}
      open={open}
      extra={
        !editing ? (
          <Tooltip title="Editar">
            <Button
              type="primary"
              size="large"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => setEditing(true)}
            />
          </Tooltip>
        ) : (
          <>
            <Tooltip title="Guardar">
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<SaveOutlined />}
                onClick={changeStatus}
              />
            </Tooltip>
            <Tooltip title="Cancelar">
              <Button
                style={{ marginLeft: 10 }}
                type="default"
                size="large"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => setEditing(false)}
              />
            </Tooltip>
          </>
        )
      }
    >
      <TitleSubtitle
        title="Referencia"
        subtitle={currentOrder?.name ?? "N/A"}
      />
      <TitleSubtitle
        title="Cliente"
        subtitle={`${currentOrder?.client?.user?.firstName ?? "N/A"} ${
          currentOrder?.client?.user?.lastName ?? "N/A"
        }`}
      />
      {editing ? (
        <div style={{ marginBottom: "10px" }}>
          <Typography.Text style={{ color: "grey" }}>Estatus</Typography.Text>
          <br />
          <Select
            defaultValue={
              orderStatus[currentOrder?.status]?.name ?? "Desconocido"
            }
            onChange={(value) => setNewStatus(value)}
            style={{ width: 200 }}
          >
            {Object.values(orderStatus).map((status) => (
              <Option
                key={status.value}
                value={status.value}
                style={{ color: status.color }}
              >
                {status.name}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        <TitleSubtitle
          title="Estatus"
          subtitle={orderStatus[currentOrder?.status]?.name ?? "Desconocido"}
        />
      )}

      <TitleSubtitle
        title="Destino"
        subtitle={currentOrder?.location?.address?.fullName ?? "N/A"}
      />
      <Divider />
      <Typography.Title level={2}>Pago</Typography.Title>
      {["subTotal", "tax", "tip", "deliveryPrice", "total"].map((key) => (
        <Row key={key} justify="space-between" align="middle">
          <Col>
            <Typography.Text strong style={{ color: "grey", fontSize: "16px" }}>
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </Typography.Text>
          </Col>
          <Typography.Text strong style={{ fontSize: "20px" }}>
            {formatCurrency(currentOrder?.payment?.[key] ?? 0)}
          </Typography.Text>
        </Row>
      ))}
      <Divider />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        Intentos de pago
      </Typography.Text>

      <List
        itemLayout="horizontal"
        dataSource={currentOrder?.paymentProcessor ?? []}
        renderItem={(item, idx) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <>
                  {idx + 1}){" "}
                  <img
                    width={200}
                    src={mapPaymentMethod[item.method]?.image ?? ""}
                  />
                </>
              }
              title={
                <Row>
                  <Tag color={mapPaymentStatus[item.status]?.color ?? "red"}>
                    {mapPaymentStatus[item.status]?.name ?? "Desconocido"}
                  </Tag>
                </Row>
              }
              description={
                <>
                  <Row>
                    <Typography.Text strong>
                      ID de pago: {item.openPayId ?? item.clipId ?? "N/A"}
                    </Typography.Text>
                  </Row>
                  {item.url && (
                    <Row>
                      <Typography.Text strong>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          URL de Pago
                        </a>
                      </Typography.Text>
                    </Row>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />

      <Divider />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        Productos ({currentOrder?.lineItems?.length ?? 0})
      </Typography.Text>
      {currentOrder?.lineItems?.length > 0 && (
        <Table
          dataSource={currentOrder.lineItems}
          columns={productsColumns}
          rowKey={(item) => item.product.id}
          pagination={false}
          bordered
        />
      )}
      <Divider />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        Generar en Odoo
      </Typography.Text>
      <br />
      <Typography.Text>
        {currentOrder?.registeredInOdoo
          ? "Orden ya registrada en Odoo"
          : "No registrada en Odoo"}
      </Typography.Text>
      <br />
      <br />
      <Button
        type="primary"
        onClick={() => handleManualOdooPost(currentOrder?.id)}
      >
        Generar en Odoo
      </Button>
    </Drawer>
  );
};

export default DetailDrawer;
