import { gql } from "@apollo/client";

const GET_ORDERS = gql`
  query orders($params: QueryParams, $filters: OrderSearchInput) {
    orders(params: $params, filters: $filters) {
      info {
        count
        prev
        next
        pages
      }
      results {
        id
        name
        status
        serviceType
        createdAt
        franchise {
          id
          name
        }
        payment {
          method
          status
          total {
            amount
            currency
            exponent
          }
          tax {
            amount
            currency
            exponent
          }
          subTotal {
            amount
            currency
            exponent
          }
        }
        lineItems {
          product {
            id
            description
            img
            title
            sku
          }
          quantity
        }
        odooId
        client {
          id
          user {
            firstName
            lastName
            email
            phone
            mobile
          }
          odooId
        }
        location {
          address {
            fullName
            location {
              coordinates
            }
          }
        }
        delivery {
          eta
          trackingId
          status
          deliveryPrice {
            amount
            currency
            exponent
          }
        }
        paymentProcessor {
          id
          status
          url
          mercadoPagoPaymentId
          openPayId
          clipId
          method
        }
      }
    }
  }
`;

const GET_ORDERS_METRICS = gql`
  query ordersMetrics {
    ordersMetrics {
      activeOrders
      accumulatedOrders
      salesAmount {
        amount
        exponent
        currency
      }
      accumulatedSales {
        amount
        exponent
        currency
      }
    }
  }
`;

const GET_CLIENTS = gql`
  query clients {
    clients {
      info {
        count
      }
      results {
        id
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export { GET_ORDERS, GET_ORDERS_METRICS, GET_CLIENTS };
