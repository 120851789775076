import { gql } from "@apollo/client";

const GET_BANNER = gql`
  query banner($id: ID!) {
    banner(id: $id) {
      id
      title
      img
      products {
        id
        title
        img
        sku
        category {
          name
          value
        }
        price {
          amount
          exponent
        }
      }
      collaboration {
        id
        name
        img
        thumbnail
        description
        type
        spotifyUrl
        colors {
          primary
          secondary
        }
        products {
          id
          title
          img
          sku
          price {
            amount
            exponent
          }
        }
      }
    }
  }
`;

export { GET_BANNER };
