import styled from "styled-components";
import { Card } from "antd";

const Container = styled.div`
  padding: 0px;

  .ant-table-thead .ant-table-cell {
    background-color: white;
    color: #8c8c8c;
    font-weight: 600;
  }
`;

const IndicatorContainer = styled.div`
  padding-bottom: 20px;
`;

// Tarjeta de indicadores
const Indicator = styled(Card)`
  min-height: 120px; /* Ajusta la altura mínima */
  width: 24%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Asegura que el contenido se distribuya correctamente */
  padding: 10px; /* Agrega espacio interno */
  overflow: hidden;

  @media (max-width: 1373px) {
    width: 49%;
    height: 100px;
    margin-bottom: 15px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
  }
`;

const Icon = styled.div`
  height: 40px;
  width: 40px;
  background-color: #71b530;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RowInfo = styled.div`
  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
  }
`;

const TextWrapper = styled.div`
  word-wrap: break-word;
`;
export { Container, IndicatorContainer, Indicator, Icon, RowInfo, TextWrapper };
