import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI;

/**
 * 📌 Obtener todas las subcategorías con opción de filtros
 * @param {Object} params - Parámetros opcionales (paginación, filtros)
 * @returns {Promise<Object>} - Datos de la respuesta de la API
 */
export const getSubcategories = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/subcategories`, { params });
    return response.data;
  } catch (error) {
    console.error("❌ Error al obtener subcategorías:", error);
    throw error;
  }
};

/**
 * 📌 Obtener todas las subcategorías con opción de filtros
 * @param {Object} params - Parámetros opcionales (paginación, filtros)
 * @returns {Promise<Object>} - Datos de la respuesta de la API
 */
export const getCategories = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/categories`, { params });
    return response.data;
  } catch (error) {
    console.error("❌ Error al obtener subcategorías:", error);
    throw error;
  }
};
