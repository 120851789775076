import React, { useState, useRef } from "react";
import {
  Button,
  Table,
  Drawer,
  Row,
  Col,
  Typography,
  Divider,
  Switch,
  Form,
  Input,
  Tooltip,
  Card,
  Tag,
  message,
} from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import TitleSubtitle from "utils/components/title-subtitle";
import { formatCurrency, removeTypename } from "utils/functions";
import { RowInfo } from "../../elements";
import { useMutation } from "@apollo/client";
import { UPDATE_PRODUCT } from "./requests";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;

// 🎨 Diccionario de colores por categoría
const CATEGORY_COLORS = {
  EXCLUSIVOS: "blue",
  BIENESTAR: "green",
  "SMOKE SHOP": "volcano",
  ARTISTAS: "cyan",
  OTROS: "geekblue",
};

const getCategoryColor = (category) =>
  CATEGORY_COLORS[category?.toUpperCase()] || "gray";

const DetailDrawer = ({ open, setOpen, product, setProduct, refetch }) => {
  const [productTraits, setProductTraits] = useState(
    removeTypename(product.traits)
  );
  const [saleOk, setSaleOk] = useState(product.saleOk);
  const [recommended, setRecommended] = useState(product.recommended);

  const [updateTagsMutation] = useMutation(UPDATE_PRODUCT);
  const [isEditing, setIsEditing] = useState(false);

  const formRef = useRef(null);

  const onFinish = async () => {
    const traits = productTraits.map((trait) => ({
      title: trait.title,
      description: trait.description,
    }));

    try {
      const payload = {
        variables: {
          id: product?.id,
          product: {
            traits,
            saleOk,
            recommended,
          },
        },
      };
      await updateTagsMutation(payload);
      await refetch();
      onClose();
      message.success("Operación realizada con éxito!");
    } catch (err) {
      console.error("Error al guardar traits:", err);
      message.error("Hubo un error!");
    }
  };

  const handleBusinessChange = (reqIndex, key, value) => {
    const updatedProductsTraits = productTraits?.map((req, index) => {
      if (index === reqIndex) {
        return {
          ...req,
          [key]: value,
        };
      }
      return req;
    });
    setProductTraits(updatedProductsTraits);
  };

  const addRequirement = () => {
    const newData = {
      title: "",
      description: "",
    };
    setProductTraits([...productTraits, newData]);

    formRef.current.setFieldsValue({ traits: [...productTraits, newData] });
  };

  const handleRemoveTrait = async (index) => {
    const newTraits = [...productTraits];
    newTraits.splice(index, 1);
    setProductTraits(newTraits);

    formRef.current.setFieldsValue({ traits: newTraits });
  };

  const onClose = async () => {
    setOpen(false);
    setProduct(undefined);
  };

  const handleSwitchToggle = (stateFunc) => {
    stateFunc((prevState) => {
      return !prevState;
    });
  };

  const columnTemplate = [
    {
      align: "left",
      width: 100,
      key: "img",
      render: (row) => {
        return (
          <img
            src={row.product?.img}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        );
      },
    },
    {
      title: "SKU",
      align: "left",
      width: 150,
      key: "sku",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.product?.sku}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Producto",
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.product?.title}</Title>
            <p>{row.product?.description}</p>
          </RowInfo>
        );
      },
    },
    {
      title: "Cantidad",
      align: "left",
      key: "qty",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.quantity}</Title>
          </RowInfo>
        );
      },
    },
  ];

  const columnVariants = [
    {
      align: "left",
      width: 100,
      key: "img",
      render: (row) => {
        return (
          <img
            src={row.img}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        );
      },
    },
    {
      title: "SKU",
      align: "left",
      width: 150,
      key: "sku",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.sku}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Producto",
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.description}</Title>
            <p>{row.title}</p>
          </RowInfo>
        );
      },
    },
  ];
  return (
    <Drawer
      title="Detalles del producto"
      placement="right"
      width={"50%"}
      onClose={onClose}
      visible={open}
      extra={
        <>
          {!isEditing ? (
            <Tooltip title={"editar"}>
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={"Cancelar"}>
              <Button
                style={{ marginLeft: 10 }}
                type="default"
                size="large"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => {
                  setIsEditing(false);
                }}
              />
            </Tooltip>
          )}
        </>
      }
    >
      <Row gutter={10} justify="middle">
        <Col span={6}>
          <img
            src={product?.img}
            style={{ width: 100, height: 100, borderRadius: 50 }}
          />
        </Col>
        <Col span={18}>
          <Title level={3}> {product?.title} </Title>
          <Typography.Paragraph> {product?.description} </Typography.Paragraph>
        </Col>
      </Row>
      <Divider />
      <TitleSubtitle title="Precio" subtitle={formatCurrency(product.price)} />
      <TitleSubtitle title="SKU" subtitle={product.sku} />
      {product?.bundle?.id ? (
        <TitleSubtitle
          title="Contenido"
          subtitle={
            <Table
              locale="es"
              size="small"
              dataSource={product.bundle.products}
              columns={columnTemplate}
              rowKey="id"
              pagination={false}
            />
          }
        />
      ) : null}
      <TitleSubtitle
        title="Variants"
        subtitle={
          <Table
            locale="es"
            size="small"
            dataSource={product.variants}
            columns={columnVariants}
            rowKey="id"
            pagination={false}
          />
        }
      />
      <Title level={3}> Descripción</Title>

      {!isEditing ? (
        productTraits.map((trait, index) => (
          <Card key={index} style={{ marginBottom: 8 }}>
            <div>
              <Title level={4}>{trait.title}</Title>
              <Typography.Paragraph>{trait.description}</Typography.Paragraph>
            </div>
          </Card>
        ))
      ) : (
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          ref={formRef}
          initialValues={{ traits: productTraits }}
        >
          <Form.List name="traits">
            {(fields) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key} style={{ marginBottom: 8 }}>
                    <div
                      style={{
                        display: "flex",
                        flex: "row",
                        alignItems: "center",
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "title"]}
                        rules={[{ required: true, message: "Falta el título" }]}
                        style={{ flex: 1, marginTop: 21 }}
                      >
                        <Input
                          onChange={(e) =>
                            handleBusinessChange(index, "title", e.target.value)
                          }
                          placeholder="Título"
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => handleRemoveTrait(index)}
                        style={{ margin: "0 8px" }}
                      />
                    </div>
                    <Form.Item
                      {...restField}
                      name={[name, "description"]}
                      rules={[
                        { required: true, message: "Falta la descripción" },
                      ]}
                    >
                      <Input.TextArea
                        onChange={(e) =>
                          handleBusinessChange(
                            index,
                            "description",
                            e.target.value
                          )
                        }
                        rows={4}
                        placeholder="Descripción"
                      />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => addRequirement()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Nueva Descripción
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}

      <Divider />

      <Title level={4}>Categoría</Title>
      <Tag color={getCategoryColor(product.productCategory)}>
        {product.productCategory}
      </Tag>

      <Title level={4} style={{ marginTop: 10 }}>
        Subcategoría
      </Title>
      {product.productSubcategory ? (
        <Tag color="purple">{product.productSubcategory}</Tag>
      ) : (
        <Tag color="red">N/A</Tag>
      )}
      <Divider />
      <Row style={{ justifyContent: "left" }}>
        <Title level={3}> ¿Se puede vender? </Title>
        {isEditing ? (
          <Switch
            style={{ marginLeft: "15px", marginTop: "5px" }}
            checked={saleOk}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => handleSwitchToggle(setSaleOk)}
          />
        ) : (
          <Tag
            color={saleOk ? "blue" : "red"}
            style={{
              marginLeft: 10,
              marginBottom: 10,
              fontSize: 14,
              padding: "6px 14px",
            }}
          >
            {saleOk ? "Sí" : "No"}
          </Tag>
        )}
      </Row>
      <Row style={{ justifyContent: "left", marginTop: 10 }}>
        <Title level={3}>
          {isEditing ? "¿Recomendar producto?" : "¿Producto recomendado?"}{" "}
        </Title>
        {isEditing ? (
          <Switch
            style={{ marginLeft: "15px", marginTop: "5px" }}
            checked={recommended}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={() => handleSwitchToggle(setRecommended)}
          />
        ) : (
          <Tag
            color={recommended ? "blue" : "red"}
            style={{
              marginLeft: 10,
              marginBottom: 10,
              fontSize: 14,
              padding: "6px 14px",
            }}
          >
            {recommended ? "Sí" : "No"}
          </Tag>
        )}
      </Row>

      {isEditing && (
        <div style={{ marginTop: 20 }}>
          <Button type="primary" onClick={() => formRef.current.submit()}>
            Guardar
          </Button>
        </div>
      )}
    </Drawer>
  );
};

export default DetailDrawer;
