import React, { useState } from "react";
import {
  Table,
  Card,
  Typography,
  Row,
  Col,
  Input,
  Button,
  Popconfirm,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Container, RowInfo } from "./elements";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_USER, GET_USERS_EMPLOYEES } from "./requests";
import NewMarketing from "./components/new-marketing/index";

const { Title, Text } = Typography;

const Marketing = () => {
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [showNewMarketing, setShowNewMarketing] = useState(false);
  const [editingMarketing, setEditingMarketing] = useState(null);

  const {
    data: usersData,
    refetch,
    loading,
  } = useQuery(GET_USERS_EMPLOYEES, {
    variables: {
      filters: { name: searchName, email: searchEmail, userType: "marketing" },
    },
  });

  const [deleteUser] = useMutation(DELETE_USER);

  const allMarketings = usersData?.users?.results || [];

  const handleDelete = async (id) => {
    try {
      const { data } = await deleteUser({ variables: { id } });

      if (data.deleteUser) {
        message.success("Usuario eliminado correctamente.");
        refetch(); // Recargar la lista de usuarios
      } else {
        message.error("No se pudo eliminar el usuario.");
      }
    } catch (error) {
      message.error("Error al eliminar el usuario.");
    }
  };

  const handleEdit = (user) => {
    setEditingMarketing(user);
    setShowNewMarketing(true);
  };

  const columns = [
    {
      align: "left",
      width: 120,
      key: "img",
      render: (row) =>
        row.img ? (
          <img
            src={row.img}
            alt="Usuario"
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        ) : null,
    },
    {
      title: "Nombre",
      align: "left",
      key: "user",
      render: (user) => {
        const fullName = user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.firstName;
        return (
          <RowInfo>
            <Title level={5}>{fullName}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Correo",
      align: "left",
      key: "email",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.email}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Teléfono",
      align: "left",
      key: "phone",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.phone ?? row.mobile}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Acciones",
      align: "center",
      key: "actions",
      render: (row) => (
        <Row>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(row)}
            style={{ marginRight: 8 }}
          />
          <Popconfirm
            title="¿Seguro que deseas eliminar este usuario?"
            onConfirm={() => handleDelete(row.id)}
            okText="Sí"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Row>
      ),
    },
  ];

  return (
    <Container>
      <Card style={{ borderRadius: 5 }}>
        <Text style={{ fontSize: 20, fontWeight: "800" }}>Marketing</Text>
        <Row justify="space-between">
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col>
                <Text strong>Filtrar por Nombre</Text>
                <Input
                  placeholder="Buscar por Nombre"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  allowClear
                />
              </Col>
              <Col>
                <Text strong>Filtrar por Correo</Text>
                <Input
                  placeholder="Buscar por Correo"
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                  allowClear
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditingMarketing(null);
                setShowNewMarketing(true);
              }}
            >
              Crear Usuario de Marketing
            </Button>
          </Col>
        </Row>
        <Table
          style={{ overflowX: "hidden", width: "100%" }}
          loading={loading}
          locale="es"
          dataSource={allMarketings}
          columns={columns}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      <NewMarketing
        showModal={showNewMarketing}
        setShowModal={setShowNewMarketing}
        refetch={refetch}
        editingMarketing={editingMarketing}
        setEditingMarketing={setEditingMarketing}
      />
    </Container>
  );
};

export default Marketing;
