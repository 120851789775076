import React from "react";
import {
  Drawer,
  Button,
  Table,
  Form,
  Input,
  Typography,
  Select,
  message,
} from "antd";
import TitleSubtitle from "utils/components/title-subtitle";
import { RowInfo } from "../../elements";
import { UPDATE_BUNDLE } from "./requests";
import { useMutation } from "@apollo/client";

const { Title } = Typography;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const { Option } = Select;

const DetailDrawer = ({ open, setOpen, bundle, setBundle, refetch }) => {
  const [form] = Form.useForm();

  const [updateBundleMutation] = useMutation(UPDATE_BUNDLE);

  const onClose = () => {
    setOpen(false);
    setBundle(undefined);
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          id: bundle.id,
          bundle: {
            ...values,
          },
        },
      };

      await updateBundleMutation(payload);
      refetch();
      message.success("Se modificó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
    }
  };

  const columns = [
    {
      align: "left",
      width: 100,
      key: "img",
      render: (row) => {
        return (
          <img
            src={row.product?.img}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        );
      },
    },
    {
      title: "SKU",
      align: "left",
      width: 150,
      key: "sku",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.product?.sku}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Producto",
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.product?.title}</Title>
            <p>{row.product?.description}</p>
          </RowInfo>
        );
      },
    },
    {
      title: "Cantidad",
      align: "left",
      key: "qty",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.quantity}</Title>
          </RowInfo>
        );
      },
    },
  ];

  return (
    <Drawer
      title="Detalles"
      placement="right"
      width={"50%"}
      onClose={onClose}
      visible={open}
    >
      <Form
        {...layout}
        form={form}
        name="bundle"
        onFinish={onFinish}
        style={{
          maxWidth: 800,
        }}
      >
        <Form.Item
          name="title"
          label="Titulo"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={bundle?.title}
        >
          <Input defaultValue={bundle?.title} placeholder="Título" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={bundle?.description}
        >
          <Input defaultValue={bundle?.description} placeholder="Descripción" />
        </Form.Item>
        <Form.Item
          name="img"
          label="Imagen (link)"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={bundle?.img}
        >
          <Input defaultValue={bundle?.img} placeholder="URL de imagen" />
        </Form.Item>
        <Form.Item
          name="status"
          label="Estatus"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={bundle?.status}
        >
          <Select
            defaultValue={bundle?.status}
            placeholder="Estatus actual de la promoción"
            allowClear
          >
            <Option value="active">Activo</Option>
            <Option value="inactive">Inactivo</Option>
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Editar
          </Button>
        </Form.Item>
      </Form>
      <TitleSubtitle
        title="Contenido"
        subtitle={
          <Table
            locale="es"
            size="small"
            dataSource={bundle.products}
            columns={columns}
            rowKey="id"
            pagination={false}
          />
        }
      />
    </Drawer>
  );
};

export default DetailDrawer;
