import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI;

// Obtener todas las membresías con paginación y filtros
export const getMemberships = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/memberships`, { params });
    return response.data;
  } catch (error) {
    console.error("Error al obtener membresías:", error);
    throw error;
  }
};

// Obtener una membresía por ID
export const getMembershipById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/memberships/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al obtener membresía con id ${id}:`, error);
    throw error;
  }
};

// Crear una nueva membresía
export const createMembership = async (membershipData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/memberships/create`,
      membershipData
    );
    return response.data;
  } catch (error) {
    console.error("Error al crear membresía:", error);
    throw error;
  }
};

// Actualizar una membresía existente
export const updateMembership = async (id, membershipData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/memberships/update/${id}`,
      membershipData
    );
    return response.data;
  } catch (error) {
    console.error(`Error al actualizar membresía con id ${id}:`, error);
    throw error;
  }
};

// Eliminar una membresía (eliminación lógica)
export const deleteMembership = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/memberships/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar membresía con id ${id}:`, error);
    throw error;
  }
};

// Buscar datos por CURP antes de la creación
export const searchByCURP = async (curp) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/memberships/search/curp/${curp}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error al buscar membresía con CURP ${curp}:`, error);
    throw error;
  }
};
