import { gql } from "@apollo/client";

const UPDATE_PRODUCT_CATEGORY = gql`
  mutation updateProductCategory($category: ProductCategoryUpdate!) {
    updateProductCategory(category: $category) {
      id
      name
      image
      description
      priority
    }
  }
`;

const UPDATE_PRODUCT_WELLNESS = gql`
  mutation updateProductWellness($category: ProductWellnessUpdate!) {
    updateProductWellness(category: $category) {
      id
      name
      image
      description
      priority
    }
  }
`;
export { UPDATE_PRODUCT_CATEGORY, UPDATE_PRODUCT_WELLNESS };
