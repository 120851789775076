import React, { useEffect } from "react";
import { Modal, Typography, Button, Form, Input, message } from "antd";
import { UPDATE_BANNER } from "./requests";
import { useMutation } from "@apollo/client";

const { Title, Text } = Typography;
const EditBanner = ({
  showModal,
  setShowModal,
  selectedBanner,
  setSelectedBanner,
  refetch,
}) => {
  console.log("selectedBanner", selectedBanner);
  const [form] = Form.useForm();
  const [updateBannerMutation] = useMutation(UPDATE_BANNER);

  useEffect(() => {
    if (showModal) {
      form.resetFields();
    }
  }, [showModal, form]);

  const onClose = () => {
    setShowModal(false);
    setSelectedBanner(null);
  };

  const onFinish = async (values) => {
    console.log("values", values);
    try {
      const payload = {
        variables: {
          id: selectedBanner.id,
          banner: {
            title: values.title || selectedBanner.title,
            type: values.type || selectedBanner.type,
            img: selectedBanner.img,
          },
        },
      };
      await updateBannerMutation(payload);
      await refetch();
      message.success("Se editó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al editar");
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        ShowModal
        onCancel={onClose}
        footer={null}
        title={<Title level={3}>Editar Banner</Title>}
      >
        <Form
          form={form}
          name="banner"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Nombre
            </Text>
            <Form.Item name="title">
              <Input
                defaultValue={selectedBanner?.title}
                placeholder="Título"
              />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Tipo
            </Text>
            <Form.Item name="type">
              <Input defaultValue={selectedBanner?.type} placeholder="Tipo" />
            </Form.Item>
          </div>
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Editar Banner
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default EditBanner;
