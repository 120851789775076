import React from "react";
import { Indicator, Icon } from "../elements";
import { Row, Col, Typography } from "antd";

const { Title, Text } = Typography;

const IndicatorComponent = ({
  title,
  value,
  subValue,
  subValueColor,
  icon,
  titleLevel,
}) => {
  return (
    <Indicator
      style={{
        borderRadius: 5,
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // Centra verticalmente el contenido
          height: "100%", // Asegura que ocupe todo el alto disponible
        }}
      >
        <Col
          style={{
            flex: "1", // Toma todo el espacio disponible
            marginRight: "10px", // Espacio entre texto e icono
          }}
        >
          <Text
            strong
            style={{
              color: "#8c8c8c",
              fontSize: "0.8rem",
              display: "block", // Mantiene el texto en una línea
              marginBottom: "5px", // Espaciado entre el título y el valor
              lineHeight: 1, // Asegura buena separación en líneas
              whiteSpace: "nowrap", // Evita que el texto haga un salto de línea
              overflow: "hidden", // Oculta el desbordamiento del texto
              textOverflow: "ellipsis", // Agrega puntos suspensivos al final si se desborda
            }}
          >
            {title}
          </Text>
          <Row
            style={{
              alignItems: "baseline", // Alinea el valor y subvalor correctamente
              flexWrap: "nowrap", // Evita que se ajuste en pantallas pequeñas
            }}
          >
            <Title
              style={{
                fontSize: "1.4rem", // Ajusta el tamaño del valor
                margin: 0, // Elimina márgenes por defecto
                lineHeight: 1, // Asegura buena separación en líneas
                whiteSpace: "nowrap", // Evita que el texto haga un salto de línea
                overflow: "hidden", // Oculta el desbordamiento del texto
                textOverflow: "ellipsis", // Agrega puntos suspensivos al final si se desborda
              }}
              level={titleLevel || 1}
            >
              {value}
            </Title>
            <Text
              style={{
                color: subValueColor,
                fontSize: "0.9rem",
                marginLeft: "10px", // Separación entre el valor y el subvalor
              }}
            >
              {subValue}
            </Text>
          </Row>
        </Col>
        <Col
          style={{
            flex: "0 0 40px", // Ancho fijo para el icono
            height: "40px", // Asegura el tamaño del contenedor
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon>{icon}</Icon>
        </Col>
      </Row>
    </Indicator>
  );
};

export default IndicatorComponent;
