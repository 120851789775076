import React, { useState } from "react";
import { Typography, Row, Card, Button, Tooltip, Col } from "antd";
import { Container, BannerCard } from "./elements";
import { useQuery } from "@apollo/client";
import { GET_LANDING_ICONS } from "./requests";
import { EditOutlined } from "@ant-design/icons";
import EditLandingIcon from "./edit-landing";

const { Title, Text } = Typography;
const { Meta } = Card;

const Banners = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingLanding, setEditingLanding] = useState(null);

  const { data: landingIcons, refetch } = useQuery(GET_LANDING_ICONS);

  return (
    <Container>
      <Row>
        <Col span={24}>
          <Title style={{ fontSize: "1.4rem" }} level={2}>
            Landing App
          </Title>
        </Col>
      </Row>
      <Row
        gutter={[
          {
            xs: 0, // Sin espacio horizontal en pantallas pequeñas
            sm: 0, // Espaciado horizontal pequeño
            md: 16, // Espaciado horizontal mediano
            lg: 20, // Espaciado horizontal grande
          },
          {
            xs: 8, // Espaciado vertical pequeño en pantallas pequeñas
            sm: 16, // Espaciado vertical mediano
            md: 24, // Espaciado vertical grande
            lg: 32, // Espaciado vertical muy grande
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        {landingIcons?.landingPages?.results?.map(
          ({ id, image, name, priority }) => {
            return (
              <Col
                key={id}
                xs={24} // Una tarjeta por fila en pantallas pequeñas
                sm={12} // Dos tarjetas por fila en pantallas pequeñas
                md={12} // Tres tarjetas por fila en pantallas medianas
                lg={8} // Cuatro tarjetas por fila en pantallas grandes
                xl={6} // Cinco tarjetas por fila en pantallas extra grandes
              >
                <BannerCard
                  style={{
                    borderRadius: 5,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                  cover={
                    <img
                      src={image}
                      alt={"No disponible"}
                      style={{
                        height: "200px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  }
                >
                  {/* Contenido */}
                  <Meta
                    title={
                      <div>
                        <Title
                          level={5}
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            marginBottom: "8px",
                          }}
                        >
                          {name}
                        </Title>
                        <Text type="secondary" style={{ fontSize: "0.9rem" }}>
                          Posición: {priority}
                        </Text>
                      </div>
                    }
                  />
                  {/* Footer con acciones */}
                  <div
                    style={{
                      marginTop: "16px",
                      textAlign: "center",
                      borderTop: "1px solid #f0f0f0",
                      paddingTop: "10px",
                    }}
                  >
                    <Tooltip title="Editar">
                      <Button
                        type="primary"
                        icon={<EditOutlined />}
                        style={{
                          borderRadius: 10,
                        }}
                        onClick={() => {
                          setShowEditModal(true);
                          setEditingLanding({
                            id,
                            image,
                            name,
                            priority,
                          });
                        }}
                      />
                    </Tooltip>
                  </div>
                </BannerCard>
              </Col>
            );
          }
        )}
      </Row>

      <EditLandingIcon
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        editingLanding={editingLanding}
        setEditingLanding={setEditingLanding}
        refetch={refetch}
      />
    </Container>
  );
};

export default Banners;
