import React, { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Tooltip,
  Input,
  Table,
  Card,
} from "antd";
import { Container, RowInfo, TitleContainer } from "./elements";
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS } from "./requests";
import { PlusOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import CreateNotification from "./create-notification/index";
import EditNotification from "./edit-notification/index";
import SendNotification from "./send-notification/index";
import moment from "moment";

const { Title, Text } = Typography;

const Notifications = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");

  const {
    data: notificationsData,
    refetch: refetchNotifications,
    loading: loadingNotifications,
  } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      filters: {
        title: searchTitle,
      },
    },
  });

  const allNotifications = notificationsData?.notifications?.results;

  const sortedNotifications = allNotifications
    ? [...allNotifications].sort((a, b) => {
        return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
      })
    : [];

  const columns = [
    {
      title: "Nombre",
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.title}</Title>
            <p>{row.description}</p>
          </RowInfo>
        );
      },
    },
    {
      title: "Fecha Agendada",
      align: "center",
      key: "scheduledDate",
      render: (row) => {
        const scheduledDate = row?.scheduledDate
          ? moment(row?.scheduledDate).format("D MMMM YYYY, HH:mm")
          : "N/A";
        return (
          <RowInfo>
            <Title level={5}>{scheduledDate}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Fecha de Creación",
      align: "center",
      key: "createdAt",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>
              {moment(row?.createdAt).format("D MMM YYYY")}
            </Title>
          </RowInfo>
        );
      },
    },
    {
      width: "10%",
      title: () => (
        <Text level={4} style={{ marginTop: "auto", marginBottom: "auto" }}>
          Acciones
        </Text>
      ),

      key: "action",
      render: (row) => {
        const isPastDate = moment(row.scheduledDate).isBefore(moment());
        return (
          <Row>
            <Tooltip title="Editar">
              <Button
                type="primary"
                icon={<EditOutlined />}
                shape="circle"
                size="large"
                style={{ margin: 5 }}
                onClick={() => {
                  setSelectedNotification(row);
                  setShowEditModal(true);
                }}
              />
            </Tooltip>
            <Tooltip title={isPastDate ? "Ya se Envió" : "Mandar notificación"}>
              <Button
                icon={<SendOutlined style={{ color: "white" }} />}
                shape="circle"
                size="large"
                style={{ margin: 5, backgroundColor: "#52B266" }}
                disabled={isPastDate}
                onClick={() => {
                  setSelectedNotification(row);
                  setShowSendModal(true);
                }}
              />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <Container>
      <Card
        style={{
          borderRadius: 5,
          overflow: "hidden",
        }}
      >
        <TitleContainer>
          <Row
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gutter={[16, 16]}
            align="middle"
          >
            {/* Título */}
            <Col span={24} lg={12}>
              <Text style={{ fontSize: 20, fontWeight: "800" }}>
                Notificaciones
              </Text>
            </Col>

            {/* Botón Crear Notificación */}
            <Col span={24} lg={12} style={{ textAlign: "right" }}>
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Crear Notificación
              </Button>
            </Col>

            {/* Campo de Filtro */}
            <Col span={24}>
              <Title level={5}>Filtrar por Nombre</Title>
              <Input
                placeholder="Buscar por Nombre"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                allowClear
              />
            </Col>
          </Row>
        </TitleContainer>
        <div style={{ marginTop: 10 }}>
          <Table
            style={{
              overflowX: "hidden", // Esto asegura que no desborde el contenedor
              width: "100%",
            }}
            loading={loadingNotifications}
            locale="es"
            dataSource={sortedNotifications}
            columns={columns}
            rowKey="id"
            scroll={{ x: 800, y: 500 }}
          />
        </div>
      </Card>
      <CreateNotification
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetchNotifications}
      />
      <EditNotification
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        refetch={refetchNotifications}
      />
      <SendNotification
        showModal={showSendModal}
        setShowModal={setShowSendModal}
        selectedNotification={selectedNotification}
        setSelectedNotification={setSelectedNotification}
        refetch={refetchNotifications}
      />
    </Container>
  );
};

export default Notifications;
