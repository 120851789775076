import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  Upload,
  Select,
  message,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { createSocialNetwork, updateSocialNetwork } from "../requests";
import useUpload from "hooks/use-upload";

const { Title } = Typography;
const { Option } = Select;

const EditSocialNetwork = ({
  showModal,
  setShowModal,
  editingNetwork,
  refetch,
}) => {
  const [form] = Form.useForm();
  const { upload } = useUpload();
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    if (showModal) {
      form.resetFields();
      console.log("Editing Network:", editingNetwork);
      if (editingNetwork) {
        form.setFieldsValue({
          name: editingNetwork.name,
          link: editingNetwork.link,
          icon: editingNetwork.icon,
          status: editingNetwork.status || "active",
        });
      } else {
        form.setFieldsValue({ status: "active" });
      }
    }
  }, [showModal, editingNetwork, form]);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      try {
        const fileName = `social-networks/icons/${new Date().toISOString()}/${
          fileList[0]?.name
        }`;
        const url = await upload(fileList[0].originFileObj, fileName);
        form.setFieldsValue({ icon: url });
        message.success("Imagen cargada correctamente.");
      } catch (error) {
        message.error("Error al cargar la imagen.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const onFinish = async (values) => {
    try {
      if (editingNetwork) {
        await updateSocialNetwork(editingNetwork._id, values);
        message.success("Red social actualizada con éxito.");
      } else {
        await createSocialNetwork(values);
        message.success("Red social creada con éxito.");
      }
      refetch();
    } catch (error) {
      message.error("Error al guardar los datos.");
    } finally {
      onClose();
    }
  };

  return (
    <Drawer
      title={
        <Title level={3} style={{ margin: 0 }}>
          {editingNetwork ? "Editar Red Social" : "Agregar Red Social"}
        </Title>
      }
      width={400}
      onClose={onClose}
      open={showModal}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ status: "active" }} // Valor por defecto
      >
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: "Por favor, ingresa el nombre." }]}
        >
          <Input placeholder="Nombre de la red social" />
        </Form.Item>

        <Form.Item
          label="URL"
          name="link"
          rules={[{ required: true, message: "Por favor, ingresa la URL." }]}
        >
          <Input placeholder="URL de la red social" />
        </Form.Item>

        <Form.Item
          label="Estado"
          name="status"
          rules={[
            { required: true, message: "Por favor, selecciona un estado." },
          ]}
        >
          <Select placeholder="Selecciona un estado">
            <Option value="active">Activo</Option>
            <Option value="inactive">Inactivo</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Icono"
          rules={[{ required: true, message: "Por favor, sube un icono." }]}
        >
          <Upload
            beforeUpload={() => false}
            listType="picture"
            maxCount={1}
            accept="image/*"
            onChange={(info) => handleUpload(info)}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Subir Icono</Button>
            {uploadLoading ? <Spin style={{ margin: 10 }} /> : null}
          </Upload>
        </Form.Item>

        <Form.Item
          name="icon"
          rules={[{ required: true, message: "Por favor, sube un icono." }]}
        >
          <Input placeholder="URL del icono" disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {editingNetwork ? "Guardar Cambios" : "Crear Red Social"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditSocialNetwork;
