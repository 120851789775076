import React from "react";
import { Typography, Col, Button, Divider } from "antd";
import { TitleContainer } from "./elements";

const { Text, Paragraph } = Typography;

const TableTitle = ({ refetch, loading }) => {
  return (
    <div>
      <TitleContainer>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: "800", marginRight: 20 }}>
            Operaciones
          </Text>
          <Button
            type="primary"
            style={{
              borderRadius: 5,
            }}
            onClick={async () => await refetch()}
            loading={loading}
          >
            Refrescar
          </Button>
        </Col>
      </TitleContainer>
      <Divider />
      <div>
        <Col>
          <Paragraph>
            Recuerda que cada operación toma aproximadamente 8 minutos...
          </Paragraph>
        </Col>
      </div>
    </div>
  );
};

export default TableTitle;
