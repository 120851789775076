import { gql } from "@apollo/client";

const UPDATE_FRANCHISE_SCHEDULE_EVENTS = gql`
  mutation updateFranchiseScheduleEvents(
    $id: ID!
    $event: FranchiseEventsInput!
  ) {
    updateFranchiseScheduleEvents(id: $id, event: $event) {
      id
    }
  }
`;

export { UPDATE_FRANCHISE_SCHEDULE_EVENTS };
