import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  message,
  Col,
  Row,
  Select,
  Input,
  Tooltip,
} from "antd";
import { PlusOutlined, PhoneOutlined } from "@ant-design/icons";
import { TitleContainer } from "./elements";
import { useMutation } from "@apollo/client";
import { SYNC_ODOO_WAREHOUSES } from "./requests";

const { Text } = Typography;

const TableTitle = ({ filters, setFranchiseSearch, setShowPhoneEditModal }) => {
  const [syncWarehousesMutation] = useMutation(SYNC_ODOO_WAREHOUSES);
  const syncWarehouses = async () => {
    try {
      syncWarehousesMutation();
      message.success("La operación ha iniciado exitosamente!");
      message.info("Para ver el progreso, dirigete al apartado de Operaciones");
    } catch (e) {
      console.log(e);
      message.error("Error al sincronizar");
    }
  };

  return (
    <TitleContainer>
      <Row
        style={{
          width: "100%",
          padding: 0,
        }}
        gutter={[20, 16]} // Espaciado entre columnas
        align="middle"
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 20, fontWeight: "800" }}>Franquicias</Text>
        </Col>

        {/* Campo de búsqueda */}
        <Col xs={24} sm={12} md={8} lg={6}>
          <Text
            level={5}
            style={{ fontSize: "0.8rem", fontWeight: "800", marginRight: 5 }}
          >
            Buscar Franquicia
          </Text>
          <Input
            placeholder="Buscar Franquicia"
            onChange={(e) => setFranchiseSearch(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>

        {/* Filtros dinámicos */}
        {filters.map((filter, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={4}>
            <Text
              style={{ fontWeight: "800", marginRight: 5, fontSize: "0.8rem" }}
            >
              {filter.label}
            </Text>
            {filter.type === "select" && (
              <Select
                defaultValue={filter.defaultValue}
                style={{ width: "100%" }}
                placeholder={filter.placeholder}
                onChange={filter.onChange}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={filter?.options ?? []}
              />
            )}
          </Col>
        ))}

        {/* Botones alineados a la derecha */}
        <Col xs={24} sm={24} md={12} lg={10}>
          <Row justify="end" gutter={[10, 0]}>
            {/* Botón de modificación de teléfonos */}
            <Col>
              <Tooltip title="Modificación de teléfonos">
                <Button
                  style={{
                    borderRadius: 10,
                    fontSize: "0.8rem",
                  }}
                  size="large"
                  type="primary"
                  icon={<PhoneOutlined />}
                  onClick={() => {
                    setShowPhoneEditModal(true);
                  }}
                />
              </Tooltip>
            </Col>

            {/* Botón de sincronización */}
            <Col>
              <Button
                style={{
                  borderRadius: 8,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={syncWarehouses}
              >
                SYNC Odoo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleContainer>
  );
};

TableTitle.defaultProps = {
  search: "",
};

TableTitle.propTypes = {
  search: PropTypes.string,
  // setSearch: PropTypes.func.isRequired,
};

export default TableTitle;
