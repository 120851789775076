import React, { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Tooltip,
  message,
  Modal,
  Select,
  Input,
} from "antd";
const { Text } = Typography;
import { Container, CollaborationCard } from "./elements";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_COLLABORATIONS,
  UPDATE_COLLABORATION,
  DELETE_COLLABORATION,
} from "./requests";
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import CreateCollaboration from "./create-collaboration/index";
import EditCollaboration from "./edit-collaboration/index";
import ViewProducts from "./view-products/index";
import { typesCollaborations } from "utils/constants";

const { Title } = Typography;
const { Meta } = Card;
const { Option } = Select;

const Collaborations = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [collaborationActiveOrDisable, setCollaborationActiveOrDisable] =
    useState(null);
  const [statusCollaboration, setStatusCollaboration] = useState("all");
  const [typeCollaboration, setTypeCollaboration] = useState("all");
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [selectedCollaborationForDelete, setSelectedCollaborationForDelete] =
    useState(null);
  const [products, setProducts] = useState(null);
  const [searchName, setSearchName] = useState("");

  const { data: collaborationsData, refetch: refetchCollaborations } = useQuery(
    GET_COLLABORATIONS,
    {
      variables: {
        filters: {
          active: statusCollaboration,
          type: typeCollaboration,
          name: searchName,
        },
      },
    }
  );

  const confirmDisableModal = (collaboration) => {
    setCollaborationActiveOrDisable(collaboration);
    setConfirmDisable(true);
  };

  const [activeOrDisableCollaborationMutation] =
    useMutation(UPDATE_COLLABORATION);
  const activeOrDisableCollaboration = async () => {
    try {
      const payload = {
        variables: {
          id: collaborationActiveOrDisable?.id,
          collaboration: {
            active: !collaborationActiveOrDisable?.active,
          },
        },
      };
      await activeOrDisableCollaborationMutation(payload);
      await refetchCollaborations();
      setConfirmDisable(false);
      message.success("El cambio de completó!");
    } catch (err) {
      console.log(err);
      message.error("Error al intentar el cambio");
    }
  };

  const [deleteCollaborationMutation] = useMutation(DELETE_COLLABORATION);
  const deleteCollaboration = async () => {
    try {
      const payload = {
        variables: {
          id: selectedCollaborationForDelete,
        },
      };
      await deleteCollaborationMutation(payload);
      await refetchCollaborations();
      setConfirmDeleteModalVisible(false);
      message.success("El collaboration se eliminó!");
    } catch (err) {
      console.log(err);
      message.error("Error al eliminar el collaboration");
    }
  };

  return (
    <Container>
      <Row
        style={{
          width: "100%",
          padding: 0,
          marginBottom: "10px",
        }}
        gutter={[20, 16]} // Espaciado entre columnas y filas
        align="middle"
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>
            Colaboraciones
          </Text>
        </Col>

        {/* Filtrar por Nombre */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Filtrar por Nombre
          </Title>
          <Input
            size="large"
            placeholder="Buscar por Nombre"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            allowClear
            style={{ width: "100%" }}
          />
        </Col>

        {/* Filtrar por Estatus */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Filtrar por Estatus
          </Title>
          <Select
            size="large"
            defaultValue="Todo"
            style={{ width: "100%" }}
            onChange={async (value) => {
              await refetchCollaborations();
              setStatusCollaboration(value);
            }}
          >
            <Option value="all">Todo</Option>
            <Option value="true">Activos</Option>
            <Option value="false">Deshabilitados</Option>
          </Select>
        </Col>

        {/* Filtrar por Tipo */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Filtrar por Tipo
          </Title>
          <Select
            size="large"
            defaultValue="all"
            style={{ width: "100%" }}
            onChange={async (value) => {
              await refetchCollaborations();
              setTypeCollaboration(value);
            }}
          >
            <Option value="all">Todo</Option>
            {Object.values(typesCollaborations).map((type) => {
              return (
                <Option key={type.value} value={type.value}>
                  {type.name}
                </Option>
              );
            })}
          </Select>
        </Col>

        {/* Botón Crear Colaboración */}
        <Col xs={24} sm={24} md={8} lg={24} xl={12}>
          <Row justify="end">
            <Col>
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                style={{
                  width: "100%",
                  borderRadius: 8,
                  fontSize: "0.9rem",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Crear Colaboración
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        gutter={[
          {
            xs: 0, // Sin espacio horizontal en pantallas pequeñas
            sm: 0, // Espaciado horizontal pequeño
            md: 16, // Espaciado horizontal mediano
            lg: 20, // Espaciado horizontal grande
          },
          {
            xs: 8, // Espaciado vertical pequeño en pantallas pequeñas
            sm: 16, // Espaciado vertical mediano
            md: 24, // Espaciado vertical grande
            lg: 32, // Espaciado vertical muy grande
          },
        ]}
        style={{
          width: "100%",
          overflow: "auto",
          flexWrap: "wrap",
        }}
      >
        {collaborationsData?.collaborations?.results?.map((row) => {
          return (
            <Col
              key={row.id}
              xs={24} // Una tarjeta por fila en pantallas extra pequeñas
              sm={12} // Dos tarjetas por fila en pantallas pequeñas
              md={12} // Tres tarjetas por fila en pantallas medianas
              lg={8} // Cuatro tarjetas por fila en pantallas grandes
              xl={6} // Cinco tarjetas por fila en pantallas extra grandes
            >
              <CollaborationCard
                style={{
                  borderRadius: 5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                cover={
                  <img
                    src={row.img}
                    alt={row.name}
                    style={{
                      height: "200px",
                      objectFit: "cover",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  />
                }
              >
                <Meta
                  title={<Title level={5}>{row.name}</Title>}
                  description={
                    <span
                      style={{
                        fontSize: "0.9rem", // Ajusta el tamaño de la fuente
                        lineHeight: "1.2rem", // Espaciado entre líneas
                        display: "-webkit-box", // Soporte para elipsis
                        WebkitBoxOrient: "vertical", // Dirección vertical
                        overflow: "hidden", // Ocultar contenido desbordante
                        textOverflow: "ellipsis", // Mostrar puntos suspensivos
                        WebkitLineClamp: 2, // Limitar a 2 líneas
                        height: "2.4rem", // Asegurar que tenga espacio para 2 líneas
                      }}
                    >
                      {row.description}
                    </span>
                  }
                  style={{ marginBottom: "16px" }}
                />
                {/* Footer con Acciones */}
                <Row
                  gutter={[16, 16]}
                  justify="center"
                  style={{
                    marginTop: "16px",
                    borderTop: "1px solid #f0f0f0",
                    paddingTop: "16px",
                  }}
                >
                  <Tooltip title="Ver Productos">
                    <Button
                      type="primary"
                      icon={<UnorderedListOutlined />}
                      style={{
                        marginRight: 10,
                        borderRadius: 10,
                        width: 60,
                      }}
                      onClick={() => {
                        setProducts(row.products);
                        setShowProductsModal(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Editar">
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      style={{
                        marginRight: 10,
                        borderRadius: 10,
                        width: 60,
                      }}
                      onClick={() => {
                        setSelectedCollaboration(row);
                        setShowEditModal(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={row.active ? "Deshabilitar" : "Activar"}>
                    <Button
                      type={row.active ? "danger" : "default"}
                      icon={
                        row.active ? (
                          <CloseCircleOutlined />
                        ) : (
                          <CheckCircleOutlined style={{ color: "black" }} />
                        )
                      }
                      style={{
                        marginRight: 10,
                        borderRadius: 10,
                        width: 60,
                        backgroundColor: row.active ? undefined : "orange",
                      }}
                      onClick={() => confirmDisableModal(row)}
                    />
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <Button
                      type="dashed"
                      ghost
                      danger
                      icon={<DeleteOutlined />}
                      style={{
                        borderRadius: 10,
                        width: 60,
                      }}
                      onClick={() => {
                        setSelectedCollaborationForDelete(row.id);
                        setConfirmDeleteModalVisible(true);
                      }}
                    />
                  </Tooltip>
                </Row>
              </CollaborationCard>
            </Col>
          );
        })}
      </Row>

      <Modal
        title={
          collaborationActiveOrDisable?.active
            ? "Confirmar deshabilitación"
            : "Confirmar activación"
        }
        visible={confirmDisable}
        onOk={activeOrDisableCollaboration}
        onCancel={() => setConfirmDisable(false)}
      >
        {collaborationActiveOrDisable?.active ? (
          <p>¿Desea deshabilitar este collaboration?</p>
        ) : (
          <p>¿Desea activar este collaboration?</p>
        )}
      </Modal>
      <Modal
        title="Confirmar eliminación"
        visible={confirmDeleteModalVisible}
        onOk={deleteCollaboration}
        onCancel={() => setConfirmDeleteModalVisible(false)}
      >
        <p>¿Desea eliminar este collaboration?</p>
      </Modal>

      <CreateCollaboration
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetchCollaborations}
      />
      <EditCollaboration
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedCollaboration={selectedCollaboration}
        setSelectedCollaboration={setSelectedCollaboration}
        refetch={refetchCollaborations}
      />
      <ViewProducts
        showModal={showProductsModal}
        setShowModal={setShowProductsModal}
        products={products}
      />
    </Container>
  );
};

export default Collaborations;
