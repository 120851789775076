import { gql } from "@apollo/client";

const CREATE_PRODUCT_CATEGORY = gql`
  mutation createProductCategory($category: ProductCategoryCreate!) {
    createProductCategory(category: $category) {
      id
    }
  }
`;

const CREATE_PRODUCT_WELLNESS = gql`
  mutation createProductWellness($category: ProductWellnessCreate!) {
    createProductWellness(category: $category) {
      id
    }
  }
`;

export { CREATE_PRODUCT_CATEGORY, CREATE_PRODUCT_WELLNESS };
