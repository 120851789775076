import { gql } from "@apollo/client";

const GET_LANDING_ICONS = gql`
  query {
    landingPages(params: { pageSize: 3, page: 1 }) {
      results {
        id
        name
        image
        priority
      }
    }
  }
`;

export { GET_LANDING_ICONS };
