import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      success
      message
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export { CREATE_USER };
