import React, { useState } from "react";
import { Table, Card, Typography, Tag, Row, Tooltip, Button } from "antd";
import TableTitle from "./table-title";
import {
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import DetailDrawer from "./components/details";
import { Container, RowInfo } from "./elements";
import { GET_DISCOUNTS } from "./requests";
import { useQuery } from "@apollo/client";
import CreateDiscount from "./create-discount/index";
import moment from "moment";

moment().format("YYYY MM DD");

const { Title, Text } = Typography;

const Discounts = () => {
  const [open, setOpen] = useState(false);
  const [editDiscount, setEditDiscount] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");

  const [programType, setProgramType] = useState("");
  const [rewardType, setRewardType] = useState("");

  const handleOpen = () => {
    setOpen(!open);
  };

  const {
    data: discountsData,
    refetch: refetchDiscounts,
    loading: loadingDiscounts,
  } = useQuery(GET_DISCOUNTS, {
    variables: {
      filters: {
        title: searchTitle,
        programType,
        rewardType,
      },
    },
  });

  const refactorDate = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    // return `${day}-${month}-${year}`;
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      title: "Descuento",
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.title}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Tipo de Promoción",
      align: "left",
      key: "programType",
      render: (row) => {
        return (
          <RowInfo>
            <Tag color="green">{row.programType}</Tag>
          </RowInfo>
        );
      },
    },
    {
      title: "Tipo de Recompensa",
      align: "left",
      key: "rewardType",
      render: (row) => {
        return (
          <RowInfo>
            <Tag color="green">{row.rewardType}</Tag>
          </RowInfo>
        );
      },
    },
    {
      title: "Fin",
      align: "left",
      key: "finalDate",
      render: (row) => (
        <Tag color="yellow">
          {row.finalDate ? refactorDate(row.finalDate) : "No expira"}
        </Tag>
      ),
    },
    {
      title: "Punto de Venta",
      align: "center",
      width: 70,
      key: "posOk",
      render: (row) =>
        row.posOk ? (
          <CheckOutlined style={{ fontSize: "20px" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "20px" }} />
        ),
    },
    {
      title: "Ventas",
      align: "center",
      width: 70,
      key: "saleOk",
      render: (row) =>
        row.saleOk ? (
          <CheckOutlined style={{ fontSize: "20px" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "20px" }} />
        ),
    },
    {
      title: "Web",
      align: "center",
      width: 70,
      key: "ecommerceOk",
      render: (row) =>
        row.ecommerceOk ? (
          <CheckOutlined style={{ fontSize: "20px" }} />
        ) : (
          <CloseOutlined style={{ fontSize: "20px" }} />
        ),
    },
    {
      title: () => (
        <Text level={4} style={{ marginTop: "auto", marginBottom: "auto" }}>
          Acciones
        </Text>
      ),

      key: "action",
      render: (row) => (
        <Row>
          <Tooltip placement="top" title="Editar">
            <Button
              shape="circle"
              type="primary"
              size="large"
              icon={<InfoCircleOutlined style={{ color: "white" }} />}
              style={{ margin: 5 }}
              onClick={() => {
                handleOpen(row);
                setEditDiscount(row);
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <Container>
        <Card
          style={{
            borderRadius: 5,
          }}
        >
          <Table
            style={{
              overflowX: "hidden", // Esto asegura que no desborde el contenedor
              width: "100%",
            }}
            loading={loadingDiscounts}
            locale="es"
            title={() => (
              <TableTitle
                setShowModal={setShowModal}
                searchTitle={searchTitle}
                setSearchTitle={setSearchTitle}
                programType={programType}
                setProgramType={setProgramType}
                rewardType={rewardType}
                setRewardType={setRewardType}
              />
            )}
            dataSource={discountsData?.discounts?.results ?? []}
            columns={columns}
            rowKey="id"
            scroll={{ x: 800, y: 500 }}
          />
        </Card>
        {editDiscount && (
          <DetailDrawer
            open={open}
            setOpen={setOpen}
            discount={editDiscount}
            setDiscount={setEditDiscount}
            refetch={refetchDiscounts}
          />
        )}
      </Container>
      <CreateDiscount
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetchDiscounts}
      />
    </div>
  );
};

export default Discounts;
