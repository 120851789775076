/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  Tag,
  Tooltip,
  Button,
  Card,
  Dropdown,
  Menu,
  Image,
  Space,
  Select,
} from "antd";
import {
  ClockCircleOutlined,
  InfoCircleOutlined,
  DownOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TableTitle from "./table-title";
import { Container, RowInfo, TextWrapper } from "./elements";
import Uber from "./components/imgs/uber.png";
import Estafeta from "./components/imgs/estafeta.png";
import DetailDrawer from "./components/details";
import { formatCurrency } from "utils/functions";
import { useQuery } from "@apollo/client";
import { GET_ORDERS, GET_CLIENTS } from "./requests";
import { checklistStatus } from "utils/constants";

const { Title, Text } = Typography;
const { Option } = Select;

const Orders = () => {
  const [open, setOpen] = useState(false);
  const [referenceSearch, setReferenceSearch] = useState(null);
  const [serviceTypeSearch, setServiceTypeSearch] = useState(null);
  const [statusSearch, setStatusSearch] = useState(["created"]);
  const [dateSearch, setDateSearch] = useState(false);
  const [params, setParams] = useState({ page: 1, pageSize: 10 });
  const [clientFilter, setClientFilter] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(undefined);

  const {
    data: orders,
    refetch,
    loading,
  } = useQuery(GET_ORDERS, {
    variables: {
      filters: {
        ...(referenceSearch && { name: referenceSearch }),
        ...(serviceTypeSearch && { serviceType: serviceTypeSearch }),
        ...(statusSearch?.length > 0 && { status: statusSearch }),
        ...(dateSearch && { ...dateSearch }),
        ...(clientFilter && { client: clientFilter }),
      },
      params,
    },
    errorPolicy: "all",
  });

  const { data: allClients } = useQuery(GET_CLIENTS);
  const clients = allClients?.clients?.results ?? [];

  const filters = [
    {
      type: "search",
      span: 4,
      label: "Buscar referencia: ",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (e) => setReferenceSearch(e.target.value),
    },
    {
      label: "Fecha generada: ",
      span: 7,
      type: "date",
      width: "100%",
      fontSize: "0.8rem",
      onChange: (value) => {
        if (value) {
          const [startDate, endDate] = value;
          setDateSearch({
            startDate: moment(startDate?._d).format("YYYY-MM-DD"),
            endDate: moment(endDate?._d).format("YYYY-MM-DD"),
          });
        } else {
          setDateSearch(false);
        }
      },
    },
  ];

  const handleOpen = (row) => {
    setOpen(!open);
    setCurrentOrder(row);
  };

  const getIcon = (status) => {
    switch (status) {
      case "created":
        return <ClockCircleOutlined />;
      case "in_progress":
        return <SyncOutlined spin />;
      case "cancelled":
        return <ExclamationCircleOutlined />;
      case "standBy":
        return <CloseCircleOutlined />;
      case "completed":
        return <CheckCircleOutlined />;
      default:
        return <InfoCircleOutlined />;
    }
  };

  const columns = [
    {
      align: "center",
      key: "type",
      width: "5%",
      render: (row) => (
        <img
          src={row?.serviceType === "express" ? Uber : Estafeta}
          style={{ width: 40, height: 40, borderRadius: 50 }}
          alt="Service Type"
        />
      ),
    },
    {
      title: "Referencia",
      align: "center",
      key: "name",
      width: "12%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row?.name || "N/A"}</Title>
          <Text>{row?.franchise?.name || "N/A"}</Text>
        </RowInfo>
      ),
    },
    {
      title: "Cliente",
      width: "10%",
      align: "left",
      key: "client",
      render: (row) => (
        <RowInfo>
          <Title level={5}>
            {row?.client?.user?.firstName || "---"}{" "}
            {row?.client?.user?.lastName || ""}
          </Title>
          <p>{row?.client?.user?.mobile || ""}</p>
        </RowInfo>
      ),
    },
    {
      title: "Creación",
      align: "center",
      key: "createdAt",
      width: "13%",
      render: (row) => (
        <RowInfo>
          <Title level={5}>
            {row?.createdAt ? moment(row.createdAt).calendar() : "N/A"}
          </Title>
        </RowInfo>
      ),
    },
    {
      title: "Estatus",
      align: "center",
      key: "status",
      width: "10%",
      render: (row) => (
        <Tag
          icon={getIcon(row?.status)}
          color={checklistStatus[row?.status]?.color ?? "#FFDD00"}
        >
          {checklistStatus[row?.status]?.name ?? row?.status}
        </Tag>
      ),
    },
    {
      title: "Rastreo",
      align: "center",
      width: "8%",
      dataIndex: "delivery",
      key: "trackingId",
      render: (row) => (
        <TextWrapper>
          <Tag color="success">{row?.trackingId || "N/A"}</Tag>
        </TextWrapper>
      ),
    },
    {
      align: "center",
      width: "17%",
      title: "Acciones",
      key: "action",
      render: (row) => (
        <Row>
          <Col>
            <Tooltip placement="top" title="Información">
              <Button
                shape="circle"
                type="primary"
                size="large"
                icon={<InfoCircleOutlined style={{ color: "white" }} />}
                onClick={() => handleOpen(row)}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Container>
      <Card
        style={{
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Table
          style={{
            overflowX: "hidden",
            width: "100%",
          }}
          loading={loading}
          locale="es"
          title={() => (
            <TableTitle
              filters={filters}
              clients={clients}
              setClientFilter={setClientFilter}
            />
          )}
          dataSource={orders?.orders?.results ?? []}
          columns={columns}
          rowKey="id"
          pagination={{
            current: Number(params.page),
            defaultCurrent: params.page,
            pageSize: Number(params.pageSize),
            defaultPageSize: params.pageSize,
            total: orders?.orders?.info?.count,
            showTotal: (total) => <Text disabled>{`${total} resultados`}</Text>,
            onChange: (page, pageSize) =>
              setParams({
                pageSize: Number(pageSize),
                page: Number(page),
              }),
          }}
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      {currentOrder && (
        <DetailDrawer
          open={open}
          setOpen={setOpen}
          currentOrder={currentOrder}
          setCurrentOrder={setCurrentOrder}
          refetch={refetch}
        />
      )}
    </Container>
  );
};

export default Orders;
