import { gql } from "@apollo/client";

const UPDATE_PRODUCT = gql`
  mutation updateProduct($id: ID!, $product: ProductsUpdate) {
    updateProduct(id: $id, product: $product) {
      id
    }
  }
`;

const GET_PRODUCT_WELLNESS = gql`
  query {
    productWellnesses(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
        description
        image
        priority
      }
    }
  }
`;

const GET_PRODUCT_CATEGORIES = gql`
  query {
    productCategories(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
        description
        image
        priority
      }
    }
  }
`;

export { UPDATE_PRODUCT, GET_PRODUCT_WELLNESS, GET_PRODUCT_CATEGORIES };
