import { gql } from "@apollo/client";

const GET_TRACK_ORDER = gql`
  query trackOrder($order: ID!) {
    trackOrder(order: $order) {
      type
      standard {
        id
        status
        trackingData {
          fecha
          descripcion
        }
      }
      express {
        status
        dropoff_deadline
        dropoff_eta
        pickup_eta
        tracking_url
        pickup {
          name
          phone_number
          address
          status
          status_timestamp
        }
        dropoff {
          name
          phone_number
          address
          status
          status_timestamp
        }
        courier {
          name
          vehicle_type
          phone_number
          img_href
        }
      }
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $order: OrderUpdate!) {
    updateOrder(id: $id, order: $order) {
      id
    }
  }
`;

export { GET_TRACK_ORDER, UPDATE_ORDER };
