import React, { useState } from "react";
import { Table, Card, Typography, Tag, Row, Tooltip, Button } from "antd";
import TableTitle from "./table-title";
import { InfoCircleOutlined } from "@ant-design/icons";
import DetailDrawer from "./components/details";
import { Container, RowInfo } from "./elements";
import { useQuery } from "@apollo/client";
import { GET_BUNDLES, GET_PRODUCTS } from "./requests";
import CreateBundle from "./create-bundle";

const { Title } = Typography;

const Bundles = () => {
  const [open, setOpen] = useState(false);
  const [createBundle, setCreateBundle] = useState(false);
  const [editBundle, setEditBundle] = useState(undefined);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchDescription, setSearchDescription] = useState("");

  const { data, refetch, loading } = useQuery(GET_BUNDLES, {
    variables: {
      filters: {
        title: searchTitle,
        description: searchDescription,
      },
    },
  });
  const { data: products } = useQuery(GET_PRODUCTS);

  const handleOpen = () => {
    setOpen(!open);
  };

  const columns = [
    {
      align: "left",
      width: 120,
      key: "img",
      render: (row) => {
        return (
          <img
            src={row.img}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        );
      },
    },
    {
      title: (
        <>
          <Title level={5}>Titulo</Title>
          <p>Descripción</p>
        </>
      ),
      align: "left",
      key: "title",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.title}</Title>
            <p>{row.description}</p>
          </RowInfo>
        );
      },
    },
    {
      title: "Estatus",
      align: "left",
      key: "category",
      render: (row) => (
        <Tag color={row.active ? "green" : "red"}>
          {row.active ? "Activo" : "Inactivo"}
        </Tag>
      ),
    },
    {
      title: () => (
        <Title
          level={4}
          strong
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          Info
        </Title>
      ),

      key: "action",
      render: (row) => (
        <Row>
          <Tooltip placement="top" title="Modificar">
            <Button
              shape="circle"
              type="primary"
              size="large"
              icon={<InfoCircleOutlined style={{ color: "white" }} />}
              style={{ margin: 5 }}
              onClick={() => {
                handleOpen(row);
                setEditBundle(row);
              }}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];
  return (
    <Container>
      <Card
        style={{
          borderRadius: 5,
          width: "100%",
          padding: 0,
        }}
      >
        <Table
          style={{
            overflowX: "hidden", // Esto asegura que no desborde el contenedor
            width: "100%",
          }}
          loading={loading}
          locale="es"
          title={() => (
            <TableTitle
              setCreateBundle={setCreateBundle}
              searchTitle={searchTitle}
              setSearchTitle={setSearchTitle}
              searchDescription={searchDescription}
              setSearchDescription={setSearchDescription}
            />
          )}
          dataSource={data?.bundles.results}
          columns={columns}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      {editBundle ? (
        <DetailDrawer
          open={open}
          setOpen={setOpen}
          bundle={editBundle}
          setBundle={setEditBundle}
          refetch={refetch}
        />
      ) : null}
      {createBundle ? (
        <CreateBundle
          open={createBundle}
          setOpen={setCreateBundle}
          products={products?.products?.results}
          refetch={refetch}
        />
      ) : null}
    </Container>
  );
};

export default Bundles;
