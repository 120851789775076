import { gql } from "@apollo/client";

const GET_USERS_ADMINS = gql`
  query users($filters: UserInputFilter) {
    users(filters: $filters) {
      results {
        id
        firstName
        lastName
        email
        phone
        mobile
        img
        client {
          locations {
            id
            odooId
            name
            address {
              fullName
              country
              state
              city
              street
              postcode
              colony
            }
          }
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UpdateUserInput!) {
    updateUser(id: $id, user: $user) {
      id
      firstName
      lastName
      email
      phone
      mobile
      img
      deleted
    }
  }
`;
const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export { GET_USERS_ADMINS, UPDATE_USER, DELETE_USER };
