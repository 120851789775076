import { gql } from "@apollo/client";

const GET_DISCOUNTS = gql`
  query discounts($filters: DiscountInputFilter) {
    discounts(filters: $filters) {
      results {
        id
        title
        company {
          id
          odooId
          name
          img
        }
        programType
        finalDate
        maxUsage
        posOk
        saleOk
        ecommerceOk
        code
        rewardType
        discountAmount {
          amount
          mode
        }
        productLine {
          id
          odooId
          title
          img
        }
      }
    }
  }
`;

export { GET_DISCOUNTS };
