import styled from "styled-components";
import { Card } from "antd";

const Container = styled.div`
  padding: 0px;

  .ant-table-thead .ant-table-cell {
    background-color: white;
    color: #8c8c8c;
    font-weight: 600;
  }
`;

const FranchiseCard = styled(Card)`
  width: 23vw;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const Icon = styled.div`
  height: 60px;
  width: 60px;
  background-color: #71b530;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Indicator = styled(Card)`
  height: 110px;
  width: 18vw;
  border-radius: 15px;
`;

const RowInfo = styled.div`
  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
  }
  a {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #8c8c8c;
    margin: 0px;
  }
`;

export { Container, FranchiseCard, Icon, Indicator, RowInfo };
