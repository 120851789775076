import styled, { keyframes } from "styled-components";
import { Card } from "antd";

// Contenedor principal
const Container = styled.div`
  padding: 0px;
`;

// Contenedor de indicadores
const IndicatorContainer = styled.div`
  padding-bottom: 10px;

  @media (max-width: 1373px) {
    padding-bottom: 5px;
  }

  @media (max-width: 480px) {
    padding-bottom: 0px;
  }
`;

// Contenedor del gráfico
const GraphContainer = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

// Tarjeta de indicadores
const Indicator = styled(Card)`
  min-height: 120px; /* Ajusta la altura mínima */
  width: 32%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Asegura que el contenido se distribuya correctamente */
  padding: 10px; /* Agrega espacio interno */
  overflow: hidden;

  @media (max-width: 1373px) {
    width: 49%;
    height: 100px;
    margin-bottom: 15px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 90px;
    margin-bottom: 10px;
  }
`;

// Icono dentro de las tarjetas
const Icon = styled.div`
  height: 40px;
  width: 40px;
  background-color: #71b530;
  border-radius: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
// Tarjeta del gráfico de línea
const LineCard = styled(Card)`
  height: 67vh;
  border-radius: 15px;

  @media (max-width: 768px) {
    height: 60vh;
  }

  @media (max-width: 480px) {
    height: 50vh;
  }
`;

// Área para un spinner de carga
const SpinArea = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: 250px;
  }

  @media (max-width: 480px) {
    height: 200px;
  }
`;

// Tarjeta de ranking de clientes
const RankingCard = styled(Card)`
  height: 480px;
  margin-left: 20px;
  border-radius: 15px;

  @media (max-width: 768px) {
    height: 45vh;
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    height: auto;
    margin-left: 0;
    margin-top: 20px;
  }
`;

// Animación para gradiente
const HeaderKeyFrame = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

// Tarjeta con gradiente animado
const Gradient = styled(Card)`
  background: linear-gradient(to top right, #1690ff, #2ebfa5, #3083dc);
  animation: ${HeaderKeyFrame} 8s ease infinite;
  background-size: 200% 100%;
  padding: 10px;
  margin-left: 20px;
  margin-top: 20px;
  height: 28vh;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-top: 15px;
    height: 25vh;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    margin-top: 20px;
    height: auto;
    padding: 15px;
  }
`;

// Contenido dentro de la tarjeta de información
const InformationCard = styled.div`
  span {
    color: #8c8c8c;
    font-weight: 600;
  }
  h5 {
    margin: 0px 0px 15px !important;
    font-size: 20px;

    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`;

// Párrafo en blanco
const WhiteParagraph = styled.p`
  margin-top: 5px;
  color: white;
  font-weight: 600;
  font-size: 17px;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

// Enlace en la parte inferior
const LinkRight = styled.a`
  color: #1890ff;
  display: block;
  cursor: pointer;
  font-weight: 600;

  .anticon-right {
    color: #40a9ff;
    margin-left: 2px;
  }
`;

// Pie de la tarjeta de información
const InformationFooter = styled.div`
  margin-top: 5px;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

export {
  Container,
  IndicatorContainer,
  Indicator,
  Icon,
  LineCard,
  SpinArea,
  GraphContainer,
  Gradient,
  RankingCard,
  InformationCard,
  WhiteParagraph,
  InformationFooter,
  LinkRight,
};
