import { gql } from "@apollo/client";

const GET_ORDERS_INDICATORS = gql`
  query ordersIndicators($startDate: Date, $endDate: Date) {
    ordersIndicators(startDate: $startDate, endDate: $endDate) {
      priceTotal {
        amount
        currency
        exponent
      }
      ordersCount
      averageTicket {
        amount
        currency
        exponent
      }
    }
  }
`;

const GET_ORDERS_DAYS_OF_CURRENT_WEEK = gql`
  query ordersByDaysOfTheCurrentWeek {
    ordersByDaysOfTheCurrentWeek {
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
    }
  }
`;

const GET_ORDERS_DAYS_OF_DATE_RANGE = gql`
  query ordersByDaysOfTheDateRange($dateStart: String, $dateEnd: String) {
    ordersByDaysOfTheDateRange(dateStart: $dateStart, dateEnd: $dateEnd) {
      day
      count
      createdAt
    }
  }
`;

const GET_TOP_CLIENTS = gql`
  query topClients($params: QueryParams!) {
    topClients(params: $params) {
      results {
        id
        odooId
        user {
          id
          firstName
          lastName
          email
          phone
          mobile
        }
        orders {
          id
          payment {
            status
            total {
              amount
              exponent
              currency
            }
          }
        }
      }
    }
  }
`;

export {
  GET_ORDERS_INDICATORS,
  GET_ORDERS_DAYS_OF_CURRENT_WEEK,
  GET_ORDERS_DAYS_OF_DATE_RANGE,
  GET_TOP_CLIENTS,
};
