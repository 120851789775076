import styled from "styled-components";
import { Card } from "antd";

const Container = styled.div`
  padding: 0px;
  overflow-y: auto;
  height: 100%;
`;

const BannerCard = styled(Card)`
  cursor: pointer;
`;

export { Container, BannerCard };
