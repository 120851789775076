import { gql } from "@apollo/client";

const UPDATE_DISCOUNT = gql`
  mutation updateDiscount($id: ID!, $discount: DiscountUpdateInput!) {
    updateDiscount(id: $id, discount: $discount) {
      title
    }
  }
`;

export { UPDATE_DISCOUNT };
