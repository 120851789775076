import { gql } from "@apollo/client";

const GET_COLLABORATIONS = gql`
  query collaborations($filters: CollaborationInputFilter) {
    collaborations(filters: $filters) {
      results {
        id
        name
        img
        thumbnail
        active
        description
        type
        spotifyUrl
        colors {
          primary
          secondary
        }
        products {
          id
          title
          img
          sku
          price {
            amount
            exponent
            currency
          }
        }
      }
    }
  }
`;

const UPDATE_COLLABORATION = gql`
  mutation updateCollaboration(
    $id: ID!
    $collaboration: CollaborationUpdateInput!
  ) {
    updateCollaboration(id: $id, collaboration: $collaboration) {
      id
    }
  }
`;

const DELETE_COLLABORATION = gql`
  mutation deleteCollaboration($id: ID!) {
    deleteCollaboration(id: $id) {
      id
    }
  }
`;

export { GET_COLLABORATIONS, UPDATE_COLLABORATION, DELETE_COLLABORATION };
