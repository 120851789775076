import { useGraphqlUpload } from "use-upload";
import { GET_SIGNED_URL } from "./requests"; // Tu consulta GraphQL
import client from "graph-ql"; // Tu cliente de Apollo GraphQL

const useUpload = () => {
  // Inicializamos el hook de carga de archivo con GraphQL
  const { upload: originalUpload, ...rest } = useGraphqlUpload(GET_SIGNED_URL, {
    apolloClient: client,
  });

  const upload = async (file, filePath) => {
    try {
      // Realizamos la solicitud GraphQL para obtener la URL firmada
      const result = await originalUpload(file, {
        variables: { filePath, fileType: file.type },
        fetchPolicy: "network-only",
      });
      // Aquí es donde extraemos la URL firmada
      const signedUrl = result?.data?.signFileUrl?.signedUrl;
      const fileUrl = result?.data?.signFileUrl?.fileUrl;

      // Verificamos si la URL firmada está disponible
      if (!signedUrl) {
        //console.error("No se recibió URL firmada. Resultado:", result);
        return result;
      }
      const response = await fetch(signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
          "x-amz-acl": "public-read", // Asegúrate de que este encabezado esté presente para permisos públicos
        },
        body: file, // El archivo que se subirá a S3
      });
      // Validamos que la solicitud PUT fue exitosa
      if (!response.ok) {
        console.error(
          "Error en la solicitud PUT a la URL firmada. Status:",
          response.status
        );
        return;
      }

      return fileUrl;
    } catch (error) {
      console.error("Error durante la solicitud de subida:", error);
      throw error; // Lanza el error para que el frontend pueda manejarlo
    }
  };
  return { upload, ...rest };
};

export default useUpload;
