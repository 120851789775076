import React from "react";
import {
  Modal,
  Typography,
  InputNumber,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import { CREATE_BUNDLE } from "./requests";
import { useMutation } from "@apollo/client";

const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreateBundle = ({ open, setOpen, products, refetch }) => {
  const [form] = Form.useForm();

  const [createBundleMutation] = useMutation(CREATE_BUNDLE);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onClose = () => {
    setOpen(false);
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          bundle: {
            ...values,
          },
        },
      };

      await createBundleMutation(payload);

      message.success("Se creó con exito!");
      refetch;
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Modal
        visible={open}
        onCancel={onClose}
        footer={null}
        title={<Title level={3}>Crear Promoción</Title>}
      >
        <Form
          {...layout}
          form={form}
          name="bundle"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="title"
            label="Titulo"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Título" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Descripción"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Descripción" />
          </Form.Item>
          <Form.Item
            name="img"
            label="Imagen (link)"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="URL de imagen" />
          </Form.Item>
          <Form.Item
            name="status"
            label="Estatus"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Estatus actual de la promoción" allowClear>
              <Option value="active">Activo</Option>
              <Option value="inactive">Inactivo</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="discount"
            label="Descuento"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={0}
              min={0}
              max={99}
              formatter={(value) => `${value}%`}
              parser={(value) => value.replace("%", "")}
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="Fecha"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item
            name="products"
            label="Productos"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              options={products.map((product) => ({
                label: product.title,
                value: product.id,
              }))}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Crear promoción
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateBundle;
