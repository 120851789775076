import React, { createContext, useCallback, useEffect, useState } from "react";
import cookies from "react-cookies";
import jwt from "jsonwebtoken";
import { useLazyQuery } from "@apollo/client";
import { GET_USER } from "./requests";
import { message } from "antd";
import { logout as logoutApp } from "utils/auth";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLogged, setIsLogged] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true); // Nuevo estado

  const [userQuery] = useLazyQuery(GET_USER, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.user) {
        console.log("[getUser] Usuario obtenido:", data.user);
        setUser(data.user);
        setIsLogged(true);
      }
      setIsInitializing(false); // Finaliza la inicialización
    },
    onError: (error) => {
      console.error("[getUser] Error al obtener el usuario:", error);
      message.error("Error al cargar el usuario. Por favor, inicia sesión.");
      logout();
      setIsInitializing(false); // Finaliza la inicialización incluso con error
    },
  });

  const getUser = useCallback(() => {
    const token = cookies.load("token");
    const tokenPayload = token ? jwt.decode(token, {}) : undefined;

    if (tokenPayload) {
      userQuery({
        variables: {
          id: tokenPayload.id,
        },
      });
    } else {
      logout();
      setIsInitializing(false);
    }
  }, [userQuery]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const logout = async () => {
    try {
      const response = await logoutApp();
      if (response.data.success) {
        isLoggedOut();
      }
      message.success("Se ha cerrado sesión.");
    } catch (error) {
      console.error("[logout] Error durante el logout:", error);
      isLoggedOut();
    }
  };

  const isLoggedOut = () => {
    cookies.remove("token");
    setUser(null);
    setIsLogged(false);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        getUser,
        isLogged,
        isInitializing, // Nuevo valor para saber si la inicialización sigue en progreso
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
export default UserProvider;
