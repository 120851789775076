import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Table,
  Button,
  Tooltip,
  Col,
  message,
  Modal,
  Tag,
} from "antd";
import { PlayCircleFilled } from "@ant-design/icons";
import { Container } from "./elements";
import { getReels, deleteReel } from "./requests";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import EditReel from "./edit";
import { Badge } from "antd";

const { Title } = Typography;

const Reels = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0); // Total de registros
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [pageSize, setPageSize] = useState(10); // Tamaño de página
  const [showModal, setShowModal] = useState(false);
  const [editingReel, setEditingReel] = useState(null);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [deletingReelId, setDeletingReelId] = useState(null);

  // Cargar los Reels desde la API con paginación
  const fetchData = async (page = 1, size = 10) => {
    setLoading(true);
    try {
      const result = await getReels({ limit: size, offset: (page - 1) * size });
      console.log("📌 Reels obtenidos:", result.data.reels);

      setData(Array.isArray(result.data.reels) ? result.data.reels : []);
      setTotal(result.data.total || 0); // Total de registros desde el backend
      setCurrentPage(page);
      setPageSize(size);
    } catch (error) {
      console.error("Error fetching reels:", error);
      message.error("Error al cargar los Reels.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Confirmar eliminación
  const handleDelete = async () => {
    if (!deletingReelId) return;
    try {
      await deleteReel(deletingReelId);
      message.success("Reel eliminado con éxito.");
      setConfirmDeleteModalVisible(false);
      fetchData(currentPage, pageSize); // Recargar los datos manteniendo la paginación actual
    } catch (error) {
      message.error("Error al eliminar el Reel.");
    }
  };

  // Columnas para la tabla
  const columns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Descripción",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Snapshot",
      dataIndex: "snapshot",
      key: "snapshotUrl",
      render: (snapshotUrl) => (
        <img
          src={snapshotUrl}
          alt="Snapshot"
          style={{ width: 50, height: 50 }}
        />
      ),
    },
    {
      title: "Video URL",
      dataIndex: "videoUrl",
      key: "videoUrl",
      render: (videoUrl) => (
        <a
          href={videoUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            color: "#08c",
          }}
        >
          <PlayCircleFilled
            style={{ fontSize: "20px", color: "#08c", paddingRight: "4px" }}
          />{" "}
          Ver Video
        </a>
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Badge
          status={status === "active" ? "success" : "error"}
          text={status === "active" ? "Activo" : "Inactivo"}
        />
      ),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag color={type === "thc" ? "green" : "blue"}>
          {type === "thc" ? "THC" : "CBD"}
        </Tag>
      ),
    },
    {
      title: "Productos Asociados",
      dataIndex: "products",
      key: "products",
      render: (products) => (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
          {Array.isArray(products) && products.length > 0 ? (
            products.map((product) => (
              <Tag key={product._id || product.id} color="blue">
                {product.title}
              </Tag>
            ))
          ) : (
            <span style={{ color: "#888" }}>Sin productos</span>
          )}
        </div>
      ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Tooltip title="Editar">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditingReel(record);
                setShowModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeletingReelId(record._id);
                setConfirmDeleteModalVisible(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "10px" }}
      >
        <Col>
          <Title style={{ fontSize: "1.4rem", marginBottom: "0px" }} level={2}>
            Reels
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingReel(null);
              setShowModal(true);
            }}
          >
            Agregar Reel
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            rowKey={(record) => record.id || record._id}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: total,
              onChange: (page, size) => fetchData(page, size),
            }}
          />
        </Col>
      </Row>

      {/* Modal de edición/creación */}
      <EditReel
        showModal={showModal}
        setShowModal={setShowModal}
        editingReel={editingReel}
        refetch={() => fetchData(currentPage, pageSize)}
      />

      {/* Modal de confirmación de eliminación */}
      <Modal
        title="Confirmar eliminación"
        visible={confirmDeleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setConfirmDeleteModalVisible(false)}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <p>¿Está seguro que desea eliminar este Reel?</p>
      </Modal>
    </Container>
  );
};

export default Reels;
