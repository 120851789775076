import React, { useState } from "react";
import {
  Modal,
  Typography,
  InputNumber,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  message,
} from "antd";
import moment from "moment";
import { CREATE_DISCOUNT } from "./requests";
import { useMutation } from "@apollo/client";

const { Title } = Typography;
const { Option } = Select;

moment.locale("es");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const CreateDiscount = ({ showModal, setShowModal, refetch }) => {
  const [form] = Form.useForm();

  const [createDiscountMutation] = useMutation(CREATE_DISCOUNT);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const [typeOfDiscount, setTypeOfDiscount] = useState("");
  const [typeOfValue, setTypeOfValue] = useState("porcentaje");
  console.log(typeOfDiscount, typeOfValue);

  const onClose = () => {
    setShowModal(false);
  };

  const refactorValues = (values) => {
    const newValues = {
      title: values.title,
      type: values.type,
      initialDate: values.initialDate,
      finalDate: values.finalDate,
      amountOfOrder: {
        type: values.typeOfValue,
        value: values.value,
      },
    };
    return newValues;
  };

  const onFinish = async (values) => {
    const newValues = refactorValues(values);
    try {
      const payload = {
        variables: {
          discount: {
            ...newValues,
          },
        },
      };
      await createDiscountMutation(payload);

      await refetch();
      message.success("Se creó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        ShowModal
        onCancel={onClose}
        footer={null}
        title={<Title level={3}>Crear Descuento</Title>}
      >
        <Form
          {...layout}
          form={form}
          name="discount"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item
            name="title"
            label="Código"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Ej. CODIGO123" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Tipo de descuento"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Tipo de descuento"
              onChange={(value) => {
                setTypeOfDiscount(value);
              }}
            >
              <Option value="amountOfOrder">Amount off order</Option>
              <Option value="amountOfProduct">Amount off product</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="typeOfValue"
            label="Tipo"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => {
                setTypeOfValue(e.target.value);
              }}
              // defaultValue={typeOfValue}
              // value={typeOfValue}
            >
              <Radio value={"porcentaje"}>Porcentaje</Radio>
              <Radio value={"valorFijo"}>Valor fijo</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="value"
            label={typeOfValue === "porcentaje" ? "Porcentaje" : "Valor"}
            rules={[
              {
                required: true,
              },
            ]}
          >
            {typeOfValue === "porcentaje" ? (
              <InputNumber
                style={{ width: "100%" }}
                defaultValue={0}
                min={0}
                max={100}
                addonAfter="%"
              />
            ) : (
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                max={999999}
                defaultValue={0}
                addonBefore="$"
              />
            )}
          </Form.Item>

          <Form.Item
            name="initialDate"
            label="Fecha de inicio"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item
            name="finalDate"
            label="Fecha final"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker disabledDate={disabledDate} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Crear descuento
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateDiscount;
