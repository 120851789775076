import { gql } from "@apollo/client";

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $id: ID!
    $notification: NotificationUpdateInput!
  ) {
    updateNotification(id: $id, notification: $notification) {
      id
    }
  }
`;

export { UPDATE_NOTIFICATION };
