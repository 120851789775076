import { gql } from "@apollo/client";

const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $order: OrderUpdate!) {
    updateOrder(id: $id, order: $order) {
      id
    }
  }
`;

export { UPDATE_ORDER };
