import React from "react";
import { Typography } from "antd";

const TitleSubtitle = ({ title, subtitle }) => {
  return (
    <div style={{ margin: "0px 0px 10px 0px" }}>
      <Typography.Text style={{ color: "grey" }}> {title} </Typography.Text>
      <br />
      <Typography.Text strong style={{ fontSize: "20px" }}>
        {subtitle ?? "NA"}
      </Typography.Text>
    </div>
  );
};

export default TitleSubtitle;
