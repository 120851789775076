import { gql } from "@apollo/client";

const CREATE_DISCOUNT = gql`
  mutation createDiscount($discount: DiscountCreateInput!) {
    createDiscount(discount: $discount) {
      title
    }
  }
`;

export { CREATE_DISCOUNT };
