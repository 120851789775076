import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Row, Card, Button, Tooltip, Col, Modal } from "antd";
import { Container, BannerCard } from "./elements";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_WELLNESS,
  DELETE_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_WELLNESS,
} from "./requests";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import EditBanner from "./edit-category/index";
import CreateCategory from "./create-category";

const { Title, Text } = Typography;
const { Meta } = Card;

const ProductCategories = () => {
  const { categoryType } = useParams();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null);

  const [deleteProductCategory] = useMutation(
    categoryType === "smoke"
      ? DELETE_PRODUCT_CATEGORIES
      : DELETE_PRODUCT_WELLNESS
  );
  const { data: productCategories, refetch } = useQuery(
    categoryType === "smoke" ? GET_PRODUCT_CATEGORIES : GET_PRODUCT_WELLNESS
  );

  const finalCats =
    categoryType === "smoke"
      ? productCategories?.productCategories?.results
      : productCategories?.productWellnesses?.results;

  return (
    <Container>
      {/* Título y Botón */}
      <Row
        style={{
          marginBottom: "20px",
          alignItems: "center",
        }}
        gutter={[16, 16]} // Espaciado entre columnas
      >
        <Col xs={24} sm={18}>
          <Text level={2} style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
            {categoryType === "smoke" ? "Smoke" : "Bienestar"}
          </Text>
        </Col>
        <Col xs={24} sm={6}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{
              width: "100%",
              fontWeight: "bold",
              borderRadius: 8,
            }}
            onClick={() => setShowCreateModal(!showCreateModal)}
          >
            Agregar Etiqueta
          </Button>
        </Col>
      </Row>

      {/* Contenido de Categorías */}
      <Row
        gutter={[24, 24]} // Espaciado entre tarjetas
        style={{
          padding: "10px 0",
        }}
      >
        {finalCats?.map(({ id, image, name, description, priority }) => (
          <Col
            key={id}
            xs={24} // Una tarjeta por fila en pantallas extra pequeñas
            sm={12} // Dos tarjetas por fila en pantallas pequeñas
            md={8} // Tres tarjetas por fila en pantallas medianas
            lg={6} // Cuatro tarjetas por fila en pantallas grandes
          >
            <BannerCard
              style={{
                borderRadius: 5,
                overflow: "hidden",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              cover={
                <img
                  src={image}
                  alt="No disponible"
                  style={{
                    height: "180px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              }
            >
              <Meta
                title={
                  <Title level={5} style={{ fontWeight: "bold" }}>
                    {name}
                  </Title>
                }
                description={
                  <span
                    style={{
                      fontSize: "0.9rem",
                      lineHeight: "1.2rem",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {description}
                  </span>
                }
              />
              {/* Acciones */}
              <Row
                gutter={[8, 8]}
                justify="center"
                style={{
                  marginTop: "10px",
                  borderTop: "1px solid #f0f0f0",
                  paddingTop: "10px",
                }}
              >
                <Tooltip title="Editar">
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    style={{
                      borderRadius: 8,
                      fontWeight: "bold",
                      width: "80px",
                    }}
                    onClick={() => {
                      setShowEditModal(true);
                      setEditingCategory({
                        id,
                        image,
                        name,
                        description,
                        priority,
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Borrar">
                  <Button
                    type="danger"
                    icon={<DeleteOutlined />}
                    style={{
                      borderRadius: 8,
                      fontWeight: "bold",
                      width: "80px",
                    }}
                    onClick={() =>
                      Modal.confirm({
                        title: "Eliminar",
                        content: "¿Estás seguro de eliminar esta etiqueta?",
                        onOk: async () => {
                          await deleteProductCategory({
                            variables: { id },
                          });
                          refetch();
                        },
                      })
                    }
                  />
                </Tooltip>
              </Row>
            </BannerCard>
          </Col>
        ))}
      </Row>

      {/* Modales */}
      <CreateCategory
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        editingCategory={editingCategory}
        setEditingCategory={setEditingCategory}
        refetch={refetch}
        categoryType={categoryType}
      />
      <EditBanner
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        editingCategory={editingCategory}
        setEditingCategory={setEditingCategory}
        refetch={refetch}
        categoryType={categoryType}
      />
    </Container>
  );
};

export default ProductCategories;
