import React from "react";
import PropTypes from "prop-types";
import { Typography, Button, Row, Col, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TitleContainer } from "./elements";

const { Text, Title } = Typography;

const TableTitle = ({
  setCreateBundle,
  searchTitle,
  setSearchTitle,
  searchDescription,
  setSearchDescription,
}) => {
  return (
    <TitleContainer>
      <Row
        gutter={[16, 16]} // Espaciado entre columnas
        style={{
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>Bundles</Text>
        </Col>

        {/* Filtros y Botón */}
        <Col xs={24}>
          <Row gutter={[16, 16]} align="middle">
            {/* Filtro por Nombre */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Filtrar por Nombre
              </Title>
              <Input
                placeholder="Buscar por Nombre"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>

            {/* Filtro por Descripción */}
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Filtrar por Descripción
              </Title>
              <Input
                placeholder="Buscar por Tipo de Promoción"
                value={searchDescription}
                onChange={(e) => setSearchDescription(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>

            {/* Botón Crear Bundle */}
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={{ span: 6, offset: 6 }}
              style={{ textAlign: "right" }}
            >
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                style={{
                  width: "100%",
                  fontWeight: "bold",
                  borderRadius: 8,
                }}
                onClick={() => {
                  setCreateBundle(true);
                }}
              >
                Crear Bundle
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleContainer>
  );
};

TableTitle.defaultProps = {
  search: "",
};

TableTitle.propTypes = {
  search: PropTypes.string,
};

export default TableTitle;
