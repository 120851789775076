/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Descriptions,
  Row,
  Col,
  Button,
  Collapse,
  Image,
  Avatar,
  Spin,
  Card,
  Tooltip,
  message,
  Select,
  Timeline,
} from "antd";
import {
  UserOutlined,
  CaretRightOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { UPDATE_ORDER } from "./requests";
import { GET_TRACK_ORDER } from "./requests";
import TitleSubtitle from "utils/components/title-subtitle";
import moment from "moment";
import {
  orderStatus,
  trackingType,
  uberParadiseStatusMap,
  uberStatusMap,
} from "utils/constants";

const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const TrackOrder = ({
  showModal,
  setShowModal,
  trackingOrder,
  setTrackingOrder,
  currentStatus,
  setCurrentStatus,
  refetch,
}) => {
  const [editing, setEditing] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [isMounted, setIsMounted] = useState(true);
  const [updateOrder] = useMutation(UPDATE_ORDER);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const { data: trackOrderData, loading } = useQuery(GET_TRACK_ORDER, {
    variables: { order: trackingOrder },
    skip: !trackingOrder,
    errorPolicy: "all",
  });

  const type = trackOrderData?.trackOrder?.type || null;
  const express = trackOrderData?.trackOrder?.express || {};
  const standard = trackOrderData?.trackOrder?.standard || {};
  const trackingData = standard?.trackingData || [];

  const handleCancel = () => {
    if (!isMounted) return;
    setShowModal(false);
    setTrackingOrder(null);
    setEditing(false);
    setNewStatus(null);
    setCurrentStatus(null);
  };

  const changeStatus = async () => {
    try {
      await updateOrder({
        variables: {
          id: trackingOrder,
          order: { status: newStatus },
        },
      });
      if (isMounted) {
        await refetch();
        message.success("Orden Actualizada!");
        handleCancel();
      }
    } catch (err) {
      console.error(err);
      if (isMounted) message.error("Error al actualizar");
    }
  };

  return (
    <Modal
      title={<Title level={3}>Detalles de la Orden</Title>}
      visible={showModal}
      onCancel={handleCancel}
      width={"80%"}
      footer={[
        <Button key="close" type="primary" onClick={handleCancel}>
          Cerrar
        </Button>,
      ]}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : type === trackingType.express.value ? (
        <>
          <Row gutter={16}>
            <Col span={14}>
              <Row>
                <Col span={12}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {!editing ? (
                      <>
                        <TitleSubtitle
                          title="Estatus Uber"
                          subtitle={
                            uberStatusMap[express?.status]?.name || "N/A"
                          }
                        />
                        <Tooltip title={"Editar"}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<EditOutlined />}
                            style={{ marginLeft: 10 }}
                            onClick={() => setEditing(true)}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Select
                          defaultValue={
                            orderStatus[currentStatus]?.name || "N/A"
                          }
                          onChange={(value) => setNewStatus(value)}
                          style={{ width: 200 }}
                        >
                          {Object.values(uberParadiseStatusMap).map(
                            (status) => (
                              <Option key={status.value} value={status.value}>
                                {status.name}
                              </Option>
                            )
                          )}
                        </Select>
                        <Tooltip title={"Guardar"}>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<SaveOutlined />}
                            onClick={changeStatus}
                          />
                        </Tooltip>
                        <Tooltip title={"Cancelar"}>
                          <Button
                            type="default"
                            shape="circle"
                            icon={<CloseOutlined />}
                            onClick={() => setEditing(false)}
                          />
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <TitleSubtitle
                    title="Se entrega con"
                    subtitle={trackingType[type]?.name || "N/A"}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              span={10}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {express?.tracking_url && (
                <Button type="primary">
                  <a
                    href={express.tracking_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver más detalles
                  </a>
                </Button>
              )}
            </Col>
          </Row>

          <Collapse>
            <Panel header="Detalles de Recolección" key="1">
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Nombre">
                  {express?.pickup?.name || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono">
                  {express?.pickup?.phone_number || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Dirección">
                  {express?.pickup?.address || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
            <Panel header="Detalles de Entrega" key="2">
              <Descriptions bordered column={2}>
                <Descriptions.Item label="Nombre">
                  {express?.dropoff?.name || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Teléfono">
                  {express?.dropoff?.phone_number || "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Dirección">
                  {express?.dropoff?.address || "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </Panel>
          </Collapse>

          {express?.courier && (
            <Collapse>
              <Panel header="Detalles del Conductor" key="3">
                <Row gutter={16}>
                  <Col span={8}>
                    {express.courier.img_href ? (
                      <Image src={express.courier.img_href} alt="" />
                    ) : (
                      <Avatar size={110} icon={<UserOutlined />} />
                    )}
                  </Col>
                  <Col span={16}>
                    <Descriptions bordered column={1}>
                      <Descriptions.Item label="Nombre">
                        {express.courier.name || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Teléfono">
                        {express.courier.phone_number || "N/A"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Vehículo">
                        {express.courier.vehicle_type || "N/A"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          )}
        </>
      ) : type === trackingType.standard.value ? (
        <>
          <Card>
            <Title level={5}>Estatus Teiker</Title>
            <Typography.Text>{standard?.status || "N/A"}</Typography.Text>
          </Card>
          <Card>
            <Title level={5}>Clave de rastreo</Title>
            <Typography.Text copyable>{standard?.id || "N/A"}</Typography.Text>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button type="primary">
                <a
                  href="https://envios.teiker.mx/Rastreo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver más detalles en Teiker
                </a>
              </Button>
            </div>
          </Card>
          <Card>
            <Timeline>
              {trackingData.map((item, index) => (
                <Timeline.Item key={index}>
                  <Typography.Text>
                    {moment(item.fecha).format("DD/MM/YYYY HH:mm")}
                  </Typography.Text>
                  <br />
                  <Typography.Text>{item.descripcion || "N/A"}</Typography.Text>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        </>
      ) : (
        <Typography.Text>No hay datos de rastreo disponibles.</Typography.Text>
      )}
    </Modal>
  );
};

export default TrackOrder;
