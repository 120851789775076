import { gql } from "@apollo/client";

const GET_SYNC_OPERATIONS = gql`
  query syncOperations($params: QueryParams) {
    syncOperations(params: $params) {
      info {
        count
        prev
        next
        pages
      }
      results {
        name
        status
        createdAt
      }
    }
  }
`;

export { GET_SYNC_OPERATIONS };
