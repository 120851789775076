import React from "react";
import { Drawer, Typography, Table } from "antd";
import { formatCurrency } from "utils/functions";

const { Title, Text } = Typography;

const ViewProducts = ({ showModal, setShowModal, products }) => {
  const onClose = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      render: (text, record) => (
        <img
          src={text}
          alt={record.title}
          style={{ maxWidth: "80px", maxHeight: "80px" }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (text) => <Text>{formatCurrency(text)}</Text>,
    },
  ];

  return (
    <>
      <Drawer
        visible={showModal}
        onClose={onClose}
        title={<Title level={3}>Productos</Title>}
        width={"60%"}
      >
        <Table columns={columns} dataSource={products} rowKey="id" />
      </Drawer>
    </>
  );
};

export default ViewProducts;
