import { gql } from "@apollo/client";

const GET_BUNDLES = gql`
  query bundles($params: QueryParams, $filters: BundleInputFilter) {
    bundles(params: $params, filters: $filters) {
      results {
        id
        odooId
        title
        description
        img
        products {
          product {
            title
            sku
            img
            description
          }
          quantity
        }
        active
      }
    }
  }
`;

const GET_PRODUCTS = gql`
  query products($params: QueryParams) {
    products(params: $params) {
      results {
        id
        odooId
        title
        img
        variant
        variants {
          title
          sku
          img
          description
        }
        description
        price {
          amount
          exponent
          currency
        }
        category
        sku
      }
    }
  }
`;

export { GET_BUNDLES, GET_PRODUCTS };
