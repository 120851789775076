import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  message,
  InputNumber,
  Upload,
  Spin,
} from "antd";
import { CREATE_PRODUCT_CATEGORY, CREATE_PRODUCT_WELLNESS } from "./requests";
import { useMutation } from "@apollo/client";
import useUpload from "hooks/use-upload";
import { UploadOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const CreateCategory = ({
  showModal,
  setShowModal,
  editingCategory,
  setEditingCategory,
  refetch,
  categoryType,
}) => {
  const { upload } = useUpload();
  const [uploadLoding, setUploadLoading] = useState(false);
  const [form] = Form.useForm();

  const [createProductCategoryMutation] = useMutation(CREATE_PRODUCT_CATEGORY);
  const [createProductWellnessMutation] = useMutation(CREATE_PRODUCT_WELLNESS);

  useEffect(() => {
    if (showModal) {
      form.resetFields();
    }
  }, [showModal, form]);

  const onClose = () => {
    setShowModal(false);
    setEditingCategory(null);
  };

  const handleUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      const fileName = `wellness/images/${new Date().toISOString()}/${
        fileList[0]?.name
      }`;
      const url = await upload(fileList[0].originFileObj, fileName);
      form.setFieldsValue({ image: url });
    }
    setUploadLoading(false);
    message.success("Imagen Cargada.");
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          category: {
            name: values?.name,
            description: values.description,
            image: values.image,
            priority: values?.priority,
          },
        },
      };
      categoryType === "smoke"
        ? await createProductCategoryMutation(payload)
        : await createProductWellnessMutation(payload);
      await refetch();
      message.success("Se editó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al editar");
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Drawer
        open={showModal}
        onClose={onClose}
        title={
          <Title level={3}>
            {categoryType === "smoke"
              ? "Generar Etiqueta Smoke"
              : "Generar Etiqueta Bienestar"}
          </Title>
        }
        width={"35%"}
      >
        <Form
          form={form}
          name="banner"
          onFinish={onFinish}
          style={{
            maxWidth: 600,
          }}
        >
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Nombre
            </Text>
            <Form.Item name="name">
              <Input placeholder="Nombre" />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Descripcion
            </Text>
            <Form.Item name="description">
              <Input
                defaultValue={editingCategory?.description}
                placeholder="Descripción"
              />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Imagen
            </Text>
            <Upload
              beforeUpload={() => false}
              listType="picture"
              maxCount={1}
              accept="image/*"
              onChange={(info) => {
                handleUpload(info);
              }}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Cargar Imagen</Button>
              {uploadLoding ? <Spin style={{ margin: 10 }} /> : null}
            </Upload>
            <Form.Item name="image">
              <Input
                defaultValue={editingCategory?.image}
                placeholder="URL imagen"
              />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Prioridad
            </Text>
            <Form.Item name="priority">
              <InputNumber
                min={0}
                max={30}
                defaultValue={editingCategory?.priority}
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Crear
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateCategory;
