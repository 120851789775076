import React, { useState } from "react";
import { Modal, Upload, message, Spin } from "antd";
import { ExcelButton, LoadingContainer, ModalContainer } from "./elements";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { utils, writeFile, read } from "xlsx";
import { useMutation } from "@apollo/client";
import { UPDATE_FRANCHISE } from "../../requests";

const { Dragger } = Upload;

const PhonesEditModal = ({ open, setOpen, data, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(undefined);
  const [updateFranchise] = useMutation(UPDATE_FRANCHISE);

  const beforeUpload = (file) => {
    const isXlsx =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXlsx) {
      message.error(`${file.name} no es un archivo .xlsx`);
    }
    return isXlsx || Upload.LIST_IGNORE;
  };

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      compareAndUpdate(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const compareAndUpdate = async (excelData) => {
    const updatePromises = excelData.slice(1).map(async (row) => {
      const [odooId, , newPhone] = row;
      const existingData = data.find((item) => item.odooId === odooId);

      if (newPhone === undefined || newPhone === existingData?.["phone"]) {
        return;
      }

      const payload = {
        variables: {
          id: existingData?.["id"],
          franchise: {
            phone: newPhone,
          },
        },
      };

      return updateFranchise(payload);
    });

    await Promise.all(updatePromises);
    message.success("Completado!");
    refetch();
    setOpen(false);
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload,
    customRequest: ({ file, onSuccess }) => {
      setLoading(true);
      setLoadingMessage("Actualizando franquicias");
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
      handleFile(file);
    },
  };

  const downloadData = () => {
    setLoading(true);
    setLoadingMessage("Descargando datos");

    let headers = [["odooId", "Nombre", "Telefono"]];

    const franchiseArray = data?.map((franchise) => {
      return {
        odooId: franchise?.["odooId"],
        Nombre: franchise?.["name"],
        Telefono: franchise?.["phone"],
      };
    });

    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headers);
    utils.sheet_add_json(ws, franchiseArray, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Sheet1");
    writeFile(wb, "franquicias.xlsx");
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        setOpen(false);
      }}
      footer={null}
    >
      {loading ? (
        <LoadingContainer>
          <Spin />
          <h3 style={{ color: "green" }}>{loadingMessage}</h3>
        </LoadingContainer>
      ) : (
        <ModalContainer>
          <Dragger {...props} style={{ width: "163%" }}>
            <div style={{ width: "100%" }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "green" }} />
              </p>
              <p className="ant-upload-text">SUBIR ARCHIVO</p>
              <p className="ant-upload-text">
                Click or arrastrar el archivo aquí
              </p>
            </div>
          </Dragger>
          <ExcelButton
            icon={<DownloadOutlined />}
            size="large"
            onClick={() => downloadData()}
          >
            DESCARGAR ARCHIVO
          </ExcelButton>
        </ModalContainer>
      )}
    </Modal>
  );
};

export default PhonesEditModal;
