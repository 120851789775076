import React, { useEffect } from "react";
import { Drawer, Form, Input, Button, Typography, message } from "antd";
import { useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "./requests";

const { Text } = Typography;

const NewMarketing = ({
  showModal,
  setShowModal,
  refetch,
  editingMarketing,
  setEditingMarketing, // Se necesita para limpiar la edición
}) => {
  const [form] = Form.useForm();
  const [createUserMutation] = useMutation(CREATE_USER);
  const [updateUserMutation] = useMutation(UPDATE_USER);

  useEffect(() => {
    if (!showModal) {
      form.resetFields(); // Limpia cuando se cierra el modal
      setEditingMarketing(null); // 🔥 Limpia la edición cuando el modal se cierra
    }
  }, [showModal]);

  useEffect(() => {
    if (editingMarketing && showModal) {
      form.setFieldsValue({ ...editingMarketing, password: "" });
    } else {
      form.resetFields(); // 🔥 Limpia los campos cuando se abre para crear
    }
  }, [editingMarketing, showModal]);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
    setEditingMarketing(null); // 🔥 Asegura que la edición se borre al cerrar
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      let response;

      if (editingMarketing) {
        console.log("✏️ Editando usuario de marketing...");
        const updatedValues = { ...values };

        if (!values.password) delete updatedValues.password;

        const hasChanges = Object.keys(updatedValues).some(
          (key) => updatedValues[key] !== editingMarketing[key]
        );

        if (!hasChanges) {
          message.warning("No hay cambios para actualizar.");
          return;
        }

        console.log("📌 Payload para updateUser:", updatedValues);

        response = await updateUserMutation({
          variables: { id: editingMarketing.id, user: updatedValues },
        });

        console.log("📌 Respuesta de updateUser:", response);

        const updatedUser = response?.data?.updateUser;
        if (!updatedUser?.success) {
          throw new Error(updatedUser?.message || "Error al actualizar.");
        }

        message.success("Usuario actualizado correctamente.");
      } else {
        console.log("➕ Creando nuevo usuario de marketing...");
        const payload = {
          variables: {
            user: { ...values, userType: "marketing" },
          },
        };

        console.log("📌 Payload para createUser:", payload);

        response = await createUserMutation(payload);

        console.log("📌 Respuesta de createUser:", response);

        const createdUser = response?.data?.createUser;
        if (!createdUser?.success) {
          throw new Error(createdUser?.message || "Error al crear usuario.");
        }

        message.success("Usuario creado correctamente.");
      }

      await refetch();
      onClose();
    } catch (error) {
      console.error("❌ GraphQL Error:", error);

      let errorMessage = "Ha ocurrido un error al guardar el usuario.";

      if (error.graphQLErrors?.length) {
        errorMessage = error.graphQLErrors[0].message || errorMessage;
      } else if (error.networkError) {
        errorMessage = "Error de conexión con el servidor.";
      } else {
        errorMessage = error.message || errorMessage;
      }

      message.error(errorMessage);
    }
  };

  return (
    <Drawer
      title={
        editingMarketing
          ? "Editar Usuario de Marketing"
          : "Nuevo Usuario de Marketing"
      }
      visible={showModal}
      onClose={onClose}
      width={"60vw"}
    >
      <Form
        form={form}
        style={{ paddingLeft: 40, paddingRight: 40 }}
        onFinish={onSubmit}
      >
        <Text strong>
          Nombre<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: "Por favor ingrese el nombre" }]}
        >
          <Input size="large" placeholder="Ingrese el nombre" />
        </Form.Item>

        <Text strong>
          Apellido<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: "Por favor ingrese el apellido" }]}
        >
          <Input size="large" placeholder="Ingrese el apellido" />
        </Form.Item>

        <Text strong>
          Correo<Text style={{ color: "red" }}> *</Text>
        </Text>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Por favor ingrese un correo electrónico válido",
            },
          ]}
        >
          <Input size="large" placeholder="Ingrese el correo electrónico" />
        </Form.Item>

        <Text strong>
          Contraseña{" "}
          {editingMarketing ? "(Opcional, solo si desea cambiarla)" : "*"}
        </Text>
        <Form.Item
          name="password"
          rules={
            editingMarketing
              ? []
              : [{ required: true, message: "Por favor ingrese la contraseña" }]
          }
        >
          <Input.Password size="large" placeholder="Ingrese la contraseña" />
        </Form.Item>

        <Text strong>URL de Imagen</Text>
        <Form.Item name="img">
          <Input size="large" placeholder="Ingrese la URL de imagen" />
        </Form.Item>

        <Text strong>Teléfono</Text>
        <Form.Item
          name="phone"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Ingrese solo números",
            },
          ]}
        >
          <Input size="large" placeholder="Ingrese el número de teléfono" />
        </Form.Item>

        <Text strong>Móvil</Text>
        <Form.Item
          name="mobile"
          rules={[
            {
              pattern: /^[0-9]+$/,
              message: "Ingrese solo números",
            },
          ]}
        >
          <Input size="large" placeholder="Ingrese el número de móvil" />
        </Form.Item>

        <Form.Item>
          <Button size="large" block type="primary" htmlType="submit">
            {editingMarketing ? "Actualizar usuario" : "Crear usuario"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewMarketing;
