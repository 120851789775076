import React from "react";
import { Drawer, Form, Input, Button, Typography, message } from "antd";
import { useMutation } from "@apollo/client";
import { CREATE_USER } from "./requests";

const { Text } = Typography;

const NewAdmin = ({ showModal, setShowModal, refetch }) => {
  const [form] = Form.useForm();
  const onClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [createUserMutation] = useMutation(CREATE_USER);

  const onSubmit = async () => {
    const values = await form.validateFields();
    try {
      const payload = {
        variables: {
          user: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            userType: "admin",
            img: values.img || "",
            phone: values.phone || "",
            mobile: values.mobile || "",
          },
        },
      };

      await createUserMutation(payload);
      await refetch();

      message.success("El admin se ha creado correctamente.");
      onClose();
    } catch (error) {
      message.error("Ha ocurrido un error al crear el admin.");
      console.log(error);
    }
  };

  return (
    <Drawer
      title="Llene la Informacion para Crear un Nuevo Admin"
      visible={showModal}
      onClose={onClose}
      width={"60vw"}
    >
      <Form
        form={form}
        style={{ paddingLeft: 40, paddingRight: 40 }}
        onFinish={onSubmit}
      >
        <div>
          <Text strong>
            Nombre
            <Text style={{ color: "red" }}> *</Text>
          </Text>
          <Form.Item
            style={{ marginTop: 10 }}
            name="firstName"
            rules={[
              { required: true, message: "Por favor ingrese el nombre " },
            ]}
          >
            <Input size="large" placeholder="Ingrese el nombre" />
          </Form.Item>
        </div>
        <div>
          <Text strong>
            Apellido
            <Text style={{ color: "red" }}> *</Text>
          </Text>
          <Form.Item
            style={{ marginTop: 10 }}
            name="lastName"
            rules={[
              { required: true, message: "Por favor ingrese el apellido " },
            ]}
          >
            <Input size="large" placeholder="Ingrese el apellido" />
          </Form.Item>
        </div>
        <div>
          <Text strong>
            Correo electronico
            <Text style={{ color: "red" }}> *</Text>
          </Text>
          <Form.Item
            style={{ marginTop: 10 }}
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el correo electronico ",
              },
            ]}
          >
            <Input
              type="email"
              size="large"
              placeholder="Ingrese el correo electronico"
            />
          </Form.Item>
        </div>
        <div>
          <Text strong>
            Contraseña
            <Text style={{ color: "red" }}> *</Text>
          </Text>
          <Form.Item
            style={{ marginTop: 10 }}
            name="password"
            rules={[
              { required: true, message: "Por favor ingrese la contraseña " },
            ]}
          >
            <Input
              type="password"
              size="large"
              placeholder="Ingrese la contraseña"
            />
          </Form.Item>
        </div>
        <div>
          <Text style={{ marginRight: 10 }} strong>
            URL de Imagen
          </Text>
          <Form.Item style={{ marginTop: 10 }} name="img">
            <Input size="large" placeholder="Ingrese el URL de Imagen" />
          </Form.Item>
        </div>
        <div>
          <Text style={{ marginRight: 10 }} strong>
            Telefono
          </Text>
          <Form.Item style={{ marginTop: 10 }} name="phone">
            <Input size="large" placeholder="Ingrese el número de telefono" />
          </Form.Item>
        </div>
        <div>
          <Text style={{ marginRight: 10 }} strong>
            Móvil
          </Text>
          <Form.Item style={{ marginTop: 10 }} name="mobile">
            <Input size="large" placeholder="Ingrese el número de móvil" />
          </Form.Item>
        </div>
        <Form.Item>
          <Button size="large" block type="primary" htmlType="submit">
            Crear Admin
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default NewAdmin;
