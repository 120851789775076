import React, { useState } from "react";
import {
  Typography,
  Drawer,
  Row,
  Select,
  Button,
  Tooltip,
  message,
  DatePicker,
  Divider,
  Radio,
} from "antd";
import { SelectOutlined, ClearOutlined, SendOutlined } from "@ant-design/icons";
import { states } from "utils/constants";
import { useMutation } from "@apollo/client";
import { SEND_NOTIFICATION, UPDATE_NOTIFICATION } from "./requests";
import moment from "moment";

import { Container } from "../elements";

const { Title, Text } = Typography;
const { Option } = Select;

const SendNotification = ({
  showModal,
  setShowModal,
  selectedNotification,
  setSelectedNotification,
  refetch,
}) => {
  const [statesSelected, setStatesSelected] = useState([]);
  const [isNow, setIsNow] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [scheduledDateSelected, setScheduledDateSelected] = useState(null);

  const allStates = Object.keys(states);

  const handleStateChange = (value) => {
    setStatesSelected(value);
  };

  const onClose = () => {
    setShowModal(false);
    setSelectedNotification(null);
    setStatesSelected([]);
    setScheduledDate(null);
    setScheduledDateSelected(null);
  };

  const handleRadioChange = (value) => {
    setIsNow(value);
    if (value) {
      setScheduledDate(moment());
    }
  };

  const handleChangeDate = (date) => {
    setScheduledDateSelected(date);
  };

  const [sendNotificationMutation] = useMutation(SEND_NOTIFICATION);
  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION);
  const sendNotification = async () => {
    try {
      const payloadSend = {
        variables: {
          id: selectedNotification?.id,
          states: statesSelected,
          title: selectedNotification?.title,
          message: selectedNotification?.description,
          scheduledDate: scheduledDateSelected,
        },
      };
      await sendNotificationMutation(payloadSend);

      const payloadUpdate = {
        variables: {
          id: selectedNotification?.id,
          notification: {
            scheduledDate: isNow ? scheduledDate : scheduledDateSelected,
          },
        },
      };
      await updateNotificationMutation(payloadUpdate);

      message.success("la notificación se envió con exito!");
      await refetch();
    } catch (err) {
      console.error(err);
      message.error("Error al enviar");
    } finally {
      onClose();
      setStatesSelected([]);
      setIsNow(false);
      setScheduledDate(null);
      setScheduledDateSelected(null);
    }
  };

  return (
    <Container>
      <Drawer
        open={showModal}
        ShowModal
        onClose={onClose}
        title={<Title level={3}>{selectedNotification?.title}</Title>}
        width={"60%"}
      >
        <Title level={5}>Clientes a los que se enviará la notificación</Title>
        <Row style={{ marginTop: 20 }}>
          <Title level={5}>Estados</Title>
          <Select
            mode="multiple"
            size="large"
            placeholder="Selecciona estados"
            onChange={handleStateChange}
            maxTagCount={4}
            style={{ width: "70%", marginLeft: 10, marginRight: 10 }}
            value={statesSelected}
          >
            {Object.keys(states).map((state) => (
              <Option key={state} value={state}>
                {states[state]}
              </Option>
            ))}
          </Select>
          <Tooltip title={"Seleccionar todos"}>
            <Button
              size="large"
              style={{ margin: 5 }}
              shape="circle"
              type="primary"
              icon={<SelectOutlined />}
              onClick={() => setStatesSelected(allStates)}
            />
          </Tooltip>
          <Tooltip title={"Limpiar"}>
            <Button
              size="large"
              style={{ margin: 5 }}
              shape="circle"
              type="primary"
              icon={<ClearOutlined />}
              onClick={() => setStatesSelected([])}
            />
          </Tooltip>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Title style={{ marginRight: 10 }} level={5}>
            ¿La notificación se enviara ahora?
          </Title>
          <Radio.Group
            size="large"
            defaultValue={isNow}
            onChange={(e) => handleRadioChange(e.target.value)}
          >
            <Radio.Button value={true}>Si</Radio.Button>
            <Radio.Button value={false}>No</Radio.Button>
          </Radio.Group>
        </Row>
        {isNow ? (
          <Text strongstyle={{ fontSize: 14 }}>
            Fecha de Envío:{" "}
            <Text style={{ fontSize: 14 }}>
              {moment().format("D MMMM YYYY, HH:mm")}
            </Text>
          </Text>
        ) : (
          <DatePicker
            style={{ marginTop: 20 }}
            size="large"
            format="YYYY-MM-DD HH:mm"
            showTime={{
              defaultValue: moment("00:00", "HH:mm"),
            }}
            onChange={(date) => handleChangeDate(date)}
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
            value={scheduledDateSelected}
          />
        )}
        <Divider />
        <Button
          size="large"
          type="primary"
          icon={<SendOutlined />}
          onClick={sendNotification}
          style={{ marginTop: 20 }}
          disabled={
            !statesSelected.length || (!scheduledDate && !scheduledDateSelected)
          }
        >
          Enviar Notificación
        </Button>
      </Drawer>
    </Container>
  );
};

export default SendNotification;
