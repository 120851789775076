/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Table,
  Card,
  Typography,
  Tag,
  Row,
  Tooltip,
  Button,
  Drawer,
  Col,
  Input,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Container, RowInfo } from "./elements";
import { useQuery } from "@apollo/client";
import { GET_USERS_CLIENTS } from "./requests";

const { Title, Text } = Typography;

const Clients = () => {
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const { data: usersData, loading } = useQuery(GET_USERS_CLIENTS, {
    variables: {
      filters: {
        name: searchName,
        email: searchEmail,
        userType: "client",
      },
    },
  });
  const allClients = usersData?.users?.results;

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);

  const handleOpenDrawer = (address) => {
    setSelectedAddress(address);
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(!open);
  // };

  const columns = [
    {
      align: "left",
      width: 120,
      key: "img",
      render: (row) => {
        return row.img ? (
          <img
            src={row.img}
            style={{ width: 60, height: 60, borderRadius: 50 }}
          />
        ) : null;
      },
    },
    {
      title: "Nombre",
      align: "left",
      key: "user",
      render: (user) => {
        const fullName = user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.firstName;

        return (
          <RowInfo>
            <Title level={5}>{fullName}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Correo",
      align: "left",
      key: "email",
      render: (row) => {
        return (
          <RowInfo>
            <Title level={5}>{row.email}</Title>
          </RowInfo>
        );
      },
    },
    {
      title: "Telefono",
      align: "left",
      key: "phone",
      render: (row) => (
        <RowInfo>
          <Title level={5}>{row.phone ?? row.mobile}</Title>
        </RowInfo>
      ),
    },
    {
      title: "Dirección",
      dataIndex: "client",
      align: "left",
      render: (client) => {
        const addressCount = client?.locations?.length;

        if (addressCount === 1) {
          return (
            <RowInfo>
              <Title level={5}>{client?.locations[0]?.address?.fullName}</Title>
            </RowInfo>
          );
        } else if (addressCount > 1) {
          return (
            <RowInfo>
              <Tooltip title={`Ver direcciones: ${addressCount}`}>
                <Button
                  shape="circle"
                  size="middle"
                  icon={<InfoCircleOutlined style={{ color: "white" }} />}
                  style={{ margin: 0, backgroundColor: "#52B266" }}
                  onClick={() => handleOpenDrawer(client.locations)}
                />
              </Tooltip>
            </RowInfo>
          );
        } else {
          return <Tag color="orange">Sin Direcciones</Tag>;
        }
      },
    },
    // {
    //   title: () => (
    //     <Title
    //       level={4}
    //       strong
    //       style={{ marginTop: "auto", marginBottom: "auto" }}
    //     >
    //       Acciones
    //     </Title>
    //   ),

    //   key: "action",
    //   render: (row) => (
    //     <Row>
    //       <Tooltip placement="top" title="Ver chatbots">
    //         <Button
    //           shape="circle"
    //           type="primary"
    //           size="large"
    //           icon={<InfoCircleOutlined style={{ color: "white" }} />}
    //           style={{ margin: 5 }}
    //           onClick={() => handleOpen(row)}
    //         />
    //       </Tooltip>
    //     </Row>
    //   ),
    // },
  ];

  return (
    <Container>
      <Card
        style={{
          borderRadius: 5,
        }}
      >
        <Text style={{ fontSize: 20, fontWeight: "800" }}>Clientes</Text>
        <Row>
          <Col>
            <Text strong level={5}>
              Filtar por Nombre
            </Text>
            <Input
              style={{ marginRight: 10 }}
              placeholder="Buscar por Nombre"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              allowClear
            />
          </Col>
          <Col>
            <Text strong level={5}>
              Filtar por Correo
            </Text>
            <Input
              style={{ marginLeft: 10 }}
              placeholder="Buscar por Correo"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              allowClear
            />
          </Col>
        </Row>
        <Table
          style={{
            overflow: "hidden", // Esto asegura que no desborde el contenedor
            width: "100%",
          }}
          loading={loading}
          locale="es"
          dataSource={allClients}
          columns={columns}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Card>
      <Drawer
        title="Direcciones"
        placement="right"
        onClose={handleCloseDrawer}
        visible={drawerVisible}
      >
        <Table
          style={{
            overflow: "hidden", // Esto asegura que no desborde el contenedor
            width: "100%",
          }}
          dataSource={selectedAddress}
          columns={[
            {
              title: "Dirección",
              dataIndex: "address",
              key: "address",
              render: (text) => {
                return (
                  <RowInfo>
                    <Title level={5}>{text.fullName}</Title>
                  </RowInfo>
                );
              },
            },
          ]}
          rowKey="id"
          scroll={{ x: 800, y: 500 }}
        />
      </Drawer>
    </Container>
  );
};

export default Clients;
