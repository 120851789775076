import { gql } from "@apollo/client";

const SEND_NOTIFICATION = gql`
  mutation sendNotification(
    $id: ID!
    $states: [String]!
    $title: String!
    $message: String!
    $scheduledDate: String
  ) {
    sendNotification(
      id: $id
      states: $states
      title: $title
      message: $message
      scheduledDate: $scheduledDate
    ) {
      id
    }
  }
`;

const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $id: ID!
    $notification: NotificationUpdateInput!
  ) {
    updateNotification(id: $id, notification: $notification) {
      id
    }
  }
`;

export { SEND_NOTIFICATION, UPDATE_NOTIFICATION };
