import { gql } from "@apollo/client";

const GET_BANNERS = gql`
  query banners($filters: BannerInputFilter) {
    banners(filters: $filters) {
      results {
        id
        title
        type
        img
        active
      }
    }
  }
`;

const UPDATE_BANNER = gql`
  mutation updateBanner($id: ID!, $banner: BannerUpdateInput!) {
    updateBanner(id: $id, banner: $banner) {
      id
    }
  }
`;

const DELETE_BANNER = gql`
  mutation deleteBanner($id: ID!) {
    deleteBanner(id: $id) {
      id
    }
  }
`;

export { GET_BANNERS, UPDATE_BANNER, DELETE_BANNER };
