import axios from "axios";

const API_URL = "https://api.valida-curp.com.mx/curp/obtener_datos/";
const TOKEN = process.env.REACT_APP_CURP_API_TK; // Token en .env

export const fetchCurpData = async (curp) => {
  try {
    const response = await axios.get(`${API_URL}?token=${TOKEN}&curp=${curp}`);
    const data = response.data;

    if (data.error) {
      return { status: "error", message: data.error_message, data: null };
    }

    const solicitante = data.response.Solicitante;

    return {
      status: "success",
      message: "CURP validado correctamente",
      data: {
        firstName: solicitante.Nombres || "",
        lastName: solicitante.ApellidoPaterno || "",
        middleName: solicitante.ApellidoMaterno || "",
        curp: solicitante.CURP || "",
        age: calcularEdad(solicitante.FechaNacimiento),
        city: solicitante.EntidadNacimiento || "",
        state: solicitante.EntidadNacimiento || "",
        country: solicitante.Nacionalidad || "MEX",
        phone: "",
        email: "",
      },
    };
  } catch (error) {
    return {
      status: "error",
      message: "Error al consultar la CURP",
      data: null,
    };
  }
};

// Función para calcular la edad basada en la fecha de nacimiento
const calcularEdad = (fechaNacimiento) => {
  const birthDate = new Date(fechaNacimiento.split("/").reverse().join("-"));
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
