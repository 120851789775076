import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Typography,
  message,
  Row,
  Col,
  Spin,
  Select,
} from "antd";
import { createMembership, updateMembership } from "../../requests";
import { fetchCurpData } from "views/private/services/curp.service";
import { useUser } from "providers/user"; // 🔥 Importar usuario

const { Text } = Typography;
const { Option } = Select;

const NewMembership = ({
  showModal,
  setShowModal,
  refetch,
  editingMembership,
  setEditingMembership,
}) => {
  const { user } = useUser(); // 🔥 Obtener usuario del contexto
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fetchingCurp, setFetchingCurp] = useState(false);
  const [isMexican, setIsMexican] = useState(true);
  const [isCurpFetched, setIsCurpFetched] = useState(false);

  useEffect(() => {
    if (!showModal) {
      form.resetFields();
      setEditingMembership(null);
      setIsCurpFetched(false);
    }
  }, [showModal]);

  useEffect(() => {
    if (editingMembership && showModal) {
      form.setFieldsValue(editingMembership);
      setIsMexican(editingMembership.nationality !== "extranjero");
      setIsCurpFetched(true);
    }
  }, [editingMembership, showModal]);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
    setEditingMembership(null);
    setIsCurpFetched(false);
  };

  const fetchCurp = async () => {
    const curp = form.getFieldValue("curp");

    if (isMexican && !editingMembership) {
      /*       const curpRegex =
        /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}\d{6}[HM]{1}[A-Z]{5}[0-9A-Z]{2}\d{1}$/i;

      if (!curpRegex.test(curp)) {
        message.warning("El CURP ingresado no es válido.");
        return;
      } */
    }

    setFetchingCurp(true);

    try {
      const response = await fetchCurpData(curp);
      console.log("CURP Response:", response);

      if (response.status === "success") {
        form.setFieldsValue(response.data);
        setIsCurpFetched(true);
        message.success("Datos cargados correctamente.");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error("Error al consultar el CURP.");
    }

    setFetchingCurp(false);
  };

  const onSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);

    try {
      if (editingMembership) {
        await updateMembership(editingMembership._id, values);
        message.success("Membresía actualizada correctamente.");
      } else {
        await createMembership(values);
        message.success("Membresía creada correctamente.");
      }
      refetch();
      onClose();
    } catch (error) {
      console.error("Error al guardar la membresía:", error);
      message.error("Error al guardar la membresía.");
    }

    setLoading(false);
  };

  return (
    <Drawer
      title={editingMembership ? "Editar Membresía" : "Nueva Membresía"}
      open={showModal}
      onClose={onClose}
      width={"60vw"}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        {/* Selector de Nacionalidad */}
        <Text strong>Nacionalidad *</Text>
        <Form.Item
          name="nationality"
          initialValue="mexicano"
          rules={[{ required: true, message: "Seleccione la nacionalidad" }]}
        >
          <Select
            disabled={editingMembership}
            onChange={(value) => {
              const isMex = value === "mexicano";
              setIsMexican(isMex);
              setIsCurpFetched(false);
              form.resetFields([
                "curp",
                "firstName",
                "lastName",
                "middleName",
                "age",
                "city",
                "state",
                "country",
              ]);
              form.setFieldsValue({ country: isMex ? "MEX" : "" });
            }}
          >
            <Option value="mexicano">Mexicano</Option>
            <Option value="extranjero">Extranjero</Option>
          </Select>
        </Form.Item>

        {/* CURP o Documento de Identificación */}
        <Text strong>
          {isMexican ? "CURP *" : "Documento de Identificación *"}
        </Text>
        <Form.Item
          name="curp"
          rules={[
            {
              required: true,
              message: isMexican
                ? "Ingrese un CURP válido"
                : "Ingrese el documento de identificación",
            },
          ]}
        >
          <Input.Search
            placeholder={
              isMexican
                ? "Ingrese CURP"
                : "Ingrese número de pasaporte o documento extranjero"
            }
            onSearch={isMexican && !editingMembership ? fetchCurp : null}
            enterButton={
              isMexican && !isCurpFetched && !editingMembership ? (
                fetchingCurp ? (
                  <Spin size="small" />
                ) : (
                  "🔍"
                )
              ) : null
            }
            disabled={editingMembership}
          />
        </Form.Item>

        {/* Member ID opcional */}
        <Text strong>Member ID (Opcional)</Text>
        <Form.Item name="memberId">
          <Input placeholder="Ingrese el Member ID (Opcional)" />
        </Form.Item>

        {/* Datos Personales */}
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Text strong>Nombre *</Text>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input placeholder="Ingrese nombre" disabled={isMexican} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text strong>Apellido Paterno *</Text>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input placeholder="Apellido Paterno" disabled={isMexican} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text strong>Apellido Materno</Text>
            <Form.Item name="middleName">
              <Input placeholder="Apellido Materno" disabled={isMexican} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Text strong>Edad *</Text>
            <Form.Item
              name="age"
              rules={[{ required: true, message: "Requerido" }]}
            >
              <Input type="number" placeholder="Edad" disabled={isMexican} />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text strong>Municipio *</Text>
            <Form.Item name="city">
              <Input placeholder="Municipio" />
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Text strong>Estado *</Text>
            <Form.Item name="state">
              <Input placeholder="Estado" disabled={isMexican} />
            </Form.Item>
          </Col>
        </Row>

        {/* País */}
        <Text strong>País *</Text>
        <Form.Item name="country">
          <Input placeholder="País" disabled={isMexican} />
        </Form.Item>

        <Text strong>Email *</Text>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Ingrese un correo válido",
            },
          ]}
        >
          <Input placeholder="Correo electrónico" />
        </Form.Item>

        <Text strong>Teléfono *</Text>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "Requerido" }]}
        >
          <Input placeholder="Número de celular" />
        </Form.Item>

        {/* 🔥 Campo oculto con franchiseId */}
        <Form.Item name="franchiseId" initialValue={user?.franchiseId}>
          <Input type="hidden" />
        </Form.Item>

        <Button type="primary" block htmlType="submit" loading={loading}>
          {editingMembership ? "Actualizar" : "Crear"}
        </Button>
      </Form>
    </Drawer>
  );
};

export default NewMembership;
