import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Button, message, Row, Col, Select, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TitleContainer } from "./elements";
import { getCategories, getSubcategories } from "../rest-api";
import { useMutation } from "@apollo/client";
const { Text, Title } = Typography;
const { Option } = Select;
import { SYNC_ODOO_PRODUCTS } from "./requests";
const TableTitle = ({
  kitOrIndiv,
  setKitOrIndiv,
  searchSku,
  setSearchSku,
  searchTitle,
  setSearchTitle,
  productCategory,
  setProductCategory,
  productSubcategory,
  setProductSubcategory,
}) => {
  const [syncProductsMutation] = useMutation(SYNC_ODOO_PRODUCTS);
  const syncProducts = async () => {
    try {
      syncProductsMutation();
      message.success("La operación ha iniciado exitosamente!");
      message.info("Para ver el progreso, dirigete al apartado de Operaciones");
    } catch (e) {
      console.log(e);
      message.error("Error al sincronizar");
    }
  };
  const [categories, setCategories] = useState([]); // Categorías principales
  const [groupedSubcategories, setGroupedSubcategories] = useState({}); // Subcategorías agrupadas

  useEffect(() => {
    const fetchCategoriesAndSubcategories = async () => {
      try {
        const categoriesRes = await getCategories({ limit: 0, offset: 0 });
        const subcategoriesRes = await getSubcategories({
          limit: 0,
          offset: 0,
        });

        // Procesar categorías
        setCategories(categoriesRes.data.categories || []);

        // Agrupar subcategorías por categoría padre
        const grouped = {};
        subcategoriesRes.data.subcategories.forEach((sub) => {
          if (sub.parentCategory) {
            if (!grouped[sub.parentCategory]) {
              grouped[sub.parentCategory] = [];
            }
            grouped[sub.parentCategory].push(sub.name);
          }
        });

        setGroupedSubcategories(grouped);
      } catch (error) {
        console.error("❌ Error al obtener categorías/subcategorías:", error);
      }
    };

    fetchCategoriesAndSubcategories();
  }, []);

  const handleCategoryChange = (value) => {
    setProductCategory(value);
    setProductSubcategory(null); // Resetear subcategoría cuando cambian de categoría
  };

  return (
    <TitleContainer>
      <Row
        style={{
          width: "100%",
          padding: 0,
        }}
        gutter={[20, 16]} // Espaciado entre columnas
        align="middle"
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 20, fontWeight: "800" }}>Productos</Text>
        </Col>

        {/* Filtrar por Nombre */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Filtrar por Nombre
          </Title>
          <Input
            placeholder="Buscar por Nombre"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            allowClear
            style={{ width: "100%" }}
          />
        </Col>

        {/* Filtrar por SKU */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Filtrar por SKU
          </Title>
          <Input
            placeholder="Buscar por SKU"
            value={searchSku}
            onChange={(e) => setSearchSku(e.target.value)}
            allowClear
            style={{ width: "100%" }}
          />
        </Col>

        {/* Categorías */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Categoría
          </Title>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Selecciona una categoría"
            value={productCategory}
            onChange={handleCategoryChange}
          >
            {categories.map((cat) => (
              <Option key={cat.name} value={cat.name}>
                {cat.name}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Subcategorías (dependientes de la categoría seleccionada) */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Subcategoría
          </Title>
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Selecciona una subcategoría"
            value={productSubcategory}
            onChange={(value) => setProductSubcategory(value)}
            disabled={!productCategory}
          >
            {(groupedSubcategories[productCategory] || []).map((sub) => (
              <Option key={sub} value={sub}>
                {sub}
              </Option>
            ))}
          </Select>
        </Col>

        {/* Kit / Individual */}
        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
          <Title level={5} style={{ fontSize: "0.9rem" }}>
            Kit / Individual
          </Title>
          <Select
            defaultValue="Todo"
            style={{ width: "100%" }}
            value={kitOrIndiv}
            onChange={(value) => setKitOrIndiv(value)}
          >
            <Option value="all">Todo</Option>
            <Option value="true">Kit</Option>
            <Option value="false">Individual</Option>
          </Select>
        </Col>

        {/* Botón de sincronización */}
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Row justify="end">
            <Col>
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                style={{
                  width: "100%",
                  borderRadius: 8,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                onClick={() => syncProducts()}
              >
                SYNC Odoo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleContainer>
  );
};

TableTitle.propTypes = {
  kitOrIndiv: PropTypes.string.isRequired,
  setKitOrIndiv: PropTypes.func.isRequired,
  searchSku: PropTypes.string.isRequired,
  setSearchSku: PropTypes.func.isRequired,
  searchTitle: PropTypes.string.isRequired,
  setSearchTitle: PropTypes.func.isRequired,
  productCategory: PropTypes.string,
  setProductCategory: PropTypes.func.isRequired,
  productSubcategory: PropTypes.string,
  setProductSubcategory: PropTypes.func.isRequired,
  subcategories: PropTypes.object.isRequired,
};

export default TableTitle;
