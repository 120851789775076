import React, { useState } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  message,
  Upload,
  Spin,
  Select,
  Tooltip,
  Table,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { CREATE_BANNER } from "./requests";
import { useMutation, useQuery } from "@apollo/client";
import useUpload from "hooks/use-upload/index";
import { formatCurrency } from "utils/functions";
import { GET_PRODUCTS, GET_COLLABORATIONS } from "./requests";

const { Title, Text } = Typography;
const { Option } = Select;

const CreateBanner = ({ showModal, setShowModal, refetch }) => {
  const [form] = Form.useForm();
  const { upload } = useUpload();
  const [uploadLoding, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [typeOfBanner, setTypeOfBanner] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchSku, setSearchSku] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { data: productsData } = useQuery(GET_PRODUCTS, {
    variables: {
      filters: {
        title: searchTitle,
        sku: searchSku,
      },
    },
  });
  const products = productsData?.products?.results || [];

  const { data: collaborationsData } = useQuery(GET_COLLABORATIONS, {
    variables: {
      filters: {
        active: "true",
      },
    },
  });
  const collaborations = collaborationsData?.collaborations?.results || [];

  const [createBannerMutation] = useMutation(CREATE_BANNER);

  const onClose = () => {
    setShowModal(false);
  };

  const handleFileUpload = async ({ fileList }) => {
    if (fileList[0]) {
      const fileName = `banners/${new Date().toISOString()}/${
        fileList[0]?.name
      }`;
      setUploadLoading(true);
      const url = await upload(fileList[0].originFileObj, fileName);

      setImageUrl(url);
    }
    setUploadLoading(false);
    message.success("Imagen agregada.");
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          banner: {
            ...values,
            img: imageUrl,
            collaboration: values.collaboration ?? null,
            products: selectedProducts ?? [],
          },
        },
      };
      await createBannerMutation(payload);
      await refetch();
      message.success("Se creó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
      setSelectedProducts([]);
      setSearchTitle("");
      setSearchSku("");
      setTypeOfBanner(null);
      setImageUrl(null);
      form.resetFields();
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      render: (text, record) => (
        <img
          src={text}
          alt={record.title}
          style={{ maxWidth: "80px", maxHeight: "80px" }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (text) => <Text>{formatCurrency(text)}</Text>,
    },
  ];

  const handleSelect = (record) => {
    const keys = [...selectedProducts];
    if (keys.includes(record.id)) {
      setSelectedProducts(keys.filter((key) => key !== record.id));
    } else {
      setSelectedProducts([...keys, record.id]);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedProducts,
    onChange: (selectedKeys, selectedRows) => {
      const newSelectedKeys = selectedRows.map((row) => row.id);

      const uniqueNewKeys = newSelectedKeys.filter(
        (key) => !selectedProducts.includes(key)
      );

      if (uniqueNewKeys.length > 0) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...uniqueNewKeys,
        ]);
      }
    },
  };

  return (
    <>
      <Drawer
        visible={showModal}
        onClose={onClose}
        title={<Title level={3}>Crear Banner</Title>}
        width={"60%"}
      >
        <Form form={form} name="banner" onFinish={onFinish}>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Nombre <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="Título" />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Tipo <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Selecciona el tipo de Banner"
                onChange={(value) => {
                  setTypeOfBanner(value);
                }}
              >
                <Option value="collaboration">Colaboración</Option>
                <Option value="products">Productos</Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Imagen <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Upload
              beforeUpload={() => false}
              listType="picture"
              maxCount={1}
              accept="image/*"
              onChange={(info) => {
                handleFileUpload(info);
              }}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>Seleccionar Imagen</Button>
              {uploadLoding != "" ? <Spin style={{ margin: 10 }} /> : null}
            </Upload>
            {imageUrl && (
              <Image style={{ marginLeft: 20 }} width={80} src={imageUrl} />
            )}
          </div>
          {typeOfBanner === "collaboration" && (
            <div style={{ marginTop: 20 }}>
              <Text
                style={{
                  fontWeight: "800",
                  marginRight: 10,
                  fontSize: 14,
                }}
              >
                Colaboradores <Text style={{ color: "red" }}> *</Text>
              </Text>
              <Form.Item
                name="collaboration"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Selecciona el Colaborador">
                  {collaborations?.map((collab) => {
                    return (
                      <Option key={collab.id} value={collab.id}>
                        {collab.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
          {typeOfBanner === "products" && (
            <>
              <div
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <Tooltip title="Filtrar por Nombre">
                  <Input
                    placeholder="Buscar por Nombre"
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                    allowClear
                  />
                </Tooltip>
                <Tooltip title="Filtrar por SKU">
                  <Input
                    style={{ marginLeft: 10 }}
                    placeholder="Buscar por SKU"
                    value={searchSku}
                    onChange={(e) => setSearchSku(e.target.value)}
                    allowClear
                  />
                </Tooltip>
              </div>
              <div style={{ marginTop: 20 }}>
                <Table
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={products}
                  rowKey="id"
                  onRow={(record, index) => ({
                    onClick: () => handleSelect(record, index),
                  })}
                />
              </div>
            </>
          )}
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Crear Banner
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default CreateBanner;
