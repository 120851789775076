import { gql } from "@apollo/client";

const CREATE_COLLABORATION = gql`
  mutation createCollaboration($collaboration: CollaborationCreateInput!) {
    createCollaboration(collaboration: $collaboration) {
      id
    }
  }
`;

const GET_PRODUCTS = gql`
  query products($params: QueryParams, $filters: ProductFilters) {
    products(params: $params, filters: $filters) {
      results {
        id
        odooId
        title
        img
        description
        price {
          amount
          exponent
          currency
        }
        category {
          name
          value
        }
        tags {
          name
          value
        }
        sku
      }
    }
  }
`;

export { CREATE_COLLABORATION, GET_PRODUCTS };
