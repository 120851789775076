import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation createUser($user: UserCreateInput!) {
    createUser(user: $user) {
      success
      message
      user {
        id
        firstName
        lastName
        email
        franchiseId
      }
    }
  }
`;
const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $user: UserUpdateInput!) {
    updateUser(id: $id, user: $user) {
      success
      message
      user {
        id
        firstName
        lastName
        email
        phone
        mobile
        img
        franchiseId
      }
    }
  }
`;
const GET_FRANCHISES = gql`
  query getFranchises($params: QueryParams) {
    franchises(params: $params) {
      results {
        id
        name
      }
    }
  }
`;
export { CREATE_USER, UPDATE_USER, GET_FRANCHISES };
