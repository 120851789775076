import React, { useState } from "react";
import { Row, Col, Typography, Timeline } from "antd";
import {
  Container,
  IndicatorContainer,
  LineCard,
  GraphContainer,
  RankingCard,
  Gradient,
  InformationCard,
  WhiteParagraph,
  InformationFooter,
} from "./elements";
import {
  DropboxOutlined,
  ClockCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import IndicatorComponent from "./components/indicator";
import LineChart from "./components/lineChart";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_ORDERS_INDICATORS,
  GET_ORDERS_DAYS_OF_CURRENT_WEEK,
  GET_ORDERS_DAYS_OF_DATE_RANGE,
  GET_TOP_CLIENTS,
} from "./request";
import moment from "moment";
import { formatCurrency } from "utils/functions";
import { Money } from "utils/types";

const { Text, Paragraph, Title } = Typography;

const Dashboard = () => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const { data: allClients } = useQuery(GET_TOP_CLIENTS, {
    variables: {
      params: {
        pageSize: 10,
        page: 1,
      },
    },
  });
  const clients = allClients?.topClients?.results;

  const calculateTotalAmount = (client) => {
    return client?.orders?.reduce((total, order) => {
      if (order?.payment?.status === "paid") {
        const amount =
          order.payment.total.amount *
          Math.pow(10, -order.payment.total.exponent);
        return total + amount;
      }
      return total;
    }, 0);
  };

  const sortedClients = clients?.slice().sort((a, b) => {
    const totalAmountA = calculateTotalAmount(a);
    const totalAmountB = calculateTotalAmount(b);

    return totalAmountB - totalAmountA;
  });

  const { data: ordersIndicators } = useQuery(GET_ORDERS_INDICATORS, {
    variables: {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
    },
  });

  const { data: ordersByDaysOfTheCurrentWeek } = useQuery(
    GET_ORDERS_DAYS_OF_CURRENT_WEEK
  );

  const { data: ordersByDaysOfTheDateRange } = useQuery(
    GET_ORDERS_DAYS_OF_DATE_RANGE,
    {
      skip: !dateStart || !dateEnd,
      variables: {
        dateStart: moment(dateStart).startOf("day").toDate(),
        dateEnd: moment(dateEnd).endOf("day").toDate(),
      },
    }
  );

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const spanishDaysOfWeek = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];

  const ordersData = spanishDaysOfWeek.map((day, index) => {
    return {
      x: day,
      y:
        ordersByDaysOfTheCurrentWeek?.ordersByDaysOfTheCurrentWeek[
          daysOfWeek[index].toLowerCase()
        ] ?? 0,
    };
  });

  const ordersDataByDays =
    ordersByDaysOfTheDateRange?.ordersByDaysOfTheDateRange
      .map((order) => ({
        x: order.createdAt,
        y: order.count,
        day: order.day,
      }))
      .sort((a, b) => parseInt(a.day) - parseInt(b.day));

  return (
    <Container>
      <Row>
        <Col
          xs={24} // Ocupa todo el ancho en pantallas pequeñas
          sm={24} // Ocupa todo el ancho en pantallas pequeñas
          md={24} // Ocupa todo el ancho en pantallas medianas
          lg={17} // Ocupa 17/24 del ancho en pantallas grandes
          xl={17} // Ocupa 17/24 del ancho en pantallas extra grandes
        >
          <IndicatorContainer>
            <Row style={{ justifyContent: "space-between" }}>
              <IndicatorComponent
                title="Ventas Mes Actual"
                value={formatCurrency(
                  ordersIndicators?.ordersIndicators?.priceTotal
                )}
                titleLevel={4}
                subValue=""
                subValueColor="green"
                icon={
                  <DropboxOutlined style={{ fontSize: 20, color: "white" }} />
                }
              />
              <IndicatorComponent
                title="Órdenes Mes Actual"
                value={ordersIndicators?.ordersIndicators?.ordersCount}
                subValueColor="#8c8c8c"
                titleLevel={1}
                icon={
                  <ClockCircleOutlined
                    style={{ fontSize: 20, color: "white" }}
                  />
                }
              />
              <IndicatorComponent
                title="Ticket Promedio"
                value={
                  formatCurrency(
                    ordersIndicators?.ordersIndicators?.averageTicket
                  ) ?? 0
                }
                subValue=""
                subValueColor="green"
                icon={
                  <ClockCircleOutlined
                    style={{ fontSize: 20, color: "white" }}
                  />
                }
              />
            </Row>
          </IndicatorContainer>
          <GraphContainer>
            <LineCard
              style={{
                borderRadius: 5,
              }}
            >
              <LineChart
                ordersData={
                  ordersByDaysOfTheDateRange?.ordersByDaysOfTheDateRange
                    ? ordersDataByDays
                    : ordersData
                }
                categories={spanishDaysOfWeek}
                dateStart={dateStart}
                setDateStart={setDateStart}
                dateEnd={dateEnd}
                setDateEnd={setDateEnd}
              />
            </LineCard>
          </GraphContainer>
        </Col>
        <Col
          xs={24} // Ocupa todo el ancho en pantallas pequeñas
          sm={24} // Ocupa todo el ancho en pantallas pequeñas
          md={24} // Ocupa todo el ancho en pantallas medianas
          lg={7} // Ocupa 17/24 del ancho en pantallas grandes
          xl={7} // Ocupa 17/24 del ancho en pantallas extra grandes
          style={{ justifyContent: "space-between" }}
        >
          <RankingCard
            style={{
              borderRadius: 0,
            }}
          >
            <Col>
              <Row
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Text strong style={{ fontSize: 18 }}>
                    Clientes
                  </Text>
                  <Paragraph style={{ color: "#8c8c8c" }}>
                    Ranking Clientes
                  </Paragraph>
                </div>
              </Row>
            </Col>
            <Timeline
              style={{
                overflow: "auto",
                height: "370px",
              }}
            >
              {sortedClients
                ?.filter((client) => calculateTotalAmount(client) > 0)
                ?.map((client, index) => {
                  const totalAmount = calculateTotalAmount(client);
                  return (
                    <Timeline.Item color="blue" key={index}>
                      <Row
                        style={{
                          backgroundColor: "white",
                          paddingTop: "10px",
                          borderRadius: "8px",
                          transition: "background-color 0.2s ease-in-out",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}>
                          <Text
                            style={{ fontSize: 15 }}
                            strong
                          >{`${client.user.firstName} ${client.user.lastName}`}</Text>
                          <br />
                          <Text>
                            Total:{" "}
                            {formatCurrency(
                              new Money(totalAmount.toFixed(2) * 100)
                            )}
                          </Text>
                        </div>
                      </Row>
                    </Timeline.Item>
                  );
                })}
            </Timeline>
          </RankingCard>
          <Gradient bordered={false} style={{ borderRadius: 5 }}>
            <InformationCard>
              <div>
                <Title level={5} style={{ color: "white", fontWeight: "800" }}>
                  Reportes
                </Title>
                <WhiteParagraph style={{ color: "white" }}>
                  Analiza a fondo cualquier información de tu operación
                </WhiteParagraph>
              </div>
              <InformationFooter>
                <Row style={{ justifyContent: "space-between" }}>
                  <Link to="/reportes">
                    Reportes
                    <RightOutlined style={{ color: "white" }} />
                  </Link>
                </Row>
              </InformationFooter>
            </InformationCard>
          </Gradient>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
