import { gql } from "@apollo/client";

const POST_ORDER = gql`
  mutation manuallyPostOrderToOdoo($order: ID!) {
    manuallyPostOrderToOdoo(order: $order) {
      id
    }
  }
`;

const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $order: OrderUpdate!) {
    updateOrder(id: $id, order: $order) {
      id
    }
  }
`;

export { POST_ORDER, UPDATE_ORDER };
