import React, { useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Button,
  Form,
  Calendar,
  Typography,
  TimePicker,
  Select,
  Col,
  Tag,
  message,
  Modal,
} from "antd";
import { UPDATE_FRANCHISE_SCHEDULE_EVENTS } from "./requests";
import { UPDATE_FRANCHISE } from "../../requests";
import moment from "moment";
import { useMutation } from "@apollo/client";
import "./index.css";

const { Title } = Typography;

const ScheduleDrawer = ({
  open,
  setOpen,
  currentFranchise,
  setCurrent,
  refetch,
}) => {
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(() => moment());
  const [selectedValue, setSelectedValue] = useState(() => moment());

  const [formChanged, setFormChanged] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedTimeRange, setSelectedTimeRange] = useState([]);
  const [disableHolidayButton, setDisableHolidayButton] = useState(false);
  const [modalInputChanged, setModalInputChanged] = useState(false);

  const hoursRegex = /^\d{1,2}:\d{2}-\d{1,2}:\d{2}$/;

  useEffect(() => {
    const newInitialFormValues = {
      monday: hoursRegex.test(currentFranchise?.schedule?.monday)
        ? [
            moment(currentFranchise?.schedule?.monday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.monday?.split("-")[1], "H:mm"),
          ]
        : [],
      tuesday: hoursRegex.test(currentFranchise?.schedule?.tuesday)
        ? [
            moment(currentFranchise?.schedule?.tuesday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.tuesday?.split("-")[1], "H:mm"),
          ]
        : [],
      wednesday: hoursRegex.test(currentFranchise?.schedule?.wednesday)
        ? [
            moment(
              currentFranchise?.schedule?.wednesday?.split("-")[0],
              "H:mm"
            ),
            moment(
              currentFranchise?.schedule?.wednesday?.split("-")[1],
              "H:mm"
            ),
          ]
        : [],
      thursday: hoursRegex.test(currentFranchise?.schedule?.thursday)
        ? [
            moment(currentFranchise?.schedule?.thursday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.thursday?.split("-")[1], "H:mm"),
          ]
        : [],
      friday: hoursRegex.test(currentFranchise?.schedule?.friday)
        ? [
            moment(currentFranchise?.schedule?.friday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.friday?.split("-")[1], "H:mm"),
          ]
        : [],
      saturday: hoursRegex.test(currentFranchise?.schedule?.saturday)
        ? [
            moment(currentFranchise?.schedule?.saturday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.saturday?.split("-")[1], "H:mm"),
          ]
        : [],
      sunday: hoursRegex.test(currentFranchise?.schedule?.sunday)
        ? [
            moment(currentFranchise?.schedule?.sunday?.split("-")[0], "H:mm"),
            moment(currentFranchise?.schedule?.sunday?.split("-")[1], "H:mm"),
          ]
        : [],
    };

    form.setFieldsValue(newInitialFormValues);
  }, [currentFranchise]);

  const handleFormChange = (changedValues) => {
    setFormChanged(Object.keys(changedValues).length > 0);
  };

  const [updateFranchise] = useMutation(UPDATE_FRANCHISE);

  const onFinish = async (values) => {
    const updatedValues = {};
    for (const [key, value] of Object.entries(values)) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        updatedValues[key] = "closed";
      } else {
        updatedValues[key] = value;
        const formattedTimes = value.map((momentDate) =>
          momentDate.format("H:mm")
        );
        updatedValues[key] = formattedTimes.join("-");
      }
    }

    try {
      const payload = {
        variables: {
          id: currentFranchise.id,
          franchise: {
            schedule: updatedValues,
          },
        },
      };
      await updateFranchise(payload);
      await refetch();

      const updatedFranchiseSchedule = {
        ...currentFranchise,
        schedule: updatedValues,
      };
      setCurrent(updatedFranchiseSchedule);
      setFormChanged(false);

      message.success("Se modificó el horario con éxito!");
    } catch (err) {
      console.error(err);
      message.error("Error al modificar el horario");
    } finally {
      setEditMode(false);
    }
  };

  const [updateFranchiseScheduleEvents] = useMutation(
    UPDATE_FRANCHISE_SCHEDULE_EVENTS
  );

  const newEvent = async (defaultValue = "closed") => {
    const dateString = selectedValue.format("YYYY-MM-DD");

    const updatedEvents = [...currentFranchise.events];

    const existingEventIndex = updatedEvents.findIndex(
      (event) => event.date === dateString
    );

    let joinedTimeRange = defaultValue;
    if (
      selectedTimeRange &&
      selectedTimeRange.length > 0 &&
      defaultValue !== "holiday"
    ) {
      const formattedTimes = selectedTimeRange.map((momentDate) =>
        momentDate.format("H:mm")
      );
      joinedTimeRange = formattedTimes.join("-");
    }

    const updatedEvent = {
      date: dateString,
      content: joinedTimeRange,
    };

    if (existingEventIndex !== -1) {
      updatedEvents[existingEventIndex] = updatedEvent;
    } else {
      updatedEvents.push(updatedEvent);
    }

    try {
      const payload = {
        variables: {
          id: currentFranchise.id,
          event: {
            date: updatedEvent.date,
            content: updatedEvent.content,
          },
        },
      };

      await updateFranchiseScheduleEvents(payload);
      await refetch();

      const updatedFranchise = {
        ...currentFranchise,
        events: updatedEvents,
      };

      setCurrent(updatedFranchise);

      message.success("Se modificó el horario con éxito!");
    } catch (err) {
      console.error(err);
      message.error("Error al modificar el horario");
    } finally {
      closeModal();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const closeEdit = () => {
    setEditMode(false);
  };

  const onClose = () => {
    setOpen(false);
    setCurrent(undefined);
    form.resetFields();
    setEditMode(false);
    setFormChanged(false);
    setValue(moment());
    setSelectedValue(moment());
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedTimeRange([]);
    setDisableHolidayButton(false);
    setModalInputChanged(false);
  };

  const onSelect = (newValue) => {
    setValue(newValue);
    setSelectedValue(newValue);
  };

  const pickDate = (newValue) => {
    setOpenModal(true);

    const dateString = newValue.format("YYYY-MM-DD");

    const eventForDate = currentFranchise?.events?.filter(
      (event) => event.date === dateString
    );

    if (eventForDate.length > 0) {
      if (
        eventForDate[0].content === "closed" ||
        eventForDate[0].content === "holiday"
      ) {
        setSelectedTimeRange([]);
        if (eventForDate[0].content === "holiday") {
          setDisableHolidayButton(true);
        }
      } else if (eventForDate[0].content) {
        const splitContent = eventForDate[0].content.split("-");
        if (splitContent.length === 2) {
          setSelectedTimeRange([
            moment(splitContent[0], "H:mm"),
            moment(splitContent[1], "H:mm"),
          ]);
        }
      }
    } else {
      const dayOfWeek = moment(newValue).format("dddd");
      const predeterminedContent =
        currentFranchise?.schedule[dayTranslation[dayOfWeek]];

      if (predeterminedContent === "closed") {
        setSelectedTimeRange([]);
      } else if (predeterminedContent) {
        const splitContent = predeterminedContent.split("-");
        if (splitContent.length === 2) {
          setSelectedTimeRange([
            moment(splitContent[0], "H:mm"),
            moment(splitContent[1], "H:mm"),
          ]);
        }
      }
    }
  };

  const getColorForStatus = (value) => {
    if (value === "closed") {
      return "red";
    } else if (value === "holiday") {
      return "purple";
    } else {
      return "default";
    }
  };

  const dayTranslation = {
    lunes: "monday",
    martes: "tuesday",
    miércoles: "wednesday",
    jueves: "thursday",
    viernes: "friday",
    sábado: "saturday",
    domingo: "sunday",
  };

  const dateContentTranslation = {
    closed: "Cerrado",
    holiday: "Asueto",
  };

  const getListData = (value) => {
    const dateString = value.format("YYYY-MM-DD");

    const eventsForDate = currentFranchise?.events?.filter(
      (event) => event.date === dateString
    );

    if (eventsForDate?.length > 0) {
      return eventsForDate;
    } else {
      const dayOfWeek = moment(value).format("dddd");
      const predeterminedContent =
        currentFranchise?.schedule[dayTranslation[dayOfWeek]];
      if (
        predeterminedContent &&
        predeterminedContent !== "holiday" &&
        predeterminedContent !== "closed"
      ) {
        return [{ date: dateString, content: predeterminedContent }];
      } else if (predeterminedContent === "holiday") {
        return [{ date: dateString, content: predeterminedContent }];
      } else if (predeterminedContent === "closed") {
        return [{ date: dateString, content: predeterminedContent }];
      } else {
        return [{ date: dateString, content: "closed" }];
      }
    }
  };

  const customHeaderCalendar = ({ value, onChange }) => {
    const year = value.year();
    const month = value.month();
    const options = [];

    for (let i = year - 10; i < year + 10; i += 1) {
      options.push(
        <Select.Option key={i} value={i}>
          {i}
        </Select.Option>
      );
    }

    const start = 0;
    const end = 12;
    const monthOptions = [];
    const current = value.clone();
    const localeData = value.localeData();
    const months = [];

    for (let i = 0; i < 12; i++) {
      current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i}>
          {months[i]}
        </Select.Option>
      );
    }

    return (
      <div
        style={{
          padding: 8,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "16px" }}>{currentFranchise?.name}</span>
        <Row gutter={8}>
          <Col>
            <Select
              size={"small"}
              dropdownMatchSelectWidth={false}
              value={year}
              onChange={(newYear) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
            >
              {options}
            </Select>
          </Col>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              value={month}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                onChange(now);
              }}
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    const cellStyle = {
      textAlign: "center",
      width: "95%",
      height: "100px",
      padding: "8px",
      backgroundColor: "#fff",
      border: "0",
      borderTop: "2px solid #f0f0f0",
      borderRadius: "0",
      cursor: "pointer",
    };

    if (value.isSame(selectedValue, "day")) {
      cellStyle.backgroundColor = "#E6F4FF";
    }

    if (value.isSame(moment(), "day")) {
      cellStyle.borderTop = "2px solid #B3D9FF";
    }

    console.log(listData);

    if (listData.length > 0) {
      return (
        <div onClick={() => pickDate(value)} style={cellStyle} className="cell">
          <div style={{ textAlign: "right" }}>{value.date()}</div>
          {listData.map((data, index) => (
            <Tag
              key={index}
              style={{
                fontSize: 12,
                display: "inline-block",
                marginBottom: 5,
              }}
              color={getColorForStatus(data.content)}
            >
              {hoursRegex.test(data.content)
                ? data.content
                : dateContentTranslation[data.content]}
            </Tag>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Drawer placement="right" width={"65%"} onClose={onClose} open={open}>
      <Title level={3}>Detalles del calendario y horario</Title>
      <Modal
        title={`Fecha seleccionada: ${selectedValue?.format("YYYY-MM-DD")}`}
        open={openModal}
        onCancel={closeModal}
        footer={[
          <Button key="close" type="danger" onClick={closeModal}>
            Cerrar
          </Button>,
          <Button
            onClick={() => newEvent()}
            disabled={!modalInputChanged}
            key="submit"
            type="primary"
          >
            Guardar
          </Button>,
        ]}
      >
        <TimePicker.RangePicker
          style={{ width: "100%" }}
          format={"H:mm"}
          value={selectedTimeRange}
          onChange={(newTimeRange) => {
            setModalInputChanged(true);
            setSelectedTimeRange(newTimeRange);
          }}
        />
        <Button
          onClick={() => newEvent("holiday")}
          disabled={disableHolidayButton}
          type="default"
          style={{
            margin: "30px auto 0 auto",
            display: "block",
          }}
        >
          Marcar día como asueto
        </Button>
      </Modal>
      <Calendar
        headerRender={customHeaderCalendar}
        value={value}
        onSelect={onSelect}
        dateFullCellRender={dateCellRender}
      />
      <Form
        style={{ display: "flex", flexDirection: "column" }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleFormChange}
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 22,
        }}
      >
        <Title style={{ marginTop: 20 }} level={3}>
          Horario predeterminado
        </Title>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Lunes</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.monday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.monday)
                ? currentFranchise?.schedule?.monday
                : dateContentTranslation[
                    currentFranchise?.schedule?.monday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="monday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Martes</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.tuesday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.tuesday)
                ? currentFranchise?.schedule?.tuesday
                : dateContentTranslation[
                    currentFranchise?.schedule?.tuesday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="tuesday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Miércoles</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.wednesday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.wednesday)
                ? currentFranchise?.schedule?.wednesday
                : dateContentTranslation[
                    currentFranchise?.schedule?.wednesday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="wednesday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Jueves</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.thursday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.thursday)
                ? currentFranchise?.schedule?.thursday
                : dateContentTranslation[
                    currentFranchise?.schedule?.thursday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="thursday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Viernes</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.friday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.friday)
                ? currentFranchise?.schedule?.friday
                : dateContentTranslation[
                    currentFranchise?.schedule?.friday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="friday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Sábado</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.saturday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.saturday)
                ? currentFranchise?.schedule?.saturday
                : dateContentTranslation[
                    currentFranchise?.schedule?.saturday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="saturday"
              >
                <TimePicker.RangePicker
                  style={{ width: "100%" }}
                  format={"H:mm"}
                  size="small"
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        <Form.Item
          style={{ width: "100%", marginBottom: editMode ? 0 : 24 }}
          label={<span>Domingo</span>}
        >
          {!editMode && (
            <Tag
              color={getColorForStatus(
                currentFranchise?.schedule?.sunday || "closed"
              )}
            >
              {hoursRegex.test(currentFranchise?.schedule?.sunday)
                ? currentFranchise?.schedule?.sunday
                : dateContentTranslation[
                    currentFranchise?.schedule?.sunday || "closed"
                  ]}
            </Tag>
          )}
          {editMode && (
            <Row>
              <Form.Item
                style={{ width: "25%", marginRight: 10 }}
                name="sunday"
              >
                <TimePicker.RangePicker
                  size="small"
                  style={{ width: "100%" }}
                  format={"H:mm"}
                />
              </Form.Item>
            </Row>
          )}
        </Form.Item>
        {!editMode && (
          <Form.Item
            wrapperCol={{
              span: 20,
              offset: 4,
            }}
          >
            <Button
              style={{ marginBottom: 30 }}
              type="primary"
              onClick={handleEdit}
            >
              Editar
            </Button>
          </Form.Item>
        )}
        {editMode && (
          <Form.Item
            wrapperCol={{
              span: 20,
              offset: 4,
            }}
          >
            <Row style={{ marginBottom: 30 }}>
              <Button
                style={{ marginRight: 10 }}
                type="danger"
                onClick={closeEdit}
              >
                Cancelar
              </Button>

              <Button disabled={!formChanged} type="primary" htmlType="submit">
                Guardar cambios
              </Button>
            </Row>
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

export default ScheduleDrawer;
