import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Row, Col, Spin, DatePicker } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { SpinArea } from "../elements";
import moment from "moment";

const { Text } = Typography;

function LineChart({
  ordersData,
  categories,
  dateStart,
  setDateStart,
  dateEnd,
  setDateEnd,
}) {
  const loading = false;
  const [yaxisTitle, setYaxisTitle] = useState("Días de Semana Actual");
  const handleDateChange = () => {
    setYaxisTitle("Días Seleccionados");
  };

  console.log("gvgvgv", ordersData);

  return (
    <>
      <Row style={{ justifyContent: "space-between" }}>
        <div>
          <Text strong style={{ fontSize: 18 }}>
            Ventas Por Día
          </Text>
        </div>
        <Col>
          <Row>
            <Text style={{ marginRight: 5 }} strong>
              De :{" "}
            </Text>
            <DatePicker
              placeholder="Fecha"
              value={dateStart}
              style={{ marginLeft: 5 }}
              onChange={(e) => {
                setDateStart(e);
                handleDateChange();
              }}
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              picker="date"
              showToday={false}
              showTime={false}
            />
            <Text style={{ marginLeft: 5 }} strong>
              Hasta :{" "}
            </Text>
            <DatePicker
              placeholder="Fecha"
              value={dateEnd}
              style={{ marginLeft: 5 }}
              onChange={(e) => {
                setDateEnd(e);
                handleDateChange();
              }}
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              picker="date"
              showToday={false}
              showTime={false}
            />
          </Row>
        </Col>

        <Col>
          <Row>
            {
              <MinusOutlined
                style={{ marginRight: 10, marginTop: 5, color: "#00e396" }}
              />
            }
            Ventas
          </Row>
          <Row>
            {
              <MinusOutlined
                style={{ marginRight: 10, marginTop: 5, color: "#008ffb" }}
              />
            }{" "}
            Órdenes
          </Row>
        </Col>
      </Row>
      {loading ? (
        <SpinArea>
          <Spin />
        </SpinArea>
      ) : (
        <ReactApexChart
          options={{
            chart: {
              width: "100%",
              height: 300,
              type: "area",
              toolbar: {
                show: false,
              },
            },

            legend: {
              show: false,
            },

            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },

            yaxis: {
              min: 0,
              title: {
                text: "Cantidad",
                style: { fontSize: "15px" },
              },
              labels: {
                formatter: function (val) {
                  return parseInt(val);
                },
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: ["#8c8c8c"],
                },
              },
            },

            xaxis: {
              title: {
                text: yaxisTitle,
                style: { fontSize: "15px" },
              },
              labels: {
                style: {
                  fontSize: "14px",
                  fontWeight: 600,
                  colors: [
                    "#8c8c8c",
                    "#8c8c8c",
                    "#8c8c8c",
                    "#8c8c8c",
                    "#8c8c8c",
                    "#8c8c8c",
                  ],
                },
              },
              categories: categories ?? [],
            },

            tooltip: {
              y: {
                formatter: function (val) {
                  return val;
                },
              },
            },
          }}
          series={[
            {
              name: "Órdenes Generadas",
              data: ordersData,
            },
          ]}
          type="line"
          height={"350vh"}
          width={"100%"}
        />
      )}
    </>
  );
}

export default LineChart;
