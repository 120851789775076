import { gql } from "@apollo/client";

const GET_PRODUCTS = gql`
  query products($params: QueryParams, $filters: ProductFilters) {
    products(params: $params, filters: $filters) {
      results {
        id
        title
      }
      info {
        count
        prev
        next
        pages
      }
    }
  }
`;

export { GET_PRODUCTS };
