import { gql } from "@apollo/client";

const UPDATE_BUNDLE = gql`
  mutation updateBundle($id: ID!, $bundle: BundleUpdate!) {
    updateBundle(id: $id, bundle: $bundle) {
      title
    }
  }
`;

export { UPDATE_BUNDLE };
