import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Table,
  Button,
  Tooltip,
  Col,
  message,
  Modal,
} from "antd";
import { Container } from "./elements";
import { getSocialNetworks, deleteSocialNetwork } from "./requests";
import { EditOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import EditSocialNetwork from "./edit/index";
import { Badge } from "../../../../../../../node_modules/antd/lib/index";

const { Title } = Typography;

const SocialNetworks = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingNetwork, setEditingNetwork] = useState(null);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [deletingNetworkId, setDeletingNetworkId] = useState(null);

  // Función para cargar las redes sociales
  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await getSocialNetworks();
      setData(
        Array.isArray(result.data.socialNetworks)
          ? result.data.socialNetworks
          : []
      );
    } catch (error) {
      console.error("Error fetching social networks:", error);
      message.error("Error al cargar las redes sociales.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Manejo de la eliminación con confirmación
  const handleDelete = async () => {
    if (!deletingNetworkId) return;
    try {
      await deleteSocialNetwork(deletingNetworkId);
      message.success("Red social eliminada con éxito.");
      setConfirmDeleteModalVisible(false);
      fetchData(); // Recargar los datos
    } catch (error) {
      message.error("Error al eliminar la red social.");
    }
  };

  // Columnas para la tabla
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "URL",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Icono",
      dataIndex: "icon",
      key: "icon",
      render: (icon) => (
        <img src={icon} alt="Icono" style={{ width: 50, height: 50 }} />
      ),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Badge
          status={status === "active" ? "success" : "error"}
          text={status === "active" ? "Activo" : "Inactivo"}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <div style={{ display: "flex", gap: "8px" }}>
          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setEditingNetwork(record);
                setShowModal(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeletingNetworkId(record._id); // Guarda el ID de la red social a eliminar
                setConfirmDeleteModalVisible(true); // Muestra el modal de confirmación
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "10px" }}
      >
        <Col>
          <Title style={{ fontSize: "1.4rem", marginBottom: "0px" }} level={2}>
            Social Networks
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingNetwork(null); // Limpia para el modo de creación
              setShowModal(true);
            }}
          >
            Agregar Red Social
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Table
            dataSource={data}
            columns={columns}
            loading={loading}
            rowKey={(record) => record.id || record._id}
          />
        </Col>
      </Row>

      {/* Modal de edición/creación */}
      <EditSocialNetwork
        showModal={showModal}
        setShowModal={setShowModal}
        editingNetwork={editingNetwork}
        refetch={fetchData}
      />

      {/* Modal de confirmación de eliminación */}
      <Modal
        title="Confirmar eliminación"
        visible={confirmDeleteModalVisible}
        onOk={handleDelete} // Llama a la función para eliminar
        onCancel={() => setConfirmDeleteModalVisible(false)}
        okText="Eliminar"
        cancelText="Cancelar"
      >
        <p>¿Está seguro que desea eliminar esta red social?</p>
      </Modal>
    </Container>
  );
};

export default SocialNetworks;
