import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Row,
  Button,
  Input,
  message,
  Image,
  Upload,
  Radio,
} from "antd";
import { useMutation } from "@apollo/client";
import { UPDATE_FRANCHISE, UPDATE_MAIN_WAREHOUSE } from "../../requests";
import { InboxOutlined } from "@ant-design/icons";
import useUpload from "hooks/use-upload/index";
import { useUser } from "providers/user";

const DetailDrawer = ({
  open,
  setOpen,
  currentFranchise,
  setCurrent,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [uploading, setUploading] = useState(false);
  const [mainWarehouse, setMainWarehouse] = useState();
  const [editMainWarehose, setEditMainWarehose] = useState(false);
  const [loading, setLoading] = useState(false);

  const { upload } = useUpload();
  const { user } = useUser();
  const userType = user?.userType;

  useEffect(() => {
    form.setFieldsValue({
      id: currentFranchise?.id,
      name: currentFranchise?.name,
      phone: currentFranchise?.phone,
      img: currentFranchise?.img,
      fullName: currentFranchise?.address?.fullName,
      country: currentFranchise?.address?.country,
      state: currentFranchise?.address?.state,
      city: currentFranchise?.address?.city,
      street: currentFranchise?.address?.street,
      postcode: currentFranchise?.address?.postcode,
      colony: currentFranchise?.address?.colony,
    });
    setMainWarehouse(currentFranchise?.mainWarehouse);
  }, [form, currentFranchise]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const closeEdit = () => {
    setEditMode(false);
    setEditMainWarehose(false);
    setMainWarehouse(currentFranchise?.mainWarehouse);
  };

  const onClose = () => {
    setOpen(false);
    setCurrent(undefined);
    setEditMode(false);
    setFormChanged(false);
    setEditMainWarehose(false);
    setMainWarehouse();
  };

  const handleFormChange = async () => {
    const isFormChanged = form.isFieldsTouched();
    setFormChanged(isFormChanged);
    const values = await form.getFieldsValue(true);
    console.log(values);
  };

  const handleFileUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploading(true);
      const fileName = `franchises/${new Date().toISOString()}/${
        fileList[0]?.name
      }`;
      const url = await upload(fileList[0].originFileObj, fileName);
      setImageUrl(url);
      setUploading(false);
    }
  };

  const [updateFranchise] = useMutation(UPDATE_FRANCHISE);
  const [updateMainWareHouse] = useMutation(UPDATE_MAIN_WAREHOUSE);

  const onFinish = async () => {
    setLoading(true);
    try {
      const values = await form.getFieldsValue(true);
      const payload = {
        variables: {
          id: currentFranchise.id,
          franchise: {
            name: values.name,
            img: imageUrl ?? values.img,
            phone: values.phone,
            address: {
              fullName: values.fullName,
              country: values.country,
              city: values.city,
              state: values.state,
              colony: values.colony,
              street: values.street,
              postcode: values.postcode,
              location: {
                type: currentFranchise.address.location.type,
                coordinates: [
                  currentFranchise.address.location.coordinates[0],
                  currentFranchise.address.location.coordinates[1],
                ],
              },
            },
          },
        },
      };
      await updateFranchise(payload);

      if (editMainWarehose) {
        await updateMainWareHouse({
          variables: {
            id: currentFranchise?.id,
            mainWarehouse,
          },
        });
      }

      await refetch();
      message.success("Se actualizaron los datos con éxito!");
    } catch (err) {
      console.error(err);
      message.error("Error al actualizar los datos");
    } finally {
      onClose();
      setLoading(false);
    }
  };

  const renderFieldContent = (field) => {
    if (editMode) {
      return <Input placeholder={!field || field === "false" ? "" : field} />;
    } else {
      return <span>{!field || field === "false" ? "" : field}</span>;
    }
  };

  return (
    <Drawer
      title="Detalles de la franquicia"
      placement="right"
      width={"40%"}
      onClose={onClose}
      open={open}
    >
      <Form
        disabled={uploading}
        onValuesChange={handleFormChange}
        form={form}
        name="banner"
        onFinish={onFinish}
        style={{
          width: "100%",
          margin: "0 auto",
        }} /* 
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 21,
        }} */
      >
        {userType === "admin" ? (
          <>
            <span style={{ fontWeight: "bold" }}>Almacén principal: </span>
            {editMode ? (
              <>
                <Radio.Group
                  onChange={(e) => {
                    setMainWarehouse(e.target.value);
                    setEditMainWarehose(true);
                    setFormChanged(true);
                  }}
                  defaultValue={mainWarehouse}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </>
            ) : (
              <span style={{ fontWeight: "bold" }}>
                {mainWarehouse ? "Sí" : "No"}
              </span>
            )}
          </>
        ) : (
          <>
            <span style={{ fontWeight: "bold" }}>Almacén principal: </span>
            <span style={{ fontWeight: "bold" }}>
              {mainWarehouse ? "Sí" : "No"}
            </span>
          </>
        )}

        <Image
          style={{
            width: "100%",
            marginBottom: "15px",
            margin: "auto",
            display: "block",
          }}
          src={currentFranchise?.img}
        />
        <Form.Item
          span={26}
          label={<span style={{ fontWeight: "bold" }}>Imagen</span>}
          name="image"
        >
          {editMode && (
            <Upload.Dragger
              onRemove={() => {
                setImageUrl(undefined);
              }}
              size="large"
              beforeUpload={() => false}
              onChange={(info) => {
                handleFileUpload(info);
              }}
              listType="picture"
              maxCount={1}
              accept="image/*"
              style={{ marginTop: 10 }}
            >
              <p style={{ marginBottom: 5 }} className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p style={{ fontSize: "14px" }} className="ant-upload-text">
                Haz clic o arrastra el archivo a esta área para subirlo
              </p>
              <p className="ant-upload-hint">Soporte para carga única</p>
            </Upload.Dragger>
          )}
        </Form.Item>

        <Form.Item
          name="name"
          label={<span style={{ fontWeight: "bold" }}>Nombre</span>}
        >
          {renderFieldContent(currentFranchise?.name)}
        </Form.Item>
        <Form.Item
          name="phone"
          label={<span style={{ fontWeight: "bold" }}>Teléfono</span>}
        >
          {renderFieldContent(currentFranchise?.phone)}
        </Form.Item>
        <Form.Item
          name="fullName"
          label={<span style={{ fontWeight: "bold" }}>Dirección</span>}
        >
          {renderFieldContent(currentFranchise?.address?.fullName)}
        </Form.Item>
        <Form.Item
          name="country"
          label={<span style={{ fontWeight: "bold" }}>País</span>}
        >
          {renderFieldContent(currentFranchise?.address?.country)}
        </Form.Item>
        <Form.Item
          name="city"
          label={<span style={{ fontWeight: "bold" }}>Ciudad</span>}
        >
          {renderFieldContent(currentFranchise?.address?.city)}
        </Form.Item>
        <Form.Item
          name="state"
          label={<span style={{ fontWeight: "bold" }}>Estado</span>}
        >
          {renderFieldContent(currentFranchise?.address?.state)}
        </Form.Item>
        <Form.Item
          name="street"
          label={<span style={{ fontWeight: "bold" }}>Calle</span>}
        >
          {renderFieldContent(currentFranchise?.address?.street)}
        </Form.Item>
        <Form.Item
          name="postcode"
          label={<span style={{ fontWeight: "bold" }}>Código postal</span>}
        >
          {renderFieldContent(currentFranchise?.address?.postcode)}
        </Form.Item>
        <Form.Item
          name="colony"
          label={<span style={{ fontWeight: "bold" }}>Colonia</span>}
        >
          {renderFieldContent(currentFranchise?.address?.colony)}
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 26,
          }}
        >
          {!editMode && (
            <Button
              style={{ margin: "auto", display: "block" }}
              type="primary"
              onClick={handleEdit}
            >
              Editar
            </Button>
          )}
          {editMode && (
            <Row>
              <Button
                style={{ margin: "auto", display: "block" }}
                type="danger"
                onClick={closeEdit}
              >
                Cancelar
              </Button>

              <Button
                style={{ margin: "auto", display: "block" }}
                type="primary"
                htmlType="submit"
                disabled={!formChanged || uploading}
                loading={loading}
              >
                Guardar cambios
              </Button>
            </Row>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default DetailDrawer;
