import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI;

// Obtener todos los Reels con paginación y estado opcional
export const getReels = async (params = {}) => {
  try {
    const response = await axios.get(`${BASE_URL}/reels`, { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching reels:", error);
    throw error;
  }
};

// Obtener un Reel por ID
export const getReelById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/reels/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching reel with id ${id}:`, error);
    throw error;
  }
};

// Crear un nuevo Reel
export const createReel = async (reelData) => {
  try {
    const response = await axios.post(`${BASE_URL}/reels/create`, reelData);
    return response.data;
  } catch (error) {
    console.error("Error creating reel:", error);
    throw error;
  }
};

// Actualizar un Reel existente
export const updateReel = async (id, reelData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/reels/update/${id}`,
      reelData
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating reel with id ${id}:`, error);
    throw error;
  }
};

// Eliminar un Reel (eliminación lógica)
export const deleteReel = async (id) => {
  try {
    const response = await axios.delete(`${BASE_URL}/reels/delete/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting reel with id ${id}:`, error);
    throw error;
  }
};

// Asociar productos a un Reel
export const addProductsToReel = async (id, products) => {
  try {
    const response = await axios.put(`${BASE_URL}/reels/add-products/${id}`, {
      products,
    });
    return response.data;
  } catch (error) {
    console.error(`Error adding products to reel with id ${id}:`, error);
    throw error;
  }
};
