import { gql } from "@apollo/client";

const GET_NOTIFICATIONS = gql`
  query notifications($filters: NotificationInputFilter) {
    notifications(filters: $filters) {
      results {
        id
        title
        description
        scheduledDate
        createdAt
      }
    }
  }
`;

export { GET_NOTIFICATIONS };
