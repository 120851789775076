import { gql } from "@apollo/client";

const CREATE_BANNER = gql`
  mutation createBanner($banner: BannerCreateInput!) {
    createBanner(banner: $banner) {
      title
    }
  }
`;

const GET_PRODUCTS = gql`
  query products($params: QueryParams, $filters: ProductFilters) {
    products(params: $params, filters: $filters) {
      results {
        id
        odooId
        title
        img
        description
        price {
          amount
          exponent
          currency
        }
        category {
          name
          value
        }
        sku
      }
    }
  }
`;

const GET_COLLABORATIONS = gql`
  query collaborations($filters: CollaborationInputFilter) {
    collaborations(filters: $filters) {
      results {
        id
        name
      }
    }
  }
`;

export { CREATE_BANNER, GET_PRODUCTS, GET_COLLABORATIONS };
