import styled from "styled-components";
import { Card } from "antd";

const Container = styled.div`
  overflow: auto;
`;

const BannerCard = styled(Card)`
  cursor: pointer;
`;

export { Container, BannerCard };
