import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URI;

/**
 * 📌 Obtener todas las subcategorías con opción de filtros
 * @param {Object} params - Parámetros opcionales (paginación, filtros)
 * @returns {Promise<Object>} - Datos de la respuesta de la API
 */
export const getSubcategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/subcategories`, {
      params: { limit: 10000, offset: 0 }, // 🔥 Obtener todas las subcategorías
    });
    return response.data;
  } catch (error) {
    console.error("❌ Error al obtener subcategorías:", error);
    throw error;
  }
};

/**
 * 📌 Subir imagen de una subcategoría
 * @param {string} subcategoryId - ID de la subcategoría
 * @param {File} file - Archivo de imagen
 * @returns {Promise<Object>} - Respuesta de la API
 */
export const updateSubcategoryImage = async (subcategoryId, imageUrl) => {
  try {
    console.log("🔍 Enviando datos:", { subcategoryId, imageUrl });

    const response = await axios.post(
      `${BASE_URL}/subcategories/upload-image`,
      {
        subcategoryId,
        imageUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("✅ Respuesta del servidor:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "❌ Error al actualizar imagen de la subcategoría:",
      error.response?.data || error
    );
    throw error;
  }
};
