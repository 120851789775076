import { gql } from "@apollo/client";

const GET_PRODUCT_CATEGORIES = gql`
  query {
    productCategories(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
        description
        image
        priority
      }
    }
  }
`;

const GET_PRODUCT_WELLNESS = gql`
  query {
    productWellnesses(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
        description
        image
        priority
      }
    }
  }
`;

const DELETE_PRODUCT_CATEGORIES = gql`
  mutation deleteProductCategory($id: ID!) {
    deleteProductCategory(id: $id)
  }
`;

const DELETE_PRODUCT_WELLNESS = gql`
  mutation deleteProductWellness($id: ID!) {
    deleteProductWellness(id: $id)
  }
`;

export {
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_WELLNESS,
  DELETE_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_WELLNESS,
};
