import { gql } from "@apollo/client";

const UPDATE_PRODUCT_WELLNESS = gql`
  mutation updateLandingPage($id: ID!, $landingPage: LandingPageUpdate!) {
    updateLandingPage(id: $id, landingPage: $landingPage) {
      id
      name
      image
      priority
    }
  }
`;

export { UPDATE_PRODUCT_WELLNESS };
