import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  Upload,
  Select,
  message,
  Spin,
} from "antd";
import { UploadOutlined, PictureOutlined } from "@ant-design/icons";
import { createReel, updateReel } from "../requests";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS } from "../graphql/queries";
import useUpload from "hooks/use-upload/index";

const { Title } = Typography;
const { Option } = Select;

const EditReel = ({ showModal, setShowModal, editingReel, refetch }) => {
  const { upload } = useUpload();
  const [form] = Form.useForm();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { data, loading } = useQuery(GET_PRODUCTS, {
    variables: { params: { page: 1, pageSize: 300 } },
  });

  useEffect(() => {
    if (showModal) {
      form.resetFields();

      console.log("📌 Datos del Reel en edición:", editingReel);

      if (editingReel) {
        const associatedProducts = Array.isArray(editingReel.products)
          ? editingReel.products.map((p) => p._id || p.id)
          : [];

        console.log("📌 Productos asociados al Reel:", associatedProducts);

        setSelectedProducts(associatedProducts);

        form.setFieldsValue({
          title: editingReel.title,
          description: editingReel.description || "",
          videoUrl: editingReel.videoUrl,
          snapshotUrl: editingReel.snapshot || "",
          status: editingReel.status || "active",
          type: editingReel.type || "thc", // ✅ Cargar el tipo si existe
        });
      } else {
        form.setFieldsValue({ status: "inactive", type: "thc" });
        setSelectedProducts([]);
      }
    }
  }, [showModal, editingReel, form]);

  const onClose = () => {
    setShowModal(false);
    form.resetFields();
  };

  const handleVideoUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      try {
        const fileName = `reels/videos/${new Date().toISOString()}/${
          fileList[0]?.name
        }`;
        const url = await upload(fileList[0].originFileObj, fileName);
        form.setFieldsValue({ videoUrl: url });
        message.success("Video subido correctamente.");
      } catch (error) {
        message.error("Error al subir el video.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const handleImageUpload = async ({ fileList }) => {
    if (fileList[0]) {
      setUploadLoading(true);
      try {
        const fileName = `reels/snapshots/${new Date().toISOString()}/${
          fileList[0]?.name
        }`;
        const url = await upload(fileList[0].originFileObj, fileName);
        form.setFieldsValue({ snapshotUrl: url });
        message.success("Imagen subida correctamente.");
      } catch (error) {
        message.error("Error al subir la imagen.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const onFinish = async (values) => {
    try {
      if (editingReel) {
        await updateReel(editingReel._id, {
          ...values,
          products: selectedProducts,
        });
        message.success("Reel actualizado con éxito.");
      } else {
        await createReel(values);
        message.success("Reel creado con éxito.");
      }
      refetch();
    } catch (error) {
      message.error("Error al guardar los datos.");
    } finally {
      onClose();
    }
  };

  return (
    <Drawer
      title={
        <Title level={3} style={{ margin: 0 }}>
          {editingReel ? "Editar Reel" : "Agregar Reel"}
        </Title>
      }
      width={400}
      onClose={onClose}
      open={showModal}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ status: "inactive", type: "thc" }}
      >
        <Form.Item
          label="Título"
          name="title"
          rules={[{ required: true, message: "Por favor, ingresa el título." }]}
        >
          <Input placeholder="Título del reel" />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="description"
          rules={[
            { required: false, message: "Por favor, ingresa la descripción." },
          ]}
        >
          <Input.TextArea placeholder="Descripción del reel" />
        </Form.Item>

        <Form.Item
          label="Estado"
          name="status"
          rules={[
            { required: true, message: "Por favor, selecciona un estado." },
          ]}
        >
          <Select placeholder="Selecciona un estado">
            <Option value="active">Activo</Option>
            <Option value="inactive">Inactivo</Option>
          </Select>
        </Form.Item>

        {/* Nuevo campo Tipo */}
        <Form.Item
          label="Tipo"
          name="type"
          rules={[
            { required: true, message: "Por favor, selecciona un tipo." },
          ]}
        >
          <Select placeholder="Selecciona un tipo">
            <Option value="thc">THC</Option>
            <Option value="cbd">CBD</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Subir Video">
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            accept="video/*"
            onChange={handleVideoUpload}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Subir Video</Button>
            {uploadLoading ? <Spin style={{ margin: 10 }} /> : null}
          </Upload>
        </Form.Item>

        <Form.Item
          name="videoUrl"
          rules={[{ required: true, message: "Por favor, sube un video." }]}
        >
          <Input placeholder="URL del video" />
        </Form.Item>

        <Form.Item label="Subir Snapshot (Imagen)">
          <Upload
            beforeUpload={() => false}
            maxCount={1}
            accept="image/*"
            onChange={handleImageUpload}
            showUploadList={false}
          >
            <Button icon={<PictureOutlined />}>Subir Snapshot</Button>
            {uploadLoading ? <Spin style={{ margin: 10 }} /> : null}
          </Upload>
        </Form.Item>

        <Form.Item
          name="snapshotUrl"
          rules={[
            {
              required: true,
              message: "Por favor, sube o ingresa una imagen.",
            },
          ]}
        >
          <Input placeholder="URL del snapshot" />
        </Form.Item>

        {editingReel && (
          <Form.Item label="Productos Asociados">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Selecciona productos"
              value={selectedProducts}
              loading={loading}
              onChange={setSelectedProducts}
            >
              {data?.products?.results?.map((product) => (
                <Option
                  key={product._id || product.id}
                  value={product._id || product.id}
                >
                  {product.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {editingReel ? "Guardar Cambios" : "Crear Reel"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditReel;
