import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import {
  InputNumber,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  message,
} from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import { UPDATE_DISCOUNT } from "./requests";
import { useMutation } from "@apollo/client";

const { Option } = Select;

moment.locale("es");

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const DetailDrawer = ({ open, setOpen, discount, setDiscount, refetch }) => {
  const [typeOfDiscount, setTypeOfDiscount] = useState(discount.programType);
  const [typeOfValue, setTypeOfValue] = useState(discount?.amountOfOrder?.type);
  const [availableOn, setAvailableOn] = useState({
    posOk: discount.posOk,
    saleOk: discount.saleOk,
    ecommerceOk: discount.ecommerceOk,
  });
  const [form] = Form.useForm();

  const [updateDiscountMutation] = useMutation(UPDATE_DISCOUNT);

  const onClose = () => {
    setOpen(false);
    setDiscount(undefined);
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const handleButtonClick = (buttonType) => {
    setAvailableOn((prevDiscount) => {
      const updatedDiscount = { ...prevDiscount };
      updatedDiscount[buttonType] = !updatedDiscount[buttonType];
      return updatedDiscount;
    });
  };

  useEffect(() => {
    form.setFieldsValue(availableOn);
  }, [availableOn, form]);

  const refactorValues = (values) => {
    const newValues = {
      title: values.title,
      type: values.type,
      initialDate: values.initialDate,
      finalDate: values.finalDate,
      amountOfOrder: {
        type: values.typeOfValue,
        value: values.value,
      },
    };
    return newValues;
  };

  const onFinish = async (values) => {
    console.log(values);
    const newValues = refactorValues(values);
    try {
      const payload = {
        variables: {
          id: discount.id,
          discount: {
            ...newValues,
          },
        },
      };
      await updateDiscountMutation(payload);
      await refetch();
      message.success(`${discount.title} se actualizó con exito!`);
    } catch (err) {
      console.error(err);
      message.error("Error al crear");
    } finally {
      onClose();
    }
  };

  // const refactorDate = (date) => {
  //   const year = date.slice(0, 4);
  //   const month = date.slice(5, 7);
  //   const day = date.slice(8, 10);
  //   return `${day}/${month}/${year}`;
  // };

  return (
    <Drawer
      title="Detalles"
      placement="right"
      width={"40%"}
      onClose={onClose}
      visible={open}
    >
      <Form
        {...layout}
        form={form}
        name="discount"
        onFinish={onFinish}
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item
          name="title"
          label="Código"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={discount?.code}
        >
          <Input placeholder="Ej. CODIGO123" defaultValue={discount?.code} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Tipo de descuento"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={typeOfDiscount}
        >
          <Select
            placeholder="Tipo de descuento"
            onChange={(value) => {
              setTypeOfDiscount(value);
            }}
            defaultValue={typeOfDiscount}
          >
            <Option value="amountOfOrder">Amount off order</Option>
            <Option value="amountOfProduct">Amount off product</Option>
          </Select>
        </Form.Item>

        <Form.Item name="typeOfValue" label="Tipo" initialValue={typeOfValue}>
          <Radio.Group
            onChange={(e) => {
              setTypeOfValue(e.target.value);
            }}
            defaultValue={typeOfValue}
          >
            <Radio value={"porcentaje"}>Porcentaje</Radio>
            <Radio value={"valorFijo"}>Valor fijo</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="value"
          label={typeOfValue === "porcentaje" ? "Porcentaje" : "Valor"}
          initialValue={discount?.amountOfOrder?.value}
        >
          {typeOfValue === "porcentaje" ? (
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={discount?.amountOfOrder?.value}
              min={0}
              max={100}
              addonAfter="%"
            />
          ) : (
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              max={999999}
              defaultValue={discount?.amountOfOrder?.value}
              addonBefore="$"
            />
          )}
        </Form.Item>

        <Form.Item
          name="finalDate"
          label="Fecha final"
          initialValue={moment(discount.finalDate)}
        >
          <DatePicker
            disabledDate={disabledDate}
            defaultValue={moment(discount.finalDate)}
          />
        </Form.Item>

        <Form.Item
          name="posOk"
          label="Punto de Venta"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={availableOn["posOk"]}
        >
          <Button
            shape="round"
            style={{ width: "18%" }}
            icon={availableOn["posOk"] ? <CheckOutlined /> : <CloseOutlined />}
            onClick={() => handleButtonClick("posOk")}
          />
        </Form.Item>
        <Form.Item
          name="saleOk"
          label="Ventas"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={availableOn["saleOk"]}
        >
          <Button
            shape="round"
            style={{ width: "18%" }}
            icon={availableOn["saleOk"] ? <CheckOutlined /> : <CloseOutlined />}
            onClick={() => handleButtonClick("saleOk")}
          />
        </Form.Item>
        <Form.Item
          name="ecommerceOk"
          label="Sitio Web"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={availableOn["ecommerceOk"]}
        >
          <Button
            shape="round"
            style={{ width: "18%" }}
            icon={
              availableOn["ecommerceOk"] ? <CheckOutlined /> : <CloseOutlined />
            }
            onClick={() => handleButtonClick("ecommerceOk")}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Actualizar descuento
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default DetailDrawer;
