import { gql } from "@apollo/client";

const SYNC_ODOO_PRODUCTS = gql`
  mutation SyncNewProducts($products: SyncNewProductsInput) {
    SyncNewProducts(products: $products)
  }
`;

const GET_PRODUCT_WELLNESS = gql`
  query {
    productWellnesses(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_CATEGORIES = gql`
  query {
    productCategories(params: { pageSize: 100, page: 1 }, isWeb: true) {
      results {
        id
        name
      }
    }
  }
`;

export { SYNC_ODOO_PRODUCTS, GET_PRODUCT_WELLNESS, GET_PRODUCT_CATEGORIES };
