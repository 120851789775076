import React, { useEffect } from "react";
import { Drawer, Typography, Button, Form, Input, message } from "antd";
import { UPDATE_NOTIFICATION } from "./requests";
import { useMutation } from "@apollo/client";
import { Container } from "../elements";

const { Title, Text } = Typography;
const EditNotification = ({
  showModal,
  setShowModal,
  selectedNotification,
  setSelectedNotification,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [updateNotificationMutation] = useMutation(UPDATE_NOTIFICATION);

  useEffect(() => {
    if (showModal) {
      form.resetFields();
    }
  }, [showModal, form]);

  const onClose = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          id: selectedNotification.id,
          notification: {
            title: values.title || selectedNotification.title,
            description: values.description || selectedNotification.description,
          },
        },
      };
      await updateNotificationMutation(payload);
      await refetch();
      message.success("Se editó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al editar");
    } finally {
      onClose();
    }
  };

  return (
    <Container>
      <Drawer
        open={showModal}
        ShowModal
        onClose={onClose}
        title={<Title level={3}>Editar Notificación</Title>}
        width={"60%"}
      >
        <Form form={form} name="notification" onFinish={onFinish}>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Nombre de la Notificación
            </Text>
            <Form.Item name="title">
              <Input
                defaultValue={selectedNotification?.title}
                placeholder="Nombre"
              />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 14,
              }}
            >
              Descripción
            </Text>
            <Form.Item name="description">
              <Input
                defaultValue={selectedNotification?.description}
                placeholder="Descripción"
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Editar Notificación
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </Container>
  );
};

export default EditNotification;
