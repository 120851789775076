import React, { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Tooltip,
  message,
  Modal,
  Select,
  Input,
} from "antd";
import { Container, BannerCard } from "./elements";
import { useQuery, useMutation } from "@apollo/client";
import { GET_BANNERS, UPDATE_BANNER, DELETE_BANNER } from "./requests";
import {
  PlusOutlined,
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import CreateBanner from "./create-banner/index";
import EditBanner from "./edit-banner/index";
import DetailsBanner from "./details-banner/index";

const { Title, Text } = Typography;
const { Meta } = Card;
const { Option } = Select;

const Banners = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [viewDetailsBanner, setViewDetaillBanner] = useState(null);
  const [confirmDisable, setConfirmDisable] = useState(false);
  const [bannerActiveOrDisable, setBannerActiveOrDisable] = useState(null);
  const [statusBanner, setStatusBanner] = useState("true");
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);
  const [selectedBannerForDelete, setSelectedBannerForDelete] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");

  const { data: bannersData, refetch: refetchBanners } = useQuery(GET_BANNERS, {
    variables: {
      filters: {
        active: statusBanner,
        title: searchTitle,
      },
    },
  });

  const confirmDisableModal = (banner) => {
    setBannerActiveOrDisable(banner);
    setConfirmDisable(true);
  };

  const [activeOrDisableBannerMutation] = useMutation(UPDATE_BANNER);
  const activeOrDisableBanner = async () => {
    try {
      const payload = {
        variables: {
          id: bannerActiveOrDisable?.id,
          banner: {
            active: !bannerActiveOrDisable?.active,
          },
        },
      };
      await activeOrDisableBannerMutation(payload);
      await refetchBanners();
      setConfirmDisable(false);
      message.success("El cambio de completó!");
    } catch (err) {
      console.log(err);
      message.error("Error al intentar el cambio");
    }
  };

  const [deleteBannerMutation] = useMutation(DELETE_BANNER);
  const deleteBanner = async () => {
    try {
      const payload = {
        variables: {
          id: selectedBannerForDelete,
        },
      };
      await deleteBannerMutation(payload);
      await refetchBanners();
      setConfirmDeleteModalVisible(false);
      message.success("El banner se eliminó!");
    } catch (err) {
      console.log(err);
      message.error("Error al eliminar el banner");
    }
  };

  return (
    <Container>
      {/* Título y Filtros */}
      <Row
        style={{
          marginBottom: 20,
          alignItems: "center",
        }}
        gutter={[16, 16]} // Espaciado entre columnas
      >
        {/* Título */}
        <Col span={24}>
          <Text style={{ fontSize: 24, fontWeight: "bold" }}>Banners</Text>
        </Col>

        {/* Filtros */}
        <Col xs={24} sm={18}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Filtrar por Nombre
              </Title>
              <Input
                size="large"
                placeholder="Buscar por Nombre"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                allowClear
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Title level={5} style={{ fontSize: "0.9rem" }}>
                Filtrar por Estatus
              </Title>
              <Select
                size="large"
                defaultValue="true"
                style={{ width: "100%" }}
                onChange={async (value) => {
                  await refetchBanners();
                  setStatusBanner(value);
                }}
              >
                <Option value="all">Todo</Option>
                <Option value="true">Activos</Option>
                <Option value="false">Deshabilitados</Option>
              </Select>
            </Col>
          </Row>
        </Col>

        {/* Botón Crear Banner */}
        <Col xs={24} sm={6} style={{ textAlign: "right" }}>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            style={{
              width: "100%",
              fontWeight: "bold",
              borderRadius: 8,
            }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            Crear Banner
          </Button>
        </Col>
      </Row>

      {/* Contenido de Banners */}
      <Row
        gutter={[24, 24]} // Espaciado entre tarjetas
        style={{
          overflowY: "auto",
          height: "80vh",
          padding: "10px 0",
        }}
      >
        {bannersData?.banners?.results?.map((row) => (
          <Col
            key={row.id}
            xs={24} // Una tarjeta por fila en pantallas extra pequeñas
            sm={12} // Dos tarjetas por fila en pantallas pequeñas
            md={12} // Tres tarjetas por fila en pantallas medianas
            lg={8} // Cuatro tarjetas por fila en pantallas grandes
          >
            <BannerCard
              style={{
                borderRadius: 5,
                overflow: "hidden",
                maxHeight: "500px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              cover={
                <img
                  src={row.img}
                  alt="No disponible"
                  style={{
                    height: "180px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              }
            >
              <Meta
                title={
                  <Title level={5} style={{ fontWeight: "bold" }}>
                    {row.title}
                  </Title>
                }
                description={
                  <span
                    style={{
                      fontSize: "0.9rem",
                      lineHeight: "1.2rem",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                    }}
                  >
                    {row.type}
                  </span>
                }
              />
              {/* Acciones */}
              <Row
                gutter={[20, 20]}
                justify="center"
                style={{
                  marginTop: "10px",
                  borderTop: "1px solid #f0f0f0",
                  paddingTop: "10px",
                }}
              >
                <Col>
                  <Tooltip title="Editar">
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      style={{
                        borderRadius: 8,
                        fontWeight: "bold",
                        width: "80px",
                      }}
                      onClick={() => {
                        setSelectedBanner(row);
                        setShowEditModal(true);
                      }}
                    />
                  </Tooltip>
                </Col>

                <Col>
                  <Tooltip title={row.active ? "Deshabilitar" : "Activar"}>
                    <Button
                      type={row.active ? "danger" : "default"}
                      icon={
                        row.active ? (
                          <CloseCircleOutlined />
                        ) : (
                          <CheckCircleOutlined style={{ color: "black" }} />
                        )
                      }
                      style={{
                        borderRadius: 8,
                        fontWeight: "bold",
                        width: "80px",
                        backgroundColor: row.active ? undefined : "orange",
                      }}
                      onClick={() => confirmDisableModal(row)}
                    />
                  </Tooltip>
                </Col>

                <Col>
                  <Tooltip title="Eliminar">
                    <Button
                      type="dashed"
                      ghost
                      danger
                      icon={<DeleteOutlined />}
                      style={{
                        borderRadius: 8,
                        fontWeight: "bold",
                        width: "80px",
                      }}
                      onClick={() => {
                        setSelectedBannerForDelete(row.id);
                        setConfirmDeleteModalVisible(true);
                      }}
                    />
                  </Tooltip>
                </Col>

                <Col>
                  <Tooltip title="Detalles">
                    <Button
                      type="primary"
                      icon={<UnorderedListOutlined />}
                      style={{
                        borderRadius: 8,
                        fontWeight: "bold",
                        width: "80px",
                      }}
                      onClick={() => {
                        setViewDetaillBanner(row.id);
                        setShowDetailsModal(true);
                      }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </BannerCard>
          </Col>
        ))}
      </Row>

      {/* Modales */}
      <Modal
        title={
          bannerActiveOrDisable?.active
            ? "Confirmar deshabilitación"
            : "Confirmar activación"
        }
        visible={confirmDisable}
        onOk={activeOrDisableBanner}
        onCancel={() => setConfirmDisable(false)}
      >
        {bannerActiveOrDisable?.active ? (
          <p>¿Desea deshabilitar este banner?</p>
        ) : (
          <p>¿Desea activar este banner?</p>
        )}
      </Modal>
      <Modal
        title="Confirmar eliminación"
        visible={confirmDeleteModalVisible}
        onOk={deleteBanner}
        onCancel={() => setConfirmDeleteModalVisible(false)}
      >
        <p>¿Desea eliminar este banner?</p>
      </Modal>

      <CreateBanner
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetchBanners}
      />
      <EditBanner
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        selectedBanner={selectedBanner}
        setSelectedBanner={setSelectedBanner}
        refetch={refetchBanners}
      />
      <DetailsBanner
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
        selectedBanner={viewDetailsBanner}
      />
    </Container>
  );
};

export default Banners;
