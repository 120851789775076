import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Button,
  Form,
  Input,
  message,
  Tooltip,
  Table,
  Row,
  Col,
  Upload,
  Spin,
  Image,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UPDATE_COLLABORATION, GET_PRODUCTS } from "./requests";
import { useMutation, useQuery } from "@apollo/client";
import { formatCurrency } from "utils/functions";
import ColorPicker from "rc-color-picker";
import "rc-color-picker/assets/index.css";
import { typesCollaborations } from "utils/constants";
import useUpload from "hooks/use-upload/index";

const { Option } = Select;

const { Title, Text } = Typography;
const EditCollaboration = ({
  showModal,
  setShowModal,
  selectedCollaboration,
  setSelectedCollaboration,
  refetch,
}) => {
  const [form] = Form.useForm();
  const { upload } = useUpload();
  const [searchTitle, setSearchTitle] = useState("");
  const [searchSku, setSearchSku] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("#000000");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageThumbnailUrl, setImageThumbnailUrl] = useState(null);
  const [uploadLoding, setUploadLoading] = useState(false);
  const [uploadLodingThumbnail, setUploadLoadingThumbnail] = useState(false);

  const { data: productsData } = useQuery(GET_PRODUCTS, {
    variables: {
      filters: {
        title: searchTitle,
        sku: searchSku,
      },
    },
  });

  useEffect(() => {
    if (showModal) {
      form.resetFields();
      setSelectedProducts(
        selectedCollaboration?.products?.map((product) => product.id) || []
      );
      setPrimaryColor(selectedCollaboration?.colors?.primary);
      setSecondaryColor(selectedCollaboration?.colors?.secondary);
      setImageUrl(selectedCollaboration.img);
      setImageThumbnailUrl(selectedCollaboration.thumbnail);
    }
  }, [showModal, form]);

  const onClose = () => {
    setShowModal(false);
    setSelectedCollaboration(null);
  };

  const handleFileUpload = async ({ fileList }, isThumbnail) => {
    if (fileList[0]) {
      const fileName = `collaborations/${new Date().toISOString()}/${
        fileList[0]?.name
      }`;
      isThumbnail ? setUploadLoadingThumbnail(true) : setUploadLoading(true);
      const url = await upload(fileList[0].originFileObj, fileName);
      if (isThumbnail) {
        setImageThumbnailUrl(url);
      } else {
        setImageUrl(url);
      }
    }

    isThumbnail ? setUploadLoadingThumbnail(false) : setUploadLoading(false);
    message.success("Imagen agregada.");
  };

  const [updateCollaborationMutation] = useMutation(UPDATE_COLLABORATION);

  const onFinish = async (values) => {
    try {
      const payload = {
        variables: {
          id: selectedCollaboration.id,
          collaboration: {
            name: values.name || selectedCollaboration.name,
            description:
              values.description || selectedCollaboration.description,
            img: imageUrl || selectedCollaboration.img,
            thumbnail: imageThumbnailUrl || selectedCollaboration.thumbnail,
            products: selectedProducts,
            spotifyUrl: values.spotifyUrl || selectedCollaboration.spotifyUrl,
            colors: {
              primary: primaryColor,
              secondary: secondaryColor,
            },
          },
        },
      };
      await updateCollaborationMutation(payload);
      await refetch();
      message.success("Se editó con exito!");
    } catch (err) {
      console.error(err);
      message.error("Error al editar");
    } finally {
      setSelectedProducts([]);
      setSearchTitle("");
      setSearchSku("");
      onClose();
    }
  };

  const products = productsData?.products?.results || [];

  const columns = [
    {
      title: "",
      dataIndex: "img",
      key: "img",
      render: (text, record) => (
        <img
          src={text}
          alt={record.title}
          style={{ maxWidth: "80px", maxHeight: "80px" }}
        />
      ),
    },
    {
      title: "Nombre",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Precio",
      dataIndex: "price",
      key: "price",
      render: (text) => <Text>{formatCurrency(text)}</Text>,
    },
  ];

  const handleSelect = (record) => {
    const isSelected = selectedProducts.includes(record.id);
    setSelectedProducts((prevSelected) => {
      if (isSelected) {
        return prevSelected.filter((id) => id !== record.id);
      } else {
        return [...prevSelected, record.id];
      }
    });
  };

  const rowSelection = {
    selectedRowKeys: selectedProducts,
    onChange: (selectedKeys, selectedRows) => {
      const newSelectedKeys = selectedRows.map((row) => row.id);

      const uniqueNewKeys = newSelectedKeys.filter(
        (key) => !selectedProducts.includes(key)
      );

      if (uniqueNewKeys.length > 0) {
        setSelectedProducts((prevSelectedProducts) => [
          ...prevSelectedProducts,
          ...uniqueNewKeys,
        ]);
      }
    },
  };

  const sortProducts = (products, selectedProducts) => {
    const selectedSet = new Set(selectedProducts);
    const selected = products.filter((product) => selectedSet.has(product.id));
    const unselected = products.filter(
      (product) => !selectedSet.has(product.id)
    );
    return [...selected, ...unselected];
  };

  const sortedProducts = sortProducts(products, selectedProducts);

  return (
    <>
      <Drawer
        visible={showModal}
        onClose={onClose}
        width={"60%"}
        title={<Title level={3}>Editar Colaboración</Title>}
      >
        <Form form={form} name="collaboration" onFinish={onFinish}>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 17,
              }}
            >
              Nombre
            </Text>
            <Form.Item name="name">
              <Input
                defaultValue={selectedCollaboration?.name}
                size="large"
                placeholder="Nombre del Artista"
              />
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 17,
              }}
            >
              Descripción
            </Text>
            <Form.Item name="description">
              <Input
                size="large"
                defaultValue={selectedCollaboration?.description}
                placeholder="Descripción de la colaboracion"
              />
            </Form.Item>
          </div>
          <Row>
            <Col span={12}>
              <div>
                <Text
                  style={{
                    fontWeight: "800",
                    marginRight: 10,
                    fontSize: 17,
                  }}
                >
                  Imagen de Fondo <Text style={{ color: "red" }}> *</Text>
                </Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Upload
                    size="large"
                    beforeUpload={() => false}
                    listType="picture"
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      handleFileUpload(info, false);
                    }}
                    showUploadList={false}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Seleccionar Imagen
                    </Button>
                    {uploadLoding ? <Spin style={{ margin: 10 }} /> : null}
                  </Upload>
                  {imageUrl && (
                    <Image
                      style={{ marginLeft: 20 }}
                      width={80}
                      src={imageUrl}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <Text
                  style={{
                    fontWeight: "800",
                    marginRight: 10,
                    fontSize: 17,
                  }}
                >
                  Imagen de Miniatura <Text style={{ color: "red" }}> *</Text>
                </Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Upload
                    size="large"
                    beforeUpload={() => false}
                    listType="picture"
                    maxCount={1}
                    accept="image/*"
                    onChange={(info) => {
                      handleFileUpload(info, true);
                    }}
                    showUploadList={false}
                  >
                    <Button size="large" icon={<UploadOutlined />}>
                      Seleccionar Imagen
                    </Button>
                    {uploadLodingThumbnail ? (
                      <Spin style={{ margin: 10 }} />
                    ) : null}
                  </Upload>
                  {imageThumbnailUrl && (
                    <Image
                      style={{ marginLeft: 20 }}
                      width={80}
                      src={imageThumbnailUrl}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 17,
              }}
            >
              Tipo <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item name="type">
              <Select size="large" defaultValue={selectedCollaboration?.type}>
                {Object.values(typesCollaborations).map((type) => {
                  return (
                    <Option key={type.value} value={type.value}>
                      {type.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 17,
              }}
            >
              Spotify <Text style={{ color: "red" }}> *</Text>
            </Text>
            <Form.Item name="spotifyUrl">
              <Input
                size="large"
                defaultValue={selectedCollaboration?.spotifyUrl}
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 10 }}>
            <Text
              style={{
                fontWeight: "800",
                marginRight: 10,
                fontSize: 17,
              }}
            >
              Colores
            </Text>
            <Row>
              <Tooltip title="Elegir color primario">
                <div style={{ marginRight: 5 }}>
                  <ColorPicker
                    color={primaryColor}
                    onChange={({ color }) => {
                      setPrimaryColor(color);
                    }}
                    animation="slide-up"
                  />
                </div>
              </Tooltip>
              <Tooltip title="Elegir color secundario">
                <div style={{ marginLeft: 5 }}>
                  <ColorPicker
                    color={secondaryColor}
                    onChange={({ color }) => {
                      setSecondaryColor(color);
                    }}
                    animation="slide-up"
                  />
                </div>
              </Tooltip>
            </Row>
          </div>
          <div style={{ marginTop: 20, display: "flex", alignItems: "center" }}>
            <Tooltip title="Filtrar por Nombre">
              <Input
                placeholder="Buscar por Nombre"
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
                allowClear
              />
            </Tooltip>
            <Tooltip title="Filtrar por SKU">
              <Input
                style={{ marginLeft: 10 }}
                placeholder="Buscar por SKU"
                value={searchSku}
                onChange={(e) => setSearchSku(e.target.value)}
                allowClear
              />
            </Tooltip>
          </div>
          <div style={{ marginTop: 20 }}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={sortedProducts}
              rowKey="id"
              onRow={(record, index) => ({
                onClick: () => handleSelect(record, index),
              })}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Form.Item>
              <Button size="large" type="primary" htmlType="submit">
                Editar Colaboración
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default EditCollaboration;
